import {Field} from "./Field";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: theme.spacing(2),
        flex: 1,
    },
}));

export const ButtonField = ({label, value, onClick, placeholder}) => {
    const styles = useStyles();


    return (
        <Field
            label={label}
        >
            <div
                className={styles.root}
                onClick={onClick}
            >
                {value ? value : placeholder}
                {onClick && <ChevronRightIcon fontSize='inherit' style={{color: '#A6ACC0'}}/>}
            </div>
        </Field>
    );
};