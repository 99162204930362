import React from "react";
import {GastosJornada} from "./GastosJornada";
import {Formik} from "formik";
import InfoIcon from "@material-ui/icons/Info";

import * as Yup from 'yup';
import {formatISODate} from "../../../utils";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {PageBodySection} from "../../common/PageBody";

const GastoSchema = Yup.object().shape({
    jornadas: Yup.array().of(
        Yup.object().shape({
            gastos: Yup.object().shape({
                desplazamiento: Yup.number().typeError('Escribe un número válido'),
                parking: Yup.number().typeError('Escribe un número válido'),
                dietas: Yup.number().typeError('Escribe un número válido'),
            })
        })
    )
});

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        '&:last-of-type': {
            marginBottom: theme.spacing(2),
        },
    },
    title: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#818CAE',
        '& span': {
            fontWeight: 'normal',
        },
    },
    info: {
        fontSize: 10,
        color: '#818CAE',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        '& svg': {
            fontSize: 13,
            marginRight: theme.spacing(0.5),
        },
    },
}));

export const Gastos = ({jornadas, setJornadas}) => {
    const styles = useStyles();
    const todayStr = formatISODate(new Date());
    const hasToday = jornadas.findIndex(jornada => jornada.propia && jornada.fecha === todayStr) >= 0;

    return (
        <Formik
            initialValues={{
                jornadas,
            }}
            enableReinitialize={true}
            validationSchema={GastoSchema}
        >
            {({values}) => (
                <>
                    {!hasToday &&
                    <PageBodySection className={styles.root}>
                        <Typography className={styles.title}>Hoy</Typography>
                        <Typography className={styles.info}><InfoIcon /> Registra el tiempo para poder añadir el gasto de hoy.</Typography>
                    </PageBodySection>}
                    {values.jornadas.map((jornada, i) => (
                    <GastosJornada
                        key={i}
                        index={i}
                        jornada={jornada}
                        onSaveGasto={(idx, gastos) => setJornadas(jornadas => {
                            return [
                                ...jornadas.slice(0, i),
                                {
                                    ...jornadas[i],
                                    gastos: gastos,
                                },
                                ...jornadas.slice(i + 1),
                            ]
                        })}
                    />))}
                </>
            )}
        </Formik>

    );
};


