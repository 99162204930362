import React, {useEffect, useState} from "react";
import {GENERAL} from "../nueva/NuevaTarea";
import ListView from "../../common/ListView";
import {clientesProvider} from "../../../api";


export default ({tarea, setFieldValue, setCurrentFieldComponent}) => {
    const [clientes, setClientes] = useState(null);

    useEffect(() => {
        clientesProvider.getAll('as_options?with_address=true').then(setClientes);
    }, []);


    const columns = React.useMemo(() => [
        {
            Header: 'Nombre',
            accessor: 'nombre',
        }
    ], []);

    return clientes && (
        <ListView
            title='Seleccionar cliente'
            onClose={() => setCurrentFieldComponent(GENERAL)}
            setFieldValue={(value) => {
                setFieldValue('cliente')(value);
                setFieldValue('direccion')(value.direccion_principal);
            }}
            currentValue={tarea.cliente}
            items={clientes}
            columns={columns}
            searchPlaceholder='Buscar cliente'
        />
    )
}



