import React, {useEffect, useMemo, useState} from "react";
import {format, formatFullDateTime, formatTiempo} from "../../utils";
import {makeStyles} from "@material-ui/core/styles";
import {fichajesProvider} from "../../api";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import EntradaIcon from "../icons/Entrada";
import SalidaIcon from "../icons/Salida";
import {getTiempoTotalMarcajes} from "../../api/fichajes";
import classnames from "classnames";
import Button from "../common/Button";
import {ButtonDialog} from "../common/ButtonDialog";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    totalHoy: {
        backgroundColor: 'white',
        color: '#8F95AF',
        fontSize: 14,
        padding: theme.spacing(1, 2),
        borderRadius: 18,
        lineHeight: '20px',
        marginBottom: theme.spacing(7),
    },
    lista: {
        marginBottom: theme.spacing(2),
        borderRadius: 8,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        '& .MuiDivider-root:last-of-type': {
            display: 'none',
        },
        '& .entrada, & .entrada svg': {
            color: '#2CBD96'
        },
        '& .salida, & .salida svg': {
            color: '#E94F4F'
        },
        '& .MuiListItemSecondaryAction-root': {
            color: '#213061',
        },
        '& .MuiListItemIcon-root': {
            minWidth: theme.spacing(4),
        },
    },
    dialogContentText: {
        fontSize: 16,
        color: '#213061',
        '& div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& div + div': {
            marginTop: theme.spacing(1.5),
        },
    },
}));

export default function ListaFichajesHoy({style, className}) {
    const classes = useStyles();
    const [marcajes, setMarcajes] = useState([]);
    const [coordenadas, setCoordenadas] = useState(null);
    const history = useHistory();

    useEffect(() => {
        fichajesProvider.getMarcajesHoy().then(setMarcajes);
    }, []);

    useEffect(() => {
        if (navigator.geolocation) {
            const watchId = navigator.geolocation.watchPosition(
                function ({coords: {latitude, longitude, accuracy}}) {
                    setCoordenadas({latitude, longitude, accuracy});
                },
                function (err) {
                    setCoordenadas(null);
                },
                {
                    enableHighAccuracy: true,
                },
            );

            return () => navigator.geolocation.clearWatch(watchId);
        }
    }, []);

    const tiempoHoy = useMemo(() => getTiempoTotalMarcajes(marcajes), [marcajes]);

    const puedeEntrar = marcajes.length === 0 || marcajes[marcajes.length - 1].hora_salida !== null;

    const ficharEntrada = () => fichajesProvider.ficharEntrada(coordenadas).then(setMarcajes);
    const ficharSalida = () => fichajesProvider.ficharSalida(coordenadas).then(setMarcajes);

    return (
        <div className={classnames(className, classes.root)} style={style}>
            <div className={classes.totalHoy}>Hoy: {formatTiempo(tiempoHoy)}</div>

            {marcajes.length > 0 &&
            <List className={classes.lista}>
                {marcajes.map((fichaje, i) => (
                    <React.Fragment key={i}>
                        {fichaje.hora_entrada &&
                        <>
                            <ListItem className='entrada'>
                                <ListItemIcon>
                                    <EntradaIcon />
                                </ListItemIcon>
                                <ListItemText primary="Entrada" />
                                <ListItemSecondaryAction>
                                    {format(fichaje.hora_entrada_real, 'HH:mm')}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </>}
                        {fichaje.hora_salida &&
                        <>
                            <ListItem className='salida'>
                                <ListItemIcon>
                                    <SalidaIcon />
                                </ListItemIcon>
                                <ListItemText primary="Salida" />
                                <ListItemSecondaryAction>
                                    {format(fichaje.hora_salida_real, 'HH:mm')}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </>}
                    </React.Fragment>
                ))}
            </List>}
            {puedeEntrar ?
                <ButtonDialog
                    button={
                        <Button
                            color='success'
                            style={{marginBottom: 16}}
                            fullWidth
                        >
                            Fichar entrada
                        </Button>
                    }
                    onAccept={ficharEntrada}
                    title='¿Estás seguro que quieres fichar la entrada?'
                    content={
                        <div className={classnames(classes.lista, classes.dialogContentText)}>
                            <div className='entrada'>
                                <EntradaIcon /> Entrada
                            </div>
                            <div>
                                {formatFullDateTime(new Date())}
                            </div>
                        </div>
                    }
                    okText='Fichar entrada'
                    okColor='success'
                />
                :
                <ButtonDialog
                    button={
                        <Button
                            color='error'
                            style={{marginBottom: 16}}
                            fullWidth
                        >
                            Fichar salida
                        </Button>
                    }
                    onAccept={ficharSalida}
                    title='¿Estás seguro que quieres fichar la salida?'
                    content={
                        <div className={classnames(classes.lista, classes.dialogContentText)}>
                            <div className='salida'>
                                <SalidaIcon /> Salida
                            </div>
                            <div>
                                {formatFullDateTime(new Date())}
                            </div>
                        </div>
                    }
                    okText='Fichar salida'
                    okColor='error'
                />
            }

            <Button
                style={{marginBottom: 16}}
                fullWidth
                onClick={() => history.push('/fichajes/historico')}
            >
                Ver historial de fichajes
            </Button>
        </div>
    )
}