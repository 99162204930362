import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import DescriptionIcon from "@material-ui/icons/Description";
import {makeStyles} from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import MuiListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles(theme => ({
}));


const ListItemText = withStyles(theme => ({
    primary: {
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
    },
}))(MuiListItemText);

const ListItem = withStyles(theme => ({
    gutters: {
        paddingLeft: 0,
        paddingRight: 0,
    },
}))(MuiListItem);

export function DetallesDialog({button, tarea}) {
    const styles = useStyles();
    const [open, setOpen] = useState(false);

    const formButton = React.cloneElement(button, {onClick: () => setOpen(true)});

    return (
        <>
            {formButton}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
            >
                <IconButton
                    onClick={() => {
                        setOpen(false);
                    }}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogTitle
                    disableTypography
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <DescriptionIcon color='primary' style={{fontSize: 60, marginBottom: 8}}/>
                    <span>Detalles del servicio</span>
                </DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            whiteSpace: 'pre-wrap',
                            width: '100%',
                            fontSize: 12,
                            marginBottom: 16,
                        }}
                    >
                        {tarea.detalles}
                    </div>
                    <List dense style={{marginTop: 16}}>
                        {tarea.proyecto?.adjuntos.map(adjunto => {
                            const isImage = adjunto.content_type.startsWith('image');

                            return (
                                <ListItem key={adjunto.id} dense button onClick={() => window.open(adjunto.image_url || adjunto.original_url, '_blank')}>
                                    <ListItemAvatar>
                                        {isImage ?
                                            <Avatar variant='square' src={adjunto.thumbnail_url} />
                                            :
                                            <Avatar variant='square'>
                                                <PictureAsPdfIcon />
                                            </Avatar>
                                        }
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={adjunto.original_url.split('/').pop()}
                                    />
                                </ListItem>
                            )
                        })}
                        {tarea.adjuntos.map(adjunto => {
                            const isImage = adjunto.content_type.startsWith('image');

                            return (
                                <ListItem key={adjunto.id} dense button onClick={() => window.open(adjunto.image_url || adjunto.original_url, '_blank')}>
                                    <ListItemAvatar>
                                        {isImage ?
                                            <Avatar variant='square' src={adjunto.thumbnail_url} />
                                            :
                                            <Avatar variant='square'>
                                                <PictureAsPdfIcon />
                                            </Avatar>
                                        }
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={adjunto.original_url.split('/').pop()}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                </DialogContent>
            </Dialog>
        </>
    )
}