import React from "react";
import {PageHeader} from "../common/PageHeader";
import {PageBody} from "../common/PageBody";
import Navigation from "../common/Navigation";
import {makeStyles} from "@material-ui/core/styles";
import Reloj from "./Reloj";
import {Typography} from "@material-ui/core";
import ListaFichajes from "./ListaFichajesHoy";

const useStyles = makeStyles(theme => ({
    subtitle: {
        fontSize: 14,
        color: 'white',
        textAlign: 'center',
    },
}));

export const FicharView = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <PageHeader
                title='Fichajes'
            >
                <Typography className={classes.subtitle}>Ficha tu entrada y salida del trabajo</Typography>
            </PageHeader>
            <PageBody>
                <Reloj />
                <ListaFichajes />
            </PageBody>
            <Navigation/>
        </React.Fragment>
    );
}
