
export function createTiempo(minutos) {
    if (!minutos || minutos <= 0)
        return {
            horas: 0,
            minutos: 0,
        };

    return {
        horas: Math.floor(minutos / 60),
        minutos: minutos % 60,
    };
}

export function getMinutos(t) {
    if (!t)
        return 0;

    return t.horas * 60 + t.minutos;
}

export function addTiempo(t1, t2) {
    if (!t1)
        return t2;

    if (!t2)
        return t1;

    return createTiempo(getMinutos(t1) + getMinutos(t2));
}

export const isTiempoZero = tiempo => !tiempo || (tiempo.horas === 0 && tiempo.minutos === 0);

export function getTiempoJornadas(tareas) {
    const horasTrabajadasJornada = {};

    for (let tarea of tareas) {
        for (let jornada of tarea.jornadas.filter(j => j.propia)) {
            horasTrabajadasJornada[jornada.fecha] = addTiempo(horasTrabajadasJornada[jornada.fecha], jornada.tiempo);
        }
    }

    return horasTrabajadasJornada;
}

export function getTiempoTotal(tarea) {
    let total = null;

    for (let jornada of tarea.jornadasPropias) {
        total = addTiempo(total, jornada.tiempo);
    }

    return total;
}
