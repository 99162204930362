import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 16,
    },
    label: {
        color: '#213061',
        fontSize: 18,
        marginBottom: 8,
    },
    childContainer: props => ({
        borderRadius: 8,
        backgroundColor: props.backgroundColor || 'white',
        fontSize: 16,
        color: '#818CAE',
        display: 'flex',
    }),
}), {name: 'Field'});

export const Field = ({label, children, backgroundColor, style}) => {
    const styles = useStyles({backgroundColor});

    return (
        <div
            className={styles.root}
            style={style}
        >
            {label && <Typography
                variant='body1'
                className={styles.label}
            >
                {label}
            </Typography>}
            <div
                className={styles.childContainer}
            >
                {children}
            </div>
        </div>
    )
};