import React, {useMemo, useState} from "react";
import {formatDate, formatISODate} from "../../../utils";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {PageBodySection} from "../../common/PageBody";
import {Nota} from "./Nota";
import {getInitials} from "../../../api/operarios";
import {Fotos} from "./Fotos";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
    root: {
        '&:last-of-type': {
            marginBottom: theme.spacing(2),
        },
    },
    descripcion: {
        color: '#45486E',
        fontSize: 16,
    },
    fecha: {
        color: '#818CAE',
        fontSize: 11,
        marginBottom: theme.spacing(1.5),
    },
    title: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#818CAE',
        marginBottom: theme.spacing(2),
        '& span': {
            fontWeight: 'normal',
        },
    },
    addNota: {
        display: 'flex',
        margin: theme.spacing(2, 0),
    },
    addNotaTexto: {
        flex: 1,
        boxSizing: 'border-box',
        borderRadius: 4,
        border: '1px dashed #B5BCD1',
        display: 'flex',
        alignItems: 'center',
        color: '#818CAE',
        fontSize: 12,
        paddingLeft: theme.spacing(1.5),
    },
    addButton: {
        marginLeft: 8,
        height: 40,
        minWidth: 44,
        background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
    },
}));

export function JornadaNotas({jornada, setAddNotaView, disabled, style}) {
    const classes = useStyles();

    const fotos = useMemo(() => {
        if (!jornada)
            return [];

        return jornada.fotos.map(foto => ({operarioInitials: getInitials(foto.operario), ...foto}))
    },[jornada]);

    const isPropia = jornada.propia;

    const todayStr = formatISODate(new Date());
    const isToday = jornada.fecha === todayStr;

    const canEdit = !disabled && isPropia;

    return (
        <PageBodySection className={classes.root} style={style}>
            <Typography className={classes.title}>{isToday ? 'Hoy' : formatDate(new Date(jornada.fecha))} {jornada.operario && <span>({jornada.operario})</span>}</Typography>

            {canEdit && jornada.notas.length === 0 &&
            <div className={classes.addNota}>
                <Typography className={classes.addNotaTexto}>
                    No hay observaciones
                </Typography>

                <Button
                    onClick={setAddNotaView}
                    className={classes.addButton}
                >
                    <AddIcon style={{color: 'white', fontSize: 20}} />
                </Button>
            </div>}

            {jornada.notas.map((nota, i) => <Nota key={i} nota={nota} canEdit={canEdit} setAddNotaView={canEdit ? setAddNotaView : null} />)}

            <Fotos jornadaId={jornada.id} fotos={fotos} disabled={disabled || !isPropia} />
        </PageBodySection>
    )
}