import React, {useEffect, useState} from "react";
import {format, formatFullDate} from "../../utils";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
    },
    fecha: {
        color: '#8F95AF',
        fontSize: 18,
        marginTop: theme.spacing(4),
    },
    hora: {
        color: '#4D8AFF',
        fontSize: 64,
        marginTop: theme.spacing(2),
        lineHeight: '92px',
    },
}));

export default function Reloj() {
    const [fecha, setFecha] = useState(new Date());
    const classes = useStyles();

    useEffect(() => {
        const timerId = setInterval(() => setFecha(new Date()), 1000);

        return () => clearInterval(timerId);
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.fecha}>{formatFullDate(fecha)}</div>
            <div className={classes.hora}>{format(fecha, 'HH:mm:ss')}</div>
        </div>
    )
}