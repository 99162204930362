import React, {useCallback, useEffect} from "react";
import {formatDate, formatISODate} from "../../../utils";
import {RoundedInput} from "../RoundedInput";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {useField} from "formik";
import {tareasProvider} from "../../../api";
import {PageBodySection} from "../../common/PageBody";
import debounce from "@material-ui/core/utils/debounce";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        '&:last-of-type': {
            marginBottom: theme.spacing(2),
        },
    },
    title: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#818CAE',
        '& span': {
            fontWeight: 'normal',
        },
    },
    lineaGasto: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-root': {
            fontSize: 13,
            flex: 1,
        },
    },
    gastoInput: {
        textAlign: 'right',
        width: 80,
    },
}));


export function GastosJornada({index, jornada, onSaveGasto}) {
    const styles = useStyles();

    const todayStr = formatISODate(new Date());
    const isToday = jornada.fecha === todayStr;

    const collectionName = `jornadas[${index}].gastos`;

    const [{value}, {initialValue, error}, {setError}] = useField(collectionName);

    const debouncedSave = useCallback(
        debounce(
            value =>
                    tareasProvider
                        .action(`jornadas/${jornada.id}/gastos`, {
                            method: 'post',
                            body: JSON.stringify(value),
                        })
                        .then(data => {
                            onSaveGasto(index, value);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                setError(err.message);
                            }
                        }),
            1000
        ), [jornada.id]);

    useEffect(() => {
        const keys = Object.keys(value);
        const changed = keys.some(key => value[key] !== initialValue[key]);

        if (!changed)
            return;

        debouncedSave(value);
    }, [value, initialValue])

    return (
        <PageBodySection className={styles.root}>
            <Typography className={styles.title}>{isToday ? 'Hoy' : formatDate(new Date(jornada.fecha))} {jornada.operario && <span>({jornada.operario})</span>}</Typography>

            <div className={styles.lineaGasto}>
                <Typography color={error?.dietas ? 'error' : 'default'}>Dietas {error?.dietas ? '(Inválido)' : null}</Typography>
                <RoundedInput
                    className={styles.gastoInput}
                    name={`${collectionName}.dietas`}
                />
            </div>
            <div className={styles.lineaGasto}>
                <Typography color={error?.desplazamiento ? 'error' : 'default'}>Desplazamiento {error?.desplazamiento ? '(Inválido)' : null}</Typography>
                <RoundedInput
                    className={styles.gastoInput}
                    name={`${collectionName}.desplazamiento`}
                />
            </div>
            <div className={styles.lineaGasto}>
                <Typography color={error?.parking ? 'error' : 'default'}>Parking {error?.parking ? '(Inválido)' : null}</Typography>
                <RoundedInput
                    className={styles.gastoInput}
                    name={`${collectionName}.parking`}
                />
            </div>
        </PageBodySection>
    )
}