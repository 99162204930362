
import {format as dateFnsFormat} from 'date-fns';
import es from "date-fns/locale/es";

export function format(date, formatStr = 'PP') {
    return dateFnsFormat(new Date(date), formatStr, {locale: es});
}

export function formatDate(date) {
    return format(date, 'dd/MM/yyyy');
}

export function formatFullDate(date) {
    return format(date, 'EEE, dd MMM yyyy');
}

export function formatFullDateTime(date) {
    return format(date, 'EEE, dd MMM yyyy HH:mm');
}

export function formatDateTime(date) {
    return format(date, 'dd/MM/yyyy HH:mm');
}

export function formatISODate(date) {
    return format(date, 'yyyy-MM-dd');
}

export function formatTiempo(tiempo) {
    if (!tiempo)
        return '0h 00m';

    return `${tiempo.horas}h ${tiempo.minutos.toString().padStart(2, '0')}m`;
}

export function isBeginOfDay(d) {
    if (!d) return false;

    const date = new Date(d);
    return date.getHours() === 0 && date.getMinutes() === 0;
}

export function isEndOfDay(d) {
    if (!d) return false;

    const date = new Date(d);
    return date.getHours() === 23 && date.getMinutes() === 59;
}

export function isAllDay(start, end) {
    return isBeginOfDay(start) && isEndOfDay(end);
}

export function deepEqual(x, y) {
    if (x === y) {
        return true;
    }
    else if ((typeof x == "object" && x != null) && (typeof y == "object" && y != null)) {
        if (Object.keys(x).length !== Object.keys(y).length)
            return false;

        for (const prop in x) {
            if (y.hasOwnProperty(prop))
            {
                if (!deepEqual(x[prop], y[prop]))
                    return false;
            }
            else
                return false;
        }

        return true;
    }
    else
        return false;
}