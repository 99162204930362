import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {PageHeader} from "../common/PageHeader";
import {PageBody} from "../common/PageBody";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {useHistory} from "react-router-dom";
import {tareasProvider} from "../../api";
import {estadoLabels, processTarea} from "../../api/tareas";
import {VistaGeneral} from "./VistaGeneral";
import {MaterialUtilizado} from "./materiales/MaterialUtilizado";
import {AddNota} from "./notas/AddNota";
import {JornadasList} from "./JornadasList";
import {ExplicacionView} from "./ExplicacionView";
import {Gastos} from "./gastos/Gastos";
import {TareasNotas} from "./notas/TareasNotas";
import {FotoSlideshowView} from "./notas/FotoSlideshowView";
import {CerrarView} from "./CerrarView";
import {FirmarView} from "./FirmarView";
import {LocationDialog} from "./LocationDialog";
import {DetallesDialog} from "./DetallesDialog";
import {OtherOperariosDialog} from "./OtherOperariosDialog";
import {SelectMaterialView} from "./materiales/SelectMaterialView";
import {TareasExplicaciones} from "./explicaciones/TareasExplicaciones";
import {AddExplicacion} from "./explicaciones/AddExplicacion";
import {AlbaranesView} from "./AlbaranesView";
import {PartesMantenimientoListView} from "./partes_mantenimiento/PartesMantenimientoListView";
import {ParteMantenimientoView} from "./partes_mantenimiento/ParteMantenimientoView";
import {SplashProvider} from "../../contexts/SplashState";
import {PartesMantenimientoResumenView} from "./partes_mantenimiento/PartesMantenimientoResumenView";
import {useSnackbar} from "material-ui-snackbar-provider/lib";




export const GENERAL = 'general';
export const LISTA_JORNADAS = 'jornadas';
export const MATERIAL_UTILIZADO = 'materiales';
export const NOTAS = 'notas';
export const NOTAS_ADD = 'notas-add';
export const EXPLICACION = 'explicacion';
export const EXPLICACION_ADD = 'explicacion-add';
export const GASTOS = 'gastos';
export const FOTO = 'fotos';
export const CERRAR = 'cerrar';
export const FIRMAR = 'firmar';
export const ALBARANES = 'albaranes';
export const PARTES_MANTENIMIENTO_LIST = 'partes-mantenimiento';
export const PARTE_MANTENIMIENTO = 'parte-mantenimiento';
export const PARTES_MANTENIMIENTO_RESUMEN = 'partes-mantenimiento-resumen';

const viewTitles = {
    [GENERAL]: 'Servicio',
    [LISTA_JORNADAS]: 'Jornadas',
    [MATERIAL_UTILIZADO]: 'Material utilizado',
    [NOTAS]: 'Observaciones',
    [NOTAS_ADD]: 'Observaciones',
    [FOTO]: 'Foto 1 de 3',
    [EXPLICACION]: 'Descripción del servicio',
    [EXPLICACION_ADD]: 'Descripción del servicio',
    [GASTOS]: 'Otros gastos',
    [CERRAR]: 'Albarán de servicio',
    [FIRMAR]: 'Conformidad',
    [ALBARANES]: 'Albaranes de servicio',
    [PARTES_MANTENIMIENTO_LIST]: 'Partes de mantenimiento',
    [PARTE_MANTENIMIENTO]: 'Parte de mantenimiento',
    [PARTES_MANTENIMIENTO_RESUMEN]: 'Resúmen de mantenimiento',
};

const TareaStateContext = createContext();

export function useTareaState() {
    return useContext(TareaStateContext);
}

export default function ({id, selectedJornadaDate, children}) {
    const history = useHistory();
    const [tarea, setTarea] = useState(null);
    const [refreshCount, setRefreshCount] = useState(0);
    const [view, setView] = useState(selectedJornadaDate ? LISTA_JORNADAS : GENERAL);
    const [viewParams, setViewParams] = useState({});
    const beforeGoBackRef = useRef(null);
    const snackbar = useSnackbar();

    useEffect(() => {
        setView(selectedJornadaDate ? LISTA_JORNADAS : GENERAL);
    }, [selectedJornadaDate]);

    const refresh = () => setRefreshCount(count => count + 1);

    useEffect(() => {
        tareasProvider.getById(id).then(tarea => {
            setTarea(processTarea(tarea))
        });
    }, [id, refreshCount]);

    if (!tarea)
        return null;

    function setViewWithParams(view, params) {
        setView(view);
        if (params)
            setViewParams(params);
    }

    function setFotos(fotos) {
        setTarea(tarea => ({
            ...tarea,
            fotos,
        }));

        if (fotos.length === 0) {
            setViewWithParams(NOTAS, {});
        } else {
            setViewParams(params => ({...params, fotos, fotoIdx: Math.min(params.fotoIdx, fotos.length - 1)}));
        }
    }

    function addFoto(jornadaId, newFoto) {
        setTarea(tarea => ({
            ...tarea,
            jornadas: tarea.jornadas
                .map(jornada => jornada.id === jornadaId ?
                    {
                        ...jornada,
                        fotos: [...jornada.fotos, newFoto],
                    }
                    :
                    jornada
            ),
        }));
    }

    function openFotoSlideshow(fotos, fotoIdx) {
        setViewWithParams(FOTO, {fotos, fotoIdx});
    }

    let Component = null;
    let componentParams = {};

    switch (view) {
        case FOTO:
            Component = FotoSlideshowView;
            break;
        case FIRMAR:
            Component = FirmarView;
            componentParams = {
                onClose: () => setView(CERRAR),
                onSave: (firma) => {
                    setViewWithParams(CERRAR, {...viewParams, firma});
                },
            }
            break;
        default:
            Component = TareaView;
            break;
    }

    function setGoBackCheck(fn) {
        beforeGoBackRef.current = fn;
    }

    function goBack() {
        if (beforeGoBackRef.current && !beforeGoBackRef.current()) {
            snackbar.showMessage('Por favor, guarda los cambios antes de volver.', null, null, {severity: 'warning'})
            return;
        }

        setGoBackCheck(null);

        if (view === GENERAL || view === LISTA_JORNADAS) {
            history.goBack();
            return;
        }

        if (view === NOTAS_ADD) {
            setView(NOTAS);
            return;
        }

        if (view === EXPLICACION_ADD) {
            setView(EXPLICACION);
            return;
        }

        if (view === CERRAR) {
            setView(ALBARANES);
            return;
        }

        if (view === PARTE_MANTENIMIENTO) {
            setView(PARTES_MANTENIMIENTO_LIST);
            return;
        }

        setView(GENERAL);
    }

    return (
        <TareaStateContext.Provider
            value={{
                id,
                selectedJornadaDate,
                tarea,
                setTarea,
                view,
                viewParams,
                openFotoSlideshow,
                setFotos,
                addFoto,
                setView: setViewWithParams,
                refresh,
                setGoBackCheck,
                goBack,
            }}
        >
            <SplashProvider>
                <Component {...componentParams} />
            </SplashProvider>
        </TareaStateContext.Provider>
    );
}


function TareaView() {
    const {
        id,
        selectedJornadaDate,
        tarea,
        setTarea,
        view,
        viewParams,
        setView,
        refresh,
        goBack
    } = useTareaState();


    const editable = tarea.estado !== 'FINALIZADA';

    const viewBodies = {
        [GENERAL]: <VistaGeneral id={id} tarea={tarea} setView={setView} onSave={refresh} editable={editable}/>,
        [LISTA_JORNADAS]: <JornadasList id={id} jornadas={tarea.jornadasPropias} editable={editable} refresh={refresh}
                                        selectedJornadaDate={selectedJornadaDate}/>,
        [MATERIAL_UTILIZADO]:
            <MaterialUtilizado
                id={id}
                jornadas={tarea.jornadas.map(jornada => ({
                    id: jornada.id,
                    fecha: jornada.fecha,
                    materiales: jornada.materiales,
                    facturada: jornada.facturada,
                    propia: jornada.propia,
                    operario: jornada.operario,
                }))}
                setJornadas={jornadas => {
                    setTarea(tarea => ({
                        ...tarea,
                        jornadas: typeof (jornadas) === 'function' ? jornadas(tarea.jornadas) : jornadas,
                    }))
                }}
                editable={editable}
            />,
        [NOTAS]:
            <TareasNotas
                setAddNotaView={jornada => setView(NOTAS_ADD, {jornada})}
                tareasNotas={tarea.tareasNotas}
                setTareasNotas={tareasNotas => {
                    setTarea(tarea => ({
                        ...tarea,
                        tareasNotas: typeof (tareasNotas) === 'function' ? tareasNotas(tarea.tareasNotas) : tareasNotas,
                    }))
                }}
                editable={editable}
            />,
        [NOTAS_ADD]:
            <AddNota
                id={id}
                currentNotas={viewParams.jornada && viewParams.jornada.notas[0]}
                onSave={newNota => {
                    const {jornada} = viewParams;

                    setTarea(tarea => {
                        return {
                            ...tarea,
                            jornadas: tarea.jornadas.map(oldJornada =>
                                oldJornada.id === jornada.id ?
                                    {
                                        ...oldJornada,
                                        notas: [newNota],
                                    }
                                    :
                                    oldJornada,
                            ),
                        };
                    });
                }}
            />,
        [EXPLICACION]:
            <TareasExplicaciones
                setAddExplicacionView={jornada => setView(EXPLICACION_ADD, {jornada})}
                tareasExplicaciones={tarea.tareasExplicaciones}
                setTareasExplicaciones={tareasExplicaciones => {
                    setTarea(tarea => ({
                        ...tarea,
                        tareasExplicaciones: typeof (tareasExplicaciones) === 'function' ? tareasExplicaciones(tarea.tareasExplicaciones) : tareasExplicaciones,
                    }))
                }}
                editable={editable}
            />,
        [EXPLICACION_ADD]:
            <AddExplicacion
                id={id}
                currentExplicacion={viewParams.jornada && viewParams.jornada.explicacion}
                onSave={newExplicacion => {
                    const {jornada} = viewParams;

                    setTarea(tarea => {
                        return {
                            ...tarea,
                            jornadas: tarea.jornadas.map(oldJornada =>
                                oldJornada.id === jornada.id ?
                                    {
                                        ...oldJornada,
                                        explicacion: newExplicacion,
                                    }
                                    :
                                    oldJornada,
                            ),
                        };
                    });
                }}
            />,
        [GASTOS]:
            <Gastos
                id={id}
                jornadas={tarea.jornadas.map(jornada => ({
                    id: jornada.id,
                    fecha: jornada.fecha,
                    gastos: jornada.gastos ?? {desplazamiento: '0', dietas: '0', parking: '0'},
                    facturada: jornada.facturada,
                    propia: jornada.propia,
                    operario: tarea.operarios.length > 1 && jornada.operario,
                }))}
                setJornadas={jornadas => {
                    setTarea(tarea => ({
                        ...tarea,
                        jornadas: typeof (jornadas) === 'function' ? jornadas(tarea.jornadas) : jornadas,
                    }))
                }}
                editable={editable}
            />,
        [CERRAR]:
            <CerrarView/>,
        [ALBARANES]:
            <AlbaranesView/>,
        [PARTES_MANTENIMIENTO_LIST]:
            <PartesMantenimientoListView/>,
        [PARTE_MANTENIMIENTO]:
            <ParteMantenimientoView/>,
        [PARTES_MANTENIMIENTO_RESUMEN]:
            <PartesMantenimientoResumenView/>,
    };

    return (
        <React.Fragment>
            <PageHeader
                title={viewTitles[view]}
                startButton={
                    <IconButton
                        onClick={goBack}
                    >
                        <ArrowBackIcon style={{color: 'white'}}/>
                    </IconButton>
                }
            >
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <Typography variant='h2' style={{flex: 1}}>{tarea.cliente}</Typography>
                    <OtherOperariosDialog
                        button={
                            <IconButton
                                size='small'
                            >
                                <AssignmentIndIcon style={{color: 'white'}}/>
                            </IconButton>
                        }
                        tarea={tarea}
                    />
                    <DetallesDialog
                        button={
                            <IconButton
                                size='small'
                            >
                                <DescriptionIcon style={{color: 'white'}}/>
                            </IconButton>
                        }
                        tarea={tarea}
                    />
                    <LocationDialog
                        button={
                            <IconButton
                                size='small'
                            >
                                <LocationOnIcon style={{color: 'white'}}/>
                            </IconButton>
                        }
                        tarea={tarea}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <Typography variant='h3' style={{flex: 1}}>{tarea.descripcion}</Typography>
                    <span
                        style={{
                            backgroundColor: 'white',
                            color: '#4C62FE',
                            fontSize: 8,
                            fontWeight: 500,
                            width: 70,
                            height: 20,
                            borderRadius: 20,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {estadoLabels[tarea.estado]}
                    </span>
                </div>
            </PageHeader>
            <PageBody paddingTop={view === FIRMAR ? 0 : 8}>
                {viewBodies[view]}
            </PageBody>
        </React.Fragment>
    );
}


