import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles(theme => ({
    root: {
        padding: props => `8px ${props.disabled ? 8 : 24}px 4px 8px`,
        backgroundColor: 'white',
        borderRadius: 8,
        marginBottom: theme.spacing(2),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 24,
    },
    editIcon: {
        position: 'absolute',
        right: 4,
        top: 'calc(50% - 9px)',
        color: '#B8BABE',
        fontSize: 18,
    },
    nota: {
        color: '#818CAE',
        fontSize: 13,
        whiteSpace: 'pre-line',
    },
    operario: {
        color: '#2196F3',
        fontWeight: 700,
        fontSize: 10,
        textAlign: 'right',
        marginTop: 'auto',
    }
}));

export function Nota({nota, canEdit, setAddNotaView}) {

    const disabled = setAddNotaView === null;
    const classes = useStyles({disabled});

    const onEdit = setAddNotaView ?? (() => {});
    return (
        <div className={classes.root} onClick={onEdit}>
            {canEdit && <ChevronRightIcon className={classes.editIcon} />}
            <Typography className={classes.nota}>{nota.texto}</Typography>
            {/*<Typography className={classes.operario}>por {nota.propia ? 'ti' : nota.operario}</Typography>*/}
        </div>
    )
}