import React from "react";
import {CERRAR, FIRMAR, useTareaState} from "./TareaView";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import {BigButton} from "../common/BigButton";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles(theme => ({
    albaran: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #D8DAE1',
        padding: theme.spacing(2),
    },
    title: {
        flex: 1,
        fontSize: 13,
        fontWeight: 600,
    },
    chip: {
        fontSize: 10,
        padding: theme.spacing(0.5, 1),
        marginRight: theme.spacing(2),
        borderRadius: 10,
        backgroundColor: '#FEE5E5',
        color: '#E94F4F',
        '&.firmado': {
            backgroundColor: '#CFF3E9',
            color: '#2CBD96',
        },
    },
    button: {
        margin: theme.spacing(2),
        marginTop: 'auto',
    },
    info: {
        fontSize: 10,
        color: '#818CAE',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(1),
        '& svg': {
            fontSize: 13,
            marginRight: theme.spacing(0.5),
        },
    },
}), {name: 'AlbaranesView'});

export const AlbaranesView = () => {
    const classes = useStyles();
    const {tarea, setView} = useTareaState();

    const hasJornadasPendientes = tarea.jornadas.filter(jornada => jornada.albaran_id === null).length > 0;

    return (
        <>
            {tarea.albaranes.map((albaran, index) => {
                const firmado = albaran.firma && albaran.firma.image_url;
                return (
                    <div key={index} className={classes.albaran} onClick={() => setView(CERRAR, {albaran})}>
                        <Typography className={classes.title}>Albarán {albaran.numero}</Typography>
                        <div className={clsx(classes.chip, {firmado})}>{firmado ? 'FIRMADO' : 'PENDIENTE DE FIRMA'}</div>
                        <ChevronRightIcon/>
                    </div>
                )
            })}

            <div className={classes.button}>
                {!hasJornadasPendientes &&
                    <Typography className={classes.info}><InfoIcon /> No hay partes de trabajo pendientes de agrupar en un alabrán.</Typography>
                }
                <BigButton
                    onClick={() => setView(CERRAR, {})}
                    style={{width: '100%', marginBottom: 0}}
                    disabled={!hasJornadasPendientes}
                >
                    GENERAR ALBARÁN DE SERVICIO
                </BigButton>
            </div>
        </>
    );
};