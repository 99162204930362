import React, {createContext, useReducer, useContext, useEffect} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import Login from "../components/auth/Login";
import * as providers from '../api';
import AuthService from "../components/auth/AuthService";
import RecoverPassword from "../components/auth/RecoverPassword";
import ChangePassword from "../components/auth/ChangePassword";

/* Action Types */
const SET_LOGIN_INFO = 'SET_LOGIN_INFO';

/* Define a context and a reducer for updating the context */
const AuthStateContext = createContext();

const initialState = {
    username: null,
    authenticationToken: null,
    roles: null,
    userInfo: {},
};

const authStateReducer = (state, action) => {
    const authService = new AuthService();

    switch (action.type) {
        case SET_LOGIN_INFO:
            const token = authService.getToken();
            Object.keys(providers).forEach(key => {
                providers[key].token = token;
            });

            const userInfo = authService.getUserInfo();
            return {
                ...state,
                username: authService.getUsername(),
                token: token,
                roles: authService.getRoles(),
                userInfo,
            };

        default:
            return state;
    }
};

/* Export a component to provide the context to its children. This is used in our _app.js file */

export const AuthStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        authStateReducer,
        initialState
    );
    const history = useHistory();

    useEffect(() => {
        dispatch({
            type: SET_LOGIN_INFO,
        });
    }, []);

    const onLogin = (token) => {
        dispatch({
            type: SET_LOGIN_INFO,
        });
    };

    if (!state.token) {
        return (
            <Switch>
                <Route
                    path='/recover-password'
                >
                    <RecoverPassword/>
                </Route>
                <Route
                    path='/change-password/:token'
                >
                    <ChangePassword/>
                </Route>
                <Route path='/'>
                    <Login onLogin={onLogin}/>
                </Route>
            </Switch>
        );
    }

    Object.keys(providers).forEach(key => {
        providers[key].onError = (error) => {
            if (error.status === 401) {
                const authService = new AuthService();
                authService.logout();
                dispatch({
                    type: SET_LOGIN_INFO,
                });
                history.push('/');
            } else if (error.status === 403) {
                history.push('/');
                console.error('ERROR 403', error);
            } else {
                throw error;
            }
        };
    });

    return (
        <AuthStateContext.Provider value={[state, dispatch]}>
            {children}
        </AuthStateContext.Provider>
    );
};

/*
Default export is a hook that provides a simple API for updating the global state.
This also allows us to keep all of this state logic in this one file
*/

const useAuthState = () => {
    const [state, dispatch] = useContext(AuthStateContext);
    const history = useHistory();

    const handleLogout = () => {
        const authService = new AuthService();
        authService.logout();
        history.push('/');
        dispatch({
            type: SET_LOGIN_INFO,
        });
    };

    const handleError = (error) => {
        if (error.status === 401) {
            const authService = new AuthService();
            authService.logout();
            dispatch({
                type: SET_LOGIN_INFO,
            });
        } else if (error.status === 403) {
            history.push('/');
            console.error('ERROR 403', error);
        } else {
            console.error('ERROR (other)', error);
        }
    };

    return {
        logout: handleLogout,
        onError: handleError,
        username: state.username,
        roles: state.roles,
        userInfo: state.userInfo,
        isAdmin: state.roles.includes('ADMIN'),
        isCompany: state.roles.includes('COMPANY'),
    };
};

export default useAuthState;