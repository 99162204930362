import React, {useEffect, useState} from "react";
import {PageHeader} from "../../common/PageHeader";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import {PageBody} from "../../common/PageBody";
import {NOTAS, useTareaState} from "../TareaView";
import {format} from "../../../utils";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {makeStyles} from "@material-ui/core/styles";
import {ButtonDialog} from "../../common/ButtonDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import {tareasProvider} from "../../../api";

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100%',
        height: '100%',
        '& .react-transform-component': {
            height: '100%',
        },
    },
}));

export function FotoSlideshowView() {
    const classes = useStyles();
    const {id, viewParams, setView, setFotos} = useTareaState();
    const {fotos, fotoIdx} = viewParams;

    const [currentIdx, setCurrentIdx] = useState(fotoIdx);

    useEffect(() => setCurrentIdx(fotoIdx), [fotoIdx]);

    const foto = fotos[currentIdx];

    if (!foto)
        return null;

    const author = foto.operario;
    const date = format(new Date(foto.fecha), "dd/MM/yyyy' a las 'HH:mm");

    return (
        <React.Fragment>
            <PageHeader
                title={`Foto ${currentIdx + 1} de ${fotos.length}`}
                startButton={
                    <IconButton
                        onClick={() => setView(NOTAS)}
                    >
                        <ArrowBackIcon style={{color: 'white'}} />
                    </IconButton>
                }
            >
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <Typography variant='h2' style={{flex: 1}}>por {author}</Typography>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant='h3' style={{flex: 1}}>{date}</Typography>

                    <ButtonDialog
                        title='¿Estás seguro que quieres borrar esta foto?'
                        okText='Borrar'
                        button={
                            <IconButton
                                size='small'
                                disabled={!foto.propia}
                            >
                                <DeleteIcon style={{color: 'white'}} />
                            </IconButton>
                        }
                        onAccept={() => {
                            tareasProvider
                                .actionOnId(id, `fotos/${foto.id}`, null, {method: 'delete'})
                                .then(fotos => {
                                    setFotos(fotos);
                                });
                        }}
                    />
                </div>
            </PageHeader>
            <PageBody paddingTop={0}>
                <TransformWrapper
                    options={{
                        limitToBounds: true,
                        limitToWrapper: true,
                    }}
                    doubleClick={{ mode: 'reset'  }}
                    onPanningStop={({positionX, scale}) => {
                        const limitX = document.body.clientWidth * 0.3
                        const margin = 10;
                        const minX = -(limitX + document.body.clientWidth * (scale - 1) - margin);
                        const maxX = limitX - margin;

                        if (positionX <= minX && currentIdx < fotos.length - 1)
                            setCurrentIdx(idx => idx + 1);

                        if (positionX >= maxX && currentIdx > 0)
                            setCurrentIdx(idx => idx - 1);
                    }}
                >
                    {() => {
                        return (
                            <>
                                <div
                                    className={classes.wrapper}
                                >
                                    <TransformComponent>
                                        <img src={foto.image_url} alt={`por ${author} el ${date}`} style={{width: '100%', objectFit: 'cover'}} />
                                    </TransformComponent>
                                </div>
                            </>
                        )
                    }}
                </TransformWrapper>
            </PageBody>
        </React.Fragment>
    );
}