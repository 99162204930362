import React, {useState} from "react";
import Calendario from "../common/Calendario";
import GeneralView from "./GeneralView";
import SelectOperarioView from "../common/SelectOperarioView";
import SelectDireccionView from "../common/SelectDireccionView";

export const GENERAL = 0;
export const CLIENTE = 1;
export const FECHA = 2;
export const OPERARIO = 3;
export const DIRECCION = 4;


const viewComponents = {
    [GENERAL]: GeneralView,
    [FECHA]: Calendario,
    [OPERARIO]: SelectOperarioView,
    [DIRECCION]: SelectDireccionView,
};

export default ({tarea: initialTarea, onClose, onSave, onDelete}) => {
    const [tarea, setTarea] = useState(initialTarea);
    const [currentFieldComponent, setCurrentFieldComponent] = useState({
        id: 0,
        props: {},
    });

    const setFieldValue = field => ev => {
        const value = ev.target ? ev.target.value : ev;

        setTarea(tarea => ({
            ...tarea,
            [field]: value,
        }));
    };

    const Component = viewComponents[currentFieldComponent.id];

    return (
        <>
            <Component
                onClose={onClose}
                onSave={onSave}
                onDelete={onDelete}
                tarea={tarea}
                setFieldValue={setFieldValue}
                setCurrentFieldComponent={(componentId, componentProps) => setCurrentFieldComponent({id: componentId, props: componentProps || {}})}
                {...currentFieldComponent.props}
            />
        </>
    );
};
