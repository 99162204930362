import DataProvider from "./DataProvider";
import {formatISODate} from "../utils";
import {addTiempo, createTiempo} from "./tareas-functions";

export const empty = {
    descripcion: '',
    fecha: null,
    cliente: null,
    direccion: '',
    telefono: '',
    operarios: [],
    jornadas: [],
    materiales: [],
    estado: '',
    notas: [],
    fotos: [],
    explicacion: '',
    detalles: '',
    has_self: false,
    proyecto: null,
    adjuntos: [],
    albaranes: [],
    partes_mantenimiento: [],
    planificaciones: [],
};

export const estadoLabels = {
    PENDIENTE: 'Nuevo',
    EN_PROGRESO: 'Abierto',
    FINALIZADA: 'Cerrado',
};

export const gastosLabels = {
    dietas: 'Dietas',
    desplazamiento: 'Desplazamiento',
    parking: 'Parking',
};

export function processTareaForList(tarea) {

    let tiempoHoy = createTiempo(tarea.tiempo_hoy);
    let tiempoAcumulado = createTiempo(tarea.tiempo_acumulado);

    if (tarea.jornadas) {
        const hoy = formatISODate(new Date());
        const jornadasPropias = tarea.jornadas.filter(jornada => jornada.propia);
        const jornadaHoy = jornadasPropias.find(jornada => jornada.fecha === hoy);
        tiempoAcumulado = jornadasPropias.reduce((tiempo, jornada) => addTiempo(tiempo, jornada.tiempo), null);
        tiempoHoy = jornadaHoy ? jornadaHoy.tiempo : null;
    }

    const tareaBase = {
        ...tarea,
        tiempoHoy,
        tiempoAcumulado,
    };

    return tarea.planificaciones.map(planificacion => ({
        ...tareaBase,
        fechaInicio: new Date(planificacion.fecha_inicio),
        fechaFin: new Date(planificacion.fecha_fin),
        numOperarios: planificacion.operarios.filter(op => op.visible).length,
    }));
}

export function processTarea({jornadas, ...tarea}) {
    const hoy = formatISODate(new Date());

    const jornadasPropias = jornadas.filter(jornada => jornada.propia);
    const jornadaHoy = jornadasPropias.find(jornada => jornada.fecha === hoy);
    const tiempoAcumulado = jornadasPropias.reduce((tiempo, jornada) => addTiempo(tiempo, jornada.tiempo), null);

    return {
        ...tarea,
        fecha: new Date(tarea.fecha),
        firma: null,
        tareasNotas: null,
        tareasExplicaciones: null,
        jornadas,
        tiempoHoy: jornadaHoy ? jornadaHoy.tiempo : null,
        tiempoAcumulado,
        jornadasPropias,
    };
}

class TareasProvider extends DataProvider {
    constructor() {
        super('tareas', empty);
    }

    getTiempoOperarios = async (id, fecha) => {
        const selectedDate = fecha || formatISODate(new Date());
        return this.getAll(`${id}/tiempo-operarios/${selectedDate}`)
    }

    updateExplicacion = async (id, explicacion) => {
        return this.actionOnId(
            id,
            'explicacion',
            {texto: explicacion},
            {method: 'post'}
        );
    }

    updateJornadaNotas = async (jornadaId, notas) => {
        return this.getAll(
            `jornadas/${jornadaId}/notas`,
            {
                method: 'post',
                body: JSON.stringify({texto: notas}),
            }
        );
    }

    updateJornadaExplicacion = async (jornadaId, notas) => {
        return this.getAll(
            `jornadas/${jornadaId}/explicacion`,
            {
                method: 'post',
                body: JSON.stringify({texto: notas}),
            }
        );
    }

    updateNotas = async (id, notas) => {
        return this.actionOnId(
            id,
            'notas',
            {texto: notas},
            {method: 'post'}
        );
    }

    getCuestionariosParteMantenimiento = async (parteMantenimientoId) => {
        return this.action(`partes-mantenimiento/${parteMantenimientoId}/cuestionarios`)
    }

    getCuestionariosTarea = async (tareaId) => {
        return this.action(`${tareaId}/cuestionarios`)
    }

    setParteMantenimientoTerminado = async (parteMantenimientoId, terminado, firma) => {
        return this.action(`partes-mantenimiento/${parteMantenimientoId}/terminado`, {method: 'post', body: JSON.stringify({terminado, ...firma})});
    }

    setParteMantenimientoDefault = async (parteMantenimientoId) => {
        return this.action(`partes-mantenimiento/${parteMantenimientoId}/default`, {method: 'post'});
    }

    updateParteMantenimientoRespuesta = async (parteMantenimientoId, preguntaId, valueString) => {
        return this.action(
            `partes-mantenimiento/${parteMantenimientoId}/respuestas/${preguntaId}`,
            {
                method: 'post',
                body: JSON.stringify({valor: valueString})
            }
        );
    }

    updateParteMantenimientoRespuestaNotas = async (parteMantenimientoId, preguntaId, notas) => {
        if (!preguntaId) {
            return this.action(
                `partes-mantenimiento/${parteMantenimientoId}/notas`,
                {
                    method: 'post',
                    body: JSON.stringify({notas})
                }
            );
        }

        return this.action(
            `partes-mantenimiento/${parteMantenimientoId}/respuestas/${preguntaId}`,
            {
                method: 'post',
                body: JSON.stringify({notas})
            }
        );
    }

    validarMantenimiento = async (tareaId, firma) => {
        return this.action(`${tareaId}/validar-mantenimiento`, {method: 'post', body: JSON.stringify(firma)});
    }
}

export const dataProvider = new TareasProvider();
