import React, {useCallback, useEffect, useRef, useState} from "react";
import {PageHeader} from "../../common/PageHeader";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import {PageBody, PageBodySection} from "../../common/PageBody";
import AutoSizer from "react-virtualized-auto-sizer";
import MuiList from "@material-ui/core/List";
import {FixedSizeList} from "react-window";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ReplayIcon from "@material-ui/icons/Replay";
import DeleteIcon from "@material-ui/icons/Delete";
import {useTareaState} from "../TareaView";
import {useSnackbar} from "material-ui-snackbar-provider";
import debounce from "@material-ui/core/utils/debounce";
import {tareasProvider} from "../../../api";
import withStyles from "@material-ui/core/styles/withStyles";
import InputBase from "@material-ui/core/InputBase";
import {useField} from "formik";
import Typography from "@material-ui/core/Typography";

const Input = withStyles({
    root: {
        color: '#818CAE',
        fontSize: 13,
        height: '100%',
    },
    inputMultiline: {
        height: '100%',
    },
})(InputBase);

export default function PreguntaNotasView({onClose, onSave, pregunta, notas: currentNotas}) {
    const {viewParams: { parteMantenimiento: { id: parteMantenimientoId } }} = useTareaState();
    const [notas, setNotas] = useState(currentNotas ?? '');
    const [initialNotas, ] = useState(currentNotas ?? '');

    const inputRef = useRef(null);
    const snackbar = useSnackbar();

    useEffect(() => {
        if (!inputRef.current)
            return;

        inputRef.current.focus();
        inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
    }, [inputRef]);

    const changed = notas !== initialNotas;

    const debouncedSaveNotas = useCallback(
        debounce(
            notas =>
                tareasProvider
                    .updateParteMantenimientoRespuestaNotas(parteMantenimientoId, pregunta?.id, notas)
                    .then(() => {
                        onSave(notas);
                        snackbar.showMessage('Notas guardadas')
                    })
                    .catch(() => snackbar.showMessage('Ha ocurrido un error al guardar la notas')),
            500
        ), [parteMantenimientoId, pregunta]);

    function changeNotas(value) {
        setNotas(value);
        debouncedSaveNotas(value);
    }

    return (
        <React.Fragment>
            <PageHeader
                title='Notas'
                startButton={
                    <IconButton
                        onClick={onClose}
                    >
                        <ArrowBackIcon style={{color: 'white'}}/>
                    </IconButton>
                }
            />
            <PageBody
                paddingTop={8}
            >
                <PageBodySection>
                    {pregunta && <Typography style={{fontSize: 12, color: '#6B6C7E'}}>{pregunta.texto}</Typography>}
                    <div
                        style={{
                            backgroundColor: '#fff',
                            borderRadius: 8,
                            padding: '8px 16px',
                            position: 'relative',
                            minHeight: 100,
                            marginBottom: 24,
                            marginTop: 16,
                            flex: 1,
                        }}
                    >
                        <Input
                            fullWidth
                            multiline
                            rows={6}
                            value={notas}
                            onChange={ev => {
                                changeNotas(ev.target.value)
                            }}
                            inputRef={inputRef}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 8,
                                right: 8,
                                display: 'flex',
                            }}
                        >
                            {changed ?
                                <>
                                    <IconButton
                                        size='small'
                                        onClick={() => changeNotas(initialNotas)}
                                    >
                                        <ReplayIcon/>
                                    </IconButton>
                                </>
                                :
                                <IconButton
                                    size='small'
                                    onClick={() => changeNotas('')}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            }
                        </div>
                    </div>
                </PageBodySection>
            </PageBody>
        </React.Fragment>
    )
}