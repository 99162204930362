import {PageHeader} from "../common/PageHeader";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import WheelPicker from 'react-simple-wheel-picker';
import {makeStyles} from "@material-ui/core/styles";
import {DatePicker} from "@material-ui/pickers";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {format, formatISODate} from '../../utils';
import {createTiempo} from "../../api/tareas-functions";
import {useHistory} from "react-router-dom";
import isToday from 'date-fns/isToday';
import {tareasProvider} from "../../api";
import useSplash from "../../contexts/SplashState";
import UpdateOperariosTime from "./UpdateOperariosTime";
import useAuthState from "../../contexts/AuthState";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: 1,
        '& li': {
            justifyContent: 'center',
        },
        '& li span': {
            display: 'none',
        },
    },
}));


const dataHours = Array(16)
    .fill(0)
    .map((_, i) => ({
        id: i.toString(),
        value: i.toString().padStart(2, '0'),
    }));


const dataMinutes = [
    {
        id: '0',
        value: '00'
    },
    {
        id: '15',
        value: '15'
    },
    {
        id: '30',
        value: '30'
    },
    {
        id: '45',
        value: '45'
    },
];


export default ({id, fecha}) => {
    const history = useHistory();
    const styles = useStyles();
    const [date, changeDate] = useState(fecha ? new Date(fecha) : new Date());
    const [tiempo, setTiempo] = useState(null);
    const {showCustomComponent} = useSplash();
    const {userInfo: {preferencias}} = useAuthState();
    const copiarTiempoOperarios = preferencias?.copiar_tiempo_operarios;

    useEffect(() => {
        const selectedDate = formatISODate(date);

        if (tiempo !== null)
            setTiempo(null);

        tareasProvider
            .actionOnId(id, `tiempo/${selectedDate}`, null, {method: 'get'})
            .then(minutos => setTiempo(createTiempo(minutos)));
    }, [date]);

    const handleOnChange = field => value => {
        if (!tiempo)
            return;

        setTiempo(tiempo => ({...tiempo, [field]: Number(value.id)}));
    };

    const timePickerProps = {
        height: 380,
        width: null,
        itemHeight: 76,
        color: 'rgb(255,255,255,0.4)',
        activeColor: '#fff',
        focusColor: 'transparent',
        backgroundColor: 'transparent',
        shadowColor: 'none',
        fontSize: 48,
    };

    return (
        <PageHeader
            title={'Establecer tiempo'}
            fill
            startButton={
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    <CloseIcon style={{color: 'white'}}/>
                </IconButton>
            }
            endButton={
                <IconButton
                    onClick={() => {
                        const selectedDate = formatISODate(date);

                        copiarTiempoOperarios ?
                            showCustomComponent(
                                ({closeSplash}) =>
                                    <UpdateOperariosTime
                                        closeSplash={closeSplash}
                                        id={id}
                                        fecha={fecha}
                                        tiempo={tiempo}
                                        onSave={operarios => {
                                            tareasProvider
                                                .actionOnId(id, `tiempo/${selectedDate}`, {tiempo, operarios})
                                                .then(() => {
                                                    closeSplash();
                                                    history.goBack();
                                                });
                                        }}
                                    />
                            )
                            :
                            tareasProvider
                                .actionOnId(id, `tiempo/${selectedDate}`, {tiempo})
                                .then(() => history.goBack());
                    }}
                >
                    <DoneIcon style={{color: 'white'}}/>
                </IconButton>
            }
        >
            <Typography
                variant='h3'
                style={{
                    textAlign: 'center',
                    marginTop: 48,
                    marginBottom: 48,
                    position: 'relative',
                }}
            >
                Jornada {isToday(date) ? 'de HOY' : 'del ' + format(date, "dd 'de' MMMM yyyy")}
            </Typography>

            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    className={styles.root}
                >
                    {tiempo !== null &&
                    <>
                        <WheelPicker
                            data={dataHours}
                            onChange={handleOnChange('horas')}
                            selectedID={tiempo.horas.toString()}
                            {...timePickerProps}
                        />
                        <WheelPicker
                            data={dataMinutes}
                            onChange={handleOnChange('minutos')}
                            selectedID={tiempo.minutos.toString()}
                            {...timePickerProps}
                        />
                    </>}
                </div>
            </div>
        </PageHeader>
    )
}