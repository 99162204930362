import React, {useCallback, useEffect, useState} from "react";
import {PageHeader} from "../common/PageHeader";
import TabbedFilter from "../TabbedFilter";
import {PageBody} from "../common/PageBody";
import TareaCard from "../TareaCard";
import Navigation from "../common/Navigation";
import Skeleton from '@material-ui/lab/Skeleton';
import {useHistory} from "react-router-dom";
import {tareasProvider} from "../../api";
import {processTarea, processTareaForList} from "../../api/tareas";
import {TextField} from "./common/TextField";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import isBefore from 'date-fns/isBefore';
import {makeStyles} from "@material-ui/core/styles";

export const estadoLabels = {
    PENDIENTE: 'Nuevo',
    EN_PROGRESO: 'Abierto',
};

const filters = [{
    label: 'Todo',
    value: null,
}, ...Object.entries(estadoLabels).map(([value, label]) => ({label, value}))];


const useStyles = makeStyles(theme => ({
    body: {
        paddingBottom: 80,
    },
}), {name: 'TareaList'});

export const TareaList = () => {
    const classes = useStyles();
    const [tareas, setTareas] = useState(null);
    const [stateFilter, setStateFilter] = useState(null);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [textFilter, setTextFilter] = useState('');
    const history = useHistory();

    useEffect(() => {
        tareasProvider
            .getAll('pendientes')
            .then(tareas => {
                const tareaList = tareas.map(processTareaForList).reduce((tareaList, processedTareas) => {
                    tareaList.push(...processedTareas);
                    return tareaList;
                }, []);

                tareaList.sort((a, b) => isBefore(a.fechaInicio, b.fechaInicio) ? -1 : 1);

                setTareas(tareaList);
            });
    }, []);

    const filteredTareas = tareas ?
        tareas.filter(tarea => {
            if (stateFilter !== null && tarea.estado !== stateFilter)
                return false;

            if (textFilter === '')
                return true;

            const upperFilter = textFilter.toUpperCase();

            return tarea.descripcion.toUpperCase().includes(upperFilter) || tarea.cliente.toUpperCase().includes(upperFilter);
        })
        :
        tareas;

    const toggleSearch = useCallback(() => {
        setShowSearchBar(show => {
            if (show)
                setTextFilter('');
            return !show;
        });
    }, []);

    return (
        <React.Fragment>
            <PageHeader
                title='Mis servicios'
                endButton={
                    <IconButton
                        onClick={() => toggleSearch()}
                    >
                        <SearchIcon style={{color: 'white'}} />
                    </IconButton>
                }
            >
                <TabbedFilter
                    style={{marginTop: 24}}
                    onFilterClicked={setStateFilter}
                    filters={filters}
                    selectedFilter={stateFilter}
                />
                {showSearchBar &&
                <TextField
                    value={textFilter}
                    onChange={e => setTextFilter(e.target.value)}
                    backgroundColor='#DBE2FF'
                    startAdornment={<SearchIcon/>}
                    placeholder={'Buscar'}
                    fieldStyle={{marginBottom: 0, marginTop: 16}}
                />}
            </PageHeader>
            <PageBody className={classes.body}>
                {filteredTareas ?
                    filteredTareas.map((tarea, i) => (
                        <TareaCard
                            key={i}
                            cliente={tarea.cliente}
                            descripcion={tarea.descripcion}
                            estado={tarea.estado}
                            tiempo={tarea.tiempoHoy}
                            tiempoAcumulado={tarea.tiempoAcumulado}
                            onClick={() => history.push(`/tarea/${tarea.id}`)}
                            numero={tarea.numero}
                            fechaInicio={tarea.fechaInicio}
                            fechaFin={tarea.fechaFin}
                            numOperarios={tarea.numOperarios}
                            tipo={tarea.tipo}
                        />
                    ))
                    :
                    <>
                        <Skeleton variant='rect' height={124} style={{margin: '8px 0'}} />
                        <Skeleton variant='rect' height={124} style={{margin: '8px 0'}} />
                        <Skeleton variant='rect' height={124} style={{margin: '8px 0'}} />
                    </>
                }
            </PageBody>
            <Navigation/>
        </React.Fragment>
    );
};
