import MuiListItem from "@material-ui/core/ListItem";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    root: {
        fontSize: 18,
        color: '#213061',
        padding: theme.spacing(2),
    },
}));

export default ({children, ...props}) => {
    const styles = useStyles();
    return (
        <MuiListItem
            divider
            button
            classes={{
                root: styles.root,
            }}
            {...props}
        >{children}</MuiListItem>
    );
}