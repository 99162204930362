import {Field} from "./Field";
import InputBase from "@material-ui/core/InputBase";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";


const useStyles = makeStyles(theme => ({
    root: {
        fontSize: 'inherit',
        color: 'inherit',
        margin: theme.spacing(1),
    },
    input: {
        padding: 0,
    },
    inputWithAdornment: {
        marginLeft: theme.spacing(1),
    },
}), {name: 'TextField'});

export const TextField = ({label, value, onChange, backgroundColor, fieldStyle, ...props}) => {
    const styles = useStyles();

    return (
        <Field
            label={label}
            backgroundColor={backgroundColor}
            style={fieldStyle}
        >
            <InputBase
                fullWidth
                value={value}
                onChange={onChange}
                endAdornment={
                    <IconButton
                        size='small'
                        onClick={() => onChange({target: {value: ''}})}
                    >
                        <CloseIcon />
                    </IconButton>
                }
                classes={{
                    root: styles.root,
                    input: classNames(styles.input, {[styles.inputWithAdornment]: Boolean(props.startAdornment)}),
                }}
                {...props}
            />
        </Field>
    );
}