import React, {useState} from "react";
import {PageHeader} from "../common/PageHeader";
import {PageBody} from "../common/PageBody";
import Navigation from "../common/Navigation";
import {makeStyles} from "@material-ui/core/styles";
import FeedbackIcon from '@material-ui/icons/Feedback';
import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import {useHistory} from "react-router-dom";
import {AUSENCIA, Solicitudes} from "../../api/solicitudes";
import VacacionesIcon from "../icons/Vacaciones";
import classnames from "classnames";
import NuevaVacaciones from "./NuevaVacaciones";
import NuevaAusencia from "./NuevaAusencia";

const useStyles = makeStyles(theme => ({
    tabs: {
        display: 'flex',
        alignItems: 'center',
    },
    tabButton: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 64,
        fontSize: 14,
        color: '#8F95AF',
        '& svg': {
            color: 'inherit',
            marginBottom: theme.spacing(0.5),
        },
        '&.active': {
            color: '#2196F3',
            backgroundColor: 'white',
            borderRadius: 12,
        },
    }
}));

export const NuevaSolicitudView = ({solicitud = {}}) => {
    const classes = useStyles();
    const history = useHistory();
    const [tipoSolicitud, setTipoSolicitud] = useState(solicitud.tipo || 'VACACIONES');

    return (
        <React.Fragment>
            <PageHeader
                title='Nueva solicitud'
                startButton={
                    <IconButton
                        onClick={() => history.push('/solicitudes')}
                    >
                        <CloseIcon style={{color: 'white'}}/>
                    </IconButton>
                }
            >
            </PageHeader>
            <PageBody>
                <div className={classes.tabs}>
                    <div
                        onClick={() => setTipoSolicitud('VACACIONES')}
                        className={classnames(classes.tabButton, tipoSolicitud === 'VACACIONES' ? 'active' : null)}
                    >
                        <VacacionesIcon/>
                        Vacaciones
                    </div>
                    <div
                        onClick={() => setTipoSolicitud('AUSENCIA')}
                        className={classnames(classes.tabButton, tipoSolicitud === 'AUSENCIA' ? 'active' : null)}
                    >
                        <FeedbackIcon/>
                        Ausencia
                    </div>
                </div>
                {tipoSolicitud === 'VACACIONES' ?
                <NuevaVacaciones solicitud={solicitud} />
                :
                <NuevaAusencia/>}
            </PageBody>
            <Navigation/>
        </React.Fragment>
    );
}
