import {PageBodySection} from "../common/PageBody";
import Typography from "@material-ui/core/Typography";
import React, {useEffect, useMemo, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FIRMAR, GENERAL, useTareaState} from "./TareaView";
import {formatTiempo} from "../../utils";
import {addTiempo} from "../../api/tareas-functions";
import {gastosLabels, processTarea} from "../../api/tareas";
import {BigButton} from "../common/BigButton";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {ButtonDialog} from "../common/ButtonDialog";
import {tareasProvider} from "../../api";
import {useHistory} from "react-router-dom";
import {StartLabelSwitch} from "../common/SwitchWithLabel";
import useAuthState from "../../contexts/AuthState";


const useStyles = makeStyles(theme => ({
    tiempo: {
        display: 'flex',
        '& h4': {
            flex: 1,
        },
        '& span': {
            color: '#818cae',
        },
    },
    explicacion: {
        fontSize: 14,
        color: '#818cae',
        opacity: 0.5,
        marginBottom: theme.spacing(2),
        whiteSpace: 'pre',
    },
    lista: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
        '&>div': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 13,
            color: '#818cae',
        },
    },
    signature: {
        border: '1px dashed #bababa',
        borderRadius: 4,
        maxHeight: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        '& img': {
            margin: theme.spacing(2),
            objectFit: 'cover',
            overflow: 'hidden',
        },
    },
    signatureName: {
        fontSize: 12,
        color: '#818cae',
        marginBottom: theme.spacing(1),
    },
    sendCopySwitch: {
        marginTop: 'auto',
        marginBottom: theme.spacing(2),
    },
    buttons: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    cerrarButton: {
    },
    cerrarSinFirmarButton: {
        textTransform: 'none',
        color: '#818cae',
        fontSize: 13,
    },
}), {name: 'CerrarView'});

export function CerrarView() {
    const classes = useStyles();
    const history = useHistory();
    const {id, tarea, setTarea, setView, viewParams} = useTareaState();
    const [coordenadas, setCoordenadas] = useState(null);
    // const [jornadas, setJornadas] = useState([]);
    const [cerrando, setCerrando] = useState(false);

    const albaran = viewParams.albaran;
    const isFirmaExistente = albaran && albaran.firma && albaran.firma.image_url;
    const firma = isFirmaExistente ? albaran.firma : viewParams.firma;

    const {userInfo: {preferencias}} = useAuthState();
    const enviarMailCliente = preferencias?.enviar_mail_cliente;
    const desglosarHoras = preferencias?.desglosar_horas_operario;

    // useEffect(() => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             function (position) {
    //                 setCoordenadas(`${position.coords.latitude},${position.coords.longitude}`);
    //             },
    //             function (err) {
    //                 setCoordenadas(null);
    //             },
    //         );
    //     }
    // }, [id, albaran]);

    const {jornadas, explicacion} = useMemo(() => {
        const albaranId = albaran ? albaran.id : null;
        const jornadas = tarea.jornadas.filter(jornada => jornada.albaran_id === albaranId);

        const explicacion = albaran ? albaran.explicacion : jornadas.map(jornada => jornada.explicacion).filter(Boolean).join('\n\n');

        return {jornadas, explicacion};
    }, [tarea, albaran]);

    const tiempoAcumuladoTotal = jornadas.reduce((tiempo, jornada) => addTiempo(tiempo, jornada.tiempo), null);

    const listaMateriales = jornadas.map(jornada => jornada.materiales).flat();
    const materiales = listaMateriales.map((m, i) => (
        <div style={{marginBottom: 4}} key={i}><span>{m.descripcion}</span><span>{m.unidades}</span></div>
    ));

    const tiposGastos = Object.keys(gastosLabels);
    const listaGastos = Object.fromEntries(tiposGastos.map(tipo => ([tipo, 0])));

    const tiemposPorOperario = {};

    jornadas.forEach(jornada => {
        tiemposPorOperario[jornada.operario] = addTiempo(tiemposPorOperario[jornada.operario], jornada.tiempo);

        if (jornada.gastos === null)
            return;

        tiposGastos.forEach(tipo => {
            if (jornada.gastos[tipo])
                listaGastos[tipo] += 1;
        });
    });

    const gastos = Object.entries(listaGastos)
        .filter(([, dias]) => dias > 0)
        .map(([key, dias]) => (
            <div style={{marginBottom: 4}} key={key}><span>{gastosLabels[key]}</span><span>{dias}</span></div>
        ));

    const enviarCopia = tarea.enviarCopia !== undefined ? tarea.enviarCopia : enviarMailCliente;
    const setEnviarCopia = checked => setTarea(tarea => ({...tarea, enviarCopia: checked}))

    const degloseTiempo = Object.entries(tiemposPorOperario).map(([operario, tiempo], i) => (
        <div style={{marginBottom: 4}} key={i}><span>{operario}</span><span>{formatTiempo(tiempo)}</span></div>
    ));

    const cerrarParams = {
        albaranId: albaran ? albaran.id : null,
        ids: jornadas.map(j => j.id),
        enviarCopia,
        // coordenadas,
    };

    function onAlbaranGenerado(tarea) {
        setCerrando(false);
        setTarea(processTarea(tarea));
        setView(GENERAL, {});
    }

    return (
        <>
            <PageBodySection>
                <div className={classes.tiempo}>
                    <Typography variant='h4'>Tiempo trabajado</Typography>
                    <Typography variant='h4' component='span'>{formatTiempo(tiempoAcumuladoTotal)}</Typography>
                </div>
                {desglosarHoras &&
                <div className={classes.lista}>
                    {degloseTiempo}
                </div>}
            </PageBodySection>
            <PageBodySection>
                <Typography variant='h4'>Descripción</Typography>
                <Typography className={classes.explicacion}>{explicacion}</Typography>
            </PageBodySection>
            <PageBodySection>
                <Typography variant='h4'>Material utilizado</Typography>
                <div className={classes.lista}>
                    {materiales}
                </div>
            </PageBodySection>
            <PageBodySection>
                <Typography variant='h4'>Otros gastos</Typography>
                <div className={classes.lista}>
                    {gastos}
                </div>
            </PageBodySection>
            {firma &&
            <PageBodySection>
                <Typography variant='h4'>Firma del cliente</Typography>
                <div className={classes.signature}>
                    <img
                        src={isFirmaExistente ? firma.image_url : firma.imagen}
                    />
                </div>
                <Typography className={classes.signatureName}>Firmado por: {firma.nombre}</Typography>
            </PageBodySection>}
            {!isFirmaExistente &&
            <PageBodySection
                className={classes.buttons}
                style={{paddingTop: 16}}
            >
                <StartLabelSwitch
                    name='send_email'
                    label='Enviar copia del albarán de servicio al cliente'
                    checked={enviarCopia}
                    onChange={ev => setEnviarCopia(ev.target.checked)}
                    className={classes.sendCopySwitch}
                />
                {firma ?
                <BigButton
                    onClick={() => {
                        setCerrando(true);
                        const url = albaran ? `albaranes/${albaran.id}/firmar` : `${id}/albaranes`;

                        tareasProvider
                            .action(url, {
                                method: 'post',
                                body: JSON.stringify({
                                    ...viewParams.firma,
                                    ...cerrarParams,
                                }),
                            })
                            .then(onAlbaranGenerado)
                            .catch(err => {
                                setCerrando(false);
                            });
                    }}
                    disabled={cerrando}
                >
                    GUARDAR ALBARÁN
                </BigButton>
                :
                <BigButton
                    onClick={() => setView(FIRMAR, viewParams)}
                >
                    FIRMAR
                </BigButton>
                }
                {!albaran &&
                <ButtonDialog
                    title='¿Estás seguro que quieres guardar este albarán sin firmar?'
                    // content='Debes estar seguro que no realizarás mas horas en este trabajo.'
                    okText='Guardar'
                    button={
                        <Button
                            className={classes.cerrarSinFirmarButton}
                            endIcon={<ArrowForwardIcon />}
                            disabled={cerrando}
                        >
                            Guardar sin firmar
                        </Button>
                    }
                    onAccept={() => {
                        setCerrando(true);
                        tareasProvider
                            .actionOnId(id, 'albaranes', cerrarParams)
                            .then(onAlbaranGenerado)
                            .catch(err => {
                                setCerrando(false);
                            });
                    }}
                />}
            </PageBodySection>}
        </>
    )
}