import React from 'react';
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import MuiRadioGroup from "@material-ui/core/RadioGroup";
import MuiRadio from "@material-ui/core/Radio";
import withStyles from "@material-ui/core/styles/withStyles";
import {useField} from "formik";
import Pregunta from "./Pregunta";


const FormControlLabel = withStyles(theme => ({
    root: {
        margin: 0,
    },
    label: {
        fontSize: 13,
        fontWeight: 500,
    },
}), {name: 'FormControlLabel'})(MuiFormControlLabel);

const RadioGroup = withStyles(theme => ({
    root: {
        gap: `${theme.spacing(3)}px`,
    },
}), {name: 'RadioGroup'})(MuiRadioGroup);

const Radio = withStyles(theme => ({
    root: {
        padding: 0,
    },
}), {name: 'Radio'})(MuiRadio);


export default function PreguntaChoice({ pregunta, columnas, ...props }) {
    const [{value}, , {setValue, setTouched}] = useField(`respuestas.${pregunta.id}.valor`);

    return (
        <Pregunta pregunta={pregunta} {...props}>
            <RadioGroup
                row
                value={value || ''}
                onChange={ev => {
                    setValue(ev.target.value);
                    setTouched(true);
                }}
            >
                {columnas.map((label, i) => (
                    <FormControlLabel
                        key={i}
                        value={label}
                        control={<Radio color="primary" />}
                        label={label}
                        labelPlacement="top"
                    />
                ))}
            </RadioGroup>
        </Pregunta>
    )
}