import decode from 'jwt-decode';

const APP_PREFIX = 'labory-operario';

export default class AuthService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || 'http://localhost:5000'; // API server domain
        this.fetch = this.fetch.bind(this); // React binding stuff
        this.login = this.login.bind(this);
        this.getProfile = this.getProfile.bind(this)
    }

    login(username, password) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/tokens`, {
            method: 'POST',
            body: JSON.stringify({app: APP_PREFIX}),
        }, {
            Authorization: 'Basic ' + btoa(`${username}:${password}`),
        }).then(res => {
            this.setToken(res.token, res.name); // Setting the token in localStorage
            this.setRoles(res.roles);
            this.setUserInfo(res.user_info);

            return Promise.resolve(res);
        })
    }

    changePassword(token, password) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/change-password`, {
            method: 'POST',
            body: JSON.stringify({token, password}),
        });
    }

    recoverPassword(username) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/recover-password`, {
            method: 'POST',
            body: JSON.stringify({username}),
        });
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken(); // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            return decoded.exp < Date.now() / 1000;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken, username) {
        // Saves user token to localStorage
        localStorage.setItem(`${APP_PREFIX}.id_token`, idToken);
        localStorage.setItem(`${APP_PREFIX}.username`, username);
    }

    setRoles(roles) {
        // Saves user token to localStorage
        localStorage.setItem(`${APP_PREFIX}.roles`, JSON.stringify(roles));
    }

    setUserInfo(userInfo) {
        // Saves user token to localStorage
        localStorage.setItem(`${APP_PREFIX}.userInfo`, JSON.stringify(userInfo));
    }

    getUserInfo() {
        return JSON.parse(localStorage.getItem(`${APP_PREFIX}.userInfo`)) || {};
    }

    getRoles() {
        return JSON.parse(localStorage.getItem(`${APP_PREFIX}.roles`));
    }

    getUsername() {
        return localStorage.getItem(`${APP_PREFIX}.username`);
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem(`${APP_PREFIX}.id_token`);
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem(`${APP_PREFIX}.id_token`);
        localStorage.removeItem(`${APP_PREFIX}.username`);
        localStorage.removeItem(`${APP_PREFIX}.roles`);
        localStorage.removeItem(`${APP_PREFIX}.userInfo`);
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }

    fetch(url, options, extraHeaders = {}) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...extraHeaders,
        };

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error(response.statusText);
            error.response = response;
            throw error
        }
    }
}