import DataProvider from "./DataProvider";

export const empty = {
    nombre: '',
    email: '',
    telefono: '',
    direccion: '',
    codigo_postal: '',
    poblacion: '',
    provincia: '',
};

export const dataProvider = new DataProvider('clientes', empty);
