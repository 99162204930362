import DataProvider from "./DataProvider";
import {createTiempo} from "./tareas-functions";

export const empty = {
    nombre: '',
    email: '',
    telefono: '',
};

export function getInitials(name) {
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...name.matchAll(rgx)] || [];

    initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();

    return initials;
}

class OperariosProvider extends DataProvider
{
    constructor() {
        super('operarios', empty);
    }

    getResumenHorasMes = async (year, month) => {
        return this.getAll(`resumen-horas/${year}/${month}`, null, {method: 'get'})
            .then(({horas_laborables, minutos_trabajados, minutos_extras}) => ({
                laborables: horas_laborables,
                trabajadas: createTiempo(minutos_trabajados),
                extras: createTiempo(minutos_extras),
            }));
    }
}

export const dataProvider = new OperariosProvider();
