import React, {useEffect, useMemo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import EventIcon from '@material-ui/icons/Event';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import {useHistory, useLocation} from "react-router-dom";
import clsx from "clsx";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import useAuthState from "../../contexts/AuthState";
import MuiListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles from "@material-ui/core/styles/withStyles";
import VacacionesIcon from "../icons/Vacaciones";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import FichajesIcon from "@material-ui/icons/Timer";
import Fab from "@material-ui/core/Fab";


const ListItemIcon = withStyles(theme => ({
    root: {
        minWidth: theme.spacing(4),
    },
}))(MuiListItemIcon);

const useStyles = makeStyles({
    root: {
        position: 'relative',
        height: 83,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.15)',
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
    },
    action: {
        minWidth: 0,
        fontSize: 9,
        fontWeight: 500,
        opacity: 0.5,
        color: '#A7AFB8',
    },
    actionSelected: {
        color: '#45486E',
        fontWeight: 500,
        opacity: 1,
        '& $actionLabel': {
            fontSize: 11,
            whiteSpace: 'nowrap',
        },
    },
    actionLabel: {
        fontSize: 9,
    },
    addButton: {
        position: 'absolute',
        top: -74,
        right: 16,
        zIndex: 999,
    },
});


export default function Navigation() {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const [value, setValue] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isListaTareas = location.pathname === '/';

    const {
        userInfo: {
            preferencias: {
                usar_fichajes_vacaciones = false,
            } = {},
        } = {},
    } = useAuthState();

    const options = useMemo(() => {
        const options = [{
            label: 'Servicios',
            icon: <PlaylistAddCheckIcon />,
            path: '/',
        }, {
            label: 'Jornada',
            icon: <AssignmentIcon />,
            path: '/jornada',
        }, {
            label: 'Calendario',
            icon: <EventIcon />,
            path: '/calendario',
        }];

        if (usar_fichajes_vacaciones)
            options.push({
                label: 'Fichajes',
                icon: <FichajesIcon />,
                path: '/fichajes',
                partial: true,
            });

        return options;
    }, [usar_fichajes_vacaciones]);

    useEffect(() => {
        setValue(options.findIndex(option => option.partial ? location.pathname.startsWith(option.path) : location.pathname === option.path))
    }, [options]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const {logout, isCompany, userInfo: {name: userName}} = useAuthState();

    return (
        <>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <ListItem>
                    <ListItemText primary={<>Hola&nbsp;<strong>{userName}</strong></>} />
                </ListItem>
                <Divider />
                {isCompany &&
                <MenuItem onClick={() => history.push('/tareas-admin')}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Editar servicios" />
                </MenuItem>}
                <MenuItem onClick={() => history.push('/tarea/nueva')}>
                    <ListItemIcon>
                        <AddIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Añadir servicio" />
                </MenuItem>
                {usar_fichajes_vacaciones &&
                <MenuItem onClick={() => history.push('/solicitudes')}>
                    <ListItemIcon>
                        <VacacionesIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Vacaciones y ausencias" />
                </MenuItem>}
                <MenuItem
                    onClick={() => {
                        handleClose();
                        logout();
                    }}
                >
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Cerrar sesión" />
                </MenuItem>
            </Menu>
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                showLabels
                className={classes.root}
            >
                {options.map((option, i) => (
                    <BottomNavigationAction
                        selected={true}
                        key={i}
                        label={option.label}
                        icon={option.icon}
                        classes={{
                            root: clsx(classes.action, option.alwaysSelected && classes.actionSelected),
                            selected: classes.actionSelected,
                            label: classes.actionLabel,
                        }}
                        onClick={() => history.push(option.path)}
                    />
                ))}
                <BottomNavigationAction
                    selected={true}
                    label={'Más'}
                    icon={<MoreVertIcon/>}
                    classes={{
                        root: classes.action,
                        selected: classes.actionSelected,
                        label: classes.actionLabel,
                    }}
                    onClick={handleClick}
                />
                {isListaTareas &&
                    <Fab
                        color='primary'
                        className={classes.addButton}
                        onClick={() => history.push('/tarea/nueva')}
                    >
                        <AddIcon />
                    </Fab>}
            </BottomNavigation>
        </>
    );
}