import React, {useEffect, useState} from "react";
import ResumenItem from "./ResumenItem";
import {solicitudesProvider} from "../../api";
import AddIcon from "@material-ui/icons/Add";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {FieldArray, Form, Formik} from "formik";
import * as Yup from "yup";
import MuiButton from "@material-ui/core/Button";
import {Input} from "../common/Input";
import {DateRangePicker} from "../common/DateRangePicker";
import {ButtonDialog} from "../common/ButtonDialog";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "../common/Button";
import {useHistory} from "react-router-dom";
import {StartLabelSwitchFormik} from "../common/SwitchWithLabel";
import {PageBodySection} from "../common/PageBody";
import useSplash from "../../contexts/SplashState";
import {formatDate, formatDateTime} from "../../utils";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: 30,
        margin: theme.spacing(0.5, 0),
    },
    label: {
        color: '#213061',
        fontSize: 16,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1.5),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    solicitarButton: {
        marginTop: 'auto',
        marginBottom: theme.spacing(2),
    },
    dateRange: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
        '& .MuiInput-root': {
            background: 'white',
            borderRadius: 4,
        },
        '& .MuiInput-input': {
            fontSize: 13,
            padding: 10,
        },
        '&&& .MuiInput-underline:after': {
            borderBottom: 'none',
        },
        '&&& .MuiInput-underline:before': {
            borderBottom: 'none',
        },
        '& .MuiSvgIcon-root': {
            width: 20,
            height: 20,
        },
    },
    errors: {
        fontSize: '0.75rem',
        color: '#f44336',
        marginBottom: theme.spacing(2),
    },
    switchLabel: {
        flex: 1,
        color: '#213061',
        fontSize: 16,
    },
    confirmContentItem: {
        fontSize: 14,
        color: '#8F95AF',
        display: 'flex',
        '& span': {
            flex: 1,
            whiteSpace: 'nowrap',
        },
    },
}), {name: 'NuevaSolicitudVacaciones'});

const SolicitudAusenciaSchema = Yup.object().shape({
    motivo: Yup.string().required('Requerido'),
    fecha_inicio: Yup.date().typeError('La fecha de inicio debe ser una fecha').required('Requerido'),
    fecha_fin: Yup.date().typeError('La fecha de fin debe ser una fecha').required('Requerido').min(
        Yup.ref('fecha_inicio'),
        "La fecha de fin debe ser posterior a la de inicio"
    ),
});

export default function NuevaAusencia() {
    const classes = useStyles();
    const history = useHistory();

    const {showSplash} = useSplash();

    return (
        <>
            <Formik
                initialValues={{
                    motivo: '',
                    allDay: true,
                    fecha_inicio: new Date(),
                    fecha_fin: new Date(),
                }}
                enableReinitialize={true}
                validationSchema={SolicitudAusenciaSchema}
                onSubmit={({fecha_inicio, fecha_fin, allDay, ...values}, actions) => {
                    if (allDay) {
                        fecha_inicio.setHours(0, 0, 0, 0);
                        fecha_fin.setHours(23, 59, 59, 0);
                    }

                    solicitudesProvider
                        .registrarAusencia({fecha_inicio, fecha_fin, ...values})
                        .then(() => {
                            actions.setSubmitting(false);
                            showSplash({
                                title: 'Ausencia registrada',
                                onClick: () => history.push('/solicitudes'),
                            });
                        })
                        .catch(() => {
                            actions.setSubmitting(false);
                        });
                }}
            >
                {({values, errors, setFieldValue, submitForm, isValid}) => {
                    const fechaErrors = ['fecha_inicio', 'fecha_fin'].map(key => errors[key]).filter(Boolean);

                    return (
                        <Form className={classes.form}>
                            <Typography className={classes.label}>Motivo de la ausencia</Typography>
                            <Input
                                name='motivo'
                                placeholder='Ej: Personal, Familiar, Médico, ...'
                                disableUnderline
                                style={{marginBottom: 16}}
                            />
                            <StartLabelSwitchFormik
                                name='allDay'
                                label='Todo el dia'
                                classes={{
                                    label: classes.switchLabel,
                                }}
                            />
                            <div className={classes.dateRange}>
                                <DateRangePicker
                                    fecha={values.fecha_inicio}
                                    allDay={values.allDay}
                                    setFieldValue={setFieldValue}
                                    fechaInicioKey='fecha_inicio'
                                    fechaFinKey='fecha_fin'
                                    showError={false}
                                />
                            </div>
                            {fechaErrors.length > 0 &&
                            <div className={classes.errors}>
                                {fechaErrors.map((err, j) => (
                                    <div key={j}>{err}</div>
                                ))}
                            </div>
                            }
                            <ButtonDialog
                                title='¿Estás seguro de registrar esta ausencia?'
                                content={
                                    <Typography className={classes.confirmContentItem}>
                                        <span>{(values.allDay ? formatDate : formatDateTime)(values.fecha_inicio)}</span>
                                        <span>a</span>
                                        <span>{(values.allDay ? formatDate : formatDateTime)(values.fecha_fin)}</span>
                                    </Typography>
                                }
                                button={
                                    <Button
                                        className={classes.solicitarButton}
                                        color='info'
                                    >
                                        Registrar ausencia
                                    </Button>
                                }
                                okText='Registrar'
                                onAccept={submitForm}
                            />
                        </Form>
                    )
                }}
            </Formik>


        </>
    );
}



