import Button from "@material-ui/core/Button";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

export const RoundedButton = withStyles(theme => ({
    root: {
        borderRadius: 30,
        minWidth: 250,
        height: 42,
        color: 'white',
        backgroundColor: '#213061',
        '&:active, &:focus': {
            backgroundColor: '#213061',
        },
    },
    label: {
        fontWeight: 500,
        fontSize: 14,
    },
    outlined: {
        color: '#213061',
        backgroundColor: 'transparent',
        borderColor: '#213061',
        '&:active, &:focus': {
        },
    }
}), {name: 'RoundedButton'})(Button);