import React from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {PARTE_MANTENIMIENTO, PARTES_MANTENIMIENTO_RESUMEN, useTareaState} from "../TareaView";
import clsx from "clsx";
import {BigButton} from "../../common/BigButton";
import {FirmarView} from "../FirmarView";

const PENDIENTE = 'PENDIENTE';
const EN_PROGRESO = 'EN_PROGRESO';
const TERMINADO = 'TERMINADO';

const estadoLabels = {
    [PENDIENTE]: 'Pendiente',
    [EN_PROGRESO]: 'En progreso',
    [TERMINADO]: 'Terminado',
};

const useStyles = makeStyles(theme => ({
    item: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #D8DAE1',
        padding: theme.spacing(2, 3),
        '&:first-of-type': {
            marginTop: -8,
        },
    },
    title: {
        flex: 1,
        fontSize: 13,
        fontWeight: 600,
    },
    chip: {
        fontSize: 8,
        padding: theme.spacing(0.5, 1),
        marginRight: theme.spacing(2),
        borderRadius: 10,
        textTransform: 'uppercase',
        width: 65,
        textAlign: 'center',
        [`&.${PENDIENTE}`]: {
            backgroundColor: '#FEE5E5',
            color: '#E94F4F',
        },
        [`&.${EN_PROGRESO}`]: {
            backgroundColor: '#FFECCF',
            color: '#F3AC3D',
        },
        [`&.${TERMINADO}`]: {
            backgroundColor: '#CFF3E9',
            color: '#2CBD96',
        },
    },
    button: {
        margin: theme.spacing(2),
        marginTop: 'auto',
    },
    info: {
        fontSize: 10,
        color: '#818CAE',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(1),
        '& svg': {
            fontSize: 13,
            marginRight: theme.spacing(0.5),
        },
    },
}), {name: 'PartesMantenimientoListView'});


export const PartesMantenimientoListView = () => {
    const classes = useStyles();
    const {tarea, setView} = useTareaState();

    return (
        <>
            {tarea.partes_mantenimiento.map((parteMantenimiento, index) => {
                const terminado = parteMantenimiento.terminado;
                const empezado = parteMantenimiento.respuestas.length > 0;

                let estado = PENDIENTE;
                if (terminado)
                    estado = TERMINADO;
                else if (empezado)
                    estado = EN_PROGRESO;

                return (
                    <div key={index} className={classes.item} onClick={() => setView(PARTE_MANTENIMIENTO, {parteMantenimiento})}>
                        <Typography className={classes.title}>{parteMantenimiento.equipo.nombre}</Typography>
                        <div className={clsx(classes.chip, estado)}>{estadoLabels[estado]}</div>
                        <ChevronRightIcon/>
                    </div>
                )
            })}
            <BigButton
                style={{ margin: 'auto 24px 24px 24px'}}
                onClick={() => setView(PARTES_MANTENIMIENTO_RESUMEN)}
            >
                RESÚMEN DEL MANTENIMIENTO
            </BigButton>
        </>
    );
};