import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function OperariosIcon(props) {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            {...props}
        >
            <path d="M8 13C5.67 13 1 14.17 1 16.5V18C1 18.55 1.45 19 2 19H14C14.55 19 15 18.55 15 18V16.5C15 14.17 10.33 13 8 13ZM16 13C15.71 13 15.38 13.02 15.03 13.05C15.05 13.06 15.06 13.08 15.07 13.09C16.21 13.92 17 15.03 17 16.5V18C17 18.35 16.93 18.69 16.82 19H22C22.55 19 23 18.55 23 18V16.5C23 14.17 18.33 13 16 13Z" fill="#757575"/>
            <path d="M19.99 8.00002C19.99 9.66002 18.66 11 17 11C15.34 11 14 9.66002 14 8.00002H19.99Z" fill="#757575"/>
            <path d="M10.99 8C10.99 9.66 9.66 11 8 11C6.34 11 5 9.66 5 8H10.99Z" fill="#757575"/>
            <path d="M4.4122 7H11.5878C11.8149 7 12 6.824 12 6.608V6.592C12 6.376 11.8149 6.2 11.5878 6.2H11.3607C11.3607 5.016 10.6793 4 9.67823 3.44V4.2C9.67823 4.424 9.49317 4.6 9.25762 4.6C9.02208 4.6 8.83701 4.424 8.83701 4.2V3.112C8.56782 3.048 8.29022 3 7.99579 3C7.70137 3 7.42376 3.048 7.15457 3.112V4.2C7.15457 4.424 6.96951 4.6 6.73396 4.6C6.49842 4.6 6.31335 4.424 6.31335 4.2V3.44C5.3123 4 4.63091 5.016 4.63091 6.2H4.4122C4.18507 6.2 4 6.376 4 6.592V6.616C4 6.824 4.18507 7 4.4122 7Z" fill="#757575"/>
            <path d="M13.4122 7H20.5878C20.8149 7 21 6.824 21 6.608V6.592C21 6.376 20.8149 6.2 20.5878 6.2H20.3607C20.3607 5.016 19.6793 4 18.6782 3.44V4.2C18.6782 4.424 18.4932 4.6 18.2576 4.6C18.0221 4.6 17.837 4.424 17.837 4.2V3.112C17.5678 3.048 17.2902 3 16.9958 3C16.7014 3 16.4238 3.048 16.1546 3.112V4.2C16.1546 4.424 15.9695 4.6 15.734 4.6C15.4984 4.6 15.3134 4.424 15.3134 4.2V3.44C14.3123 4 13.6309 5.016 13.6309 6.2H13.4122C13.1851 6.2 13 6.376 13 6.592V6.616C13 6.824 13.1851 7 13.4122 7Z" fill="#757575"/>
        </SvgIcon>
    );
}