import React, {useEffect, useState} from "react";
import {GENERAL} from "../nueva/NuevaTarea";
import ListView from "../../common/ListView";
import {clientesProvider} from "../../../api";


export default ({tarea, setFieldValue, setCurrentFieldComponent, clienteId}) => {
    const [direcciones, setDirecciones] = useState(null);

    useEffect(() => {
        clientesProvider.actionOnId(clienteId, 'direcciones/as_options', null, {method: 'get'}).then(setDirecciones);
    }, [clienteId]);


    const columns = React.useMemo(() => [
        {
            Header: 'Nombre',
            accessor: 'nombre',
        }, {
            Header: 'Direccion',
            accessor: 'direccion_completa',
        }
    ], []);

    return direcciones && (
        <ListView
            title='Seleccionar direccion'
            onClose={() => setCurrentFieldComponent(GENERAL)}
            setFieldValue={setFieldValue('direccion')}
            currentValue={tarea.direccion}
            items={direcciones}
            columns={columns}
            searchPlaceholder='Buscar direccion'
        />
    )
}
