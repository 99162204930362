import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function VacacionesIcon(props) {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            {...props}
        >
            <path d="M12.4381 13.6323L13.6298 12.4406L18.9965 17.8098L17.8073 18.999L12.4381 13.6323ZM16.0156 8.8573L18.399 6.47397C15.1073 3.1823 9.77397 3.17397 6.4823 6.4573C9.7573 5.37397 13.4073 6.24897 16.0156 8.8573V8.8573ZM6.4573 6.4823C3.17397 9.77397 3.1823 15.1073 6.47397 18.399L8.8573 16.0156C6.24897 13.4073 5.37397 9.7573 6.4573 6.4823ZM6.47397 6.46563L6.46563 6.47397C6.14897 8.9823 7.44063 12.2073 10.049 14.824L14.824 10.049C12.2156 7.44063 8.9823 6.14897 6.47397 6.46563V6.46563Z" />
        </SvgIcon>
    );
}