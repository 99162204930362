import React, {useEffect} from "react";
import {MaterialesJornada} from "./MaterialesJornada";
import {Formik, useFormikContext} from "formik";
import InfoIcon from "@material-ui/icons/Info";

import * as Yup from 'yup';
import {deepEqual, formatISODate} from "../../../utils";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {PageBodySection} from "../../common/PageBody";
import {useTareaState} from "../TareaView";

const MaterialSchema = Yup.object().shape({
    jornadas: Yup.array().of(
        Yup.object().shape({
            materiales: Yup.array().of(
                Yup.object().shape({
                    descripcion: Yup.string().required('Requerido'),
                    unidades: Yup.string().required('Requerido'),
                })
            )
        })
    )
});

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        '&:last-of-type': {
            marginBottom: theme.spacing(2),
        },
    },
    title: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#818CAE',
        '& span': {
            fontWeight: 'normal',
        },
    },
    info: {
        fontSize: 10,
        color: '#818CAE',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        '& svg': {
            fontSize: 13,
            marginRight: theme.spacing(0.5),
        },
    },
}));

export const MaterialUtilizado = ({jornadas, setJornadas}) => {

    return (
        <Formik
            initialValues={{
                jornadas,
            }}
            enableReinitialize={true}
            validationSchema={MaterialSchema}
        >
            {() => (
                <MaterialUtilizadoForm jornadas={jornadas} setJornadas={setJornadas} />
            )}
        </Formik>

    );
};


function MaterialUtilizadoForm({jornadas, setJornadas}) {
    const styles = useStyles();
    const {values} = useFormikContext();
    const todayStr = formatISODate(new Date());
    const hasToday = jornadas.findIndex(jornada => jornada.fecha === todayStr) >= 0;
    const { setGoBackCheck } = useTareaState();

    useEffect(() => {
        setGoBackCheck(function () {
            return deepEqual(jornadas, values.jornadas);
        });
    }, [setGoBackCheck, values, jornadas]);

    return (
        <>
            {!hasToday &&
                <PageBodySection className={styles.root}>
                    <Typography className={styles.title}>Hoy</Typography>
                    <Typography className={styles.info}><InfoIcon /> Registra el tiempo para poder añadir el material de hoy.</Typography>
                </PageBodySection>}
            {values.jornadas.map((jornada, i) => (
                <MaterialesJornada
                    key={i}
                    index={i}
                    jornada={jornada}
                    onSaveMateriales={(materiales) => setJornadas(jornadas => {
                        let newMateriales = [...jornadas[i].materiales];

                        materiales.forEach(({idx, material}) => {
                            newMateriales[idx] = material;
                        });

                        newMateriales = newMateriales.filter(material => material !== null);

                        return [
                            ...jornadas.slice(0, i),
                            {
                                ...jornadas[i],
                                materiales: newMateriales,
                            },
                            ...jornadas.slice(i + 1),
                        ]
                    })}
                />))}
        </>
    )
}

