import React, {useState} from "react";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import {makeStyles} from "@material-ui/core/styles";
import {PageBodySection} from "../../common/PageBody";
import {FOTO, useTareaState} from "../TareaView";
import {tareasProvider} from "../../../api";
import ImageTools from "../../../utils/ImageTools";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as classnames from "classnames";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: `${theme.spacing(2)}px`,
    },
    addFoto: {
        boxSizing: 'border-box',
        borderRadius: 4,
        width: 60,
        height: 60,
        border: '1px dashed #B5BCD1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
        '& svg': {
            color: '#B5BCD1',
        },
    },
    foto: {
        position: 'relative',
        color: '#818CAE',
        fontSize: 13,
        width: 60,
        height: 60,
        marginBottom: theme.spacing(2),
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: 4,
        },
        '&.uploading img': {
            opacity: 0.5,
        },
        '& .MuiCircularProgress-root': {
            color: 'black',
            position: 'absolute',
            top: 'calc(50% - 20px)',
            left: 'calc(50% - 20px)',
        },
    },
    avatar: {
        position: 'absolute',
        top: -11,
        right: -11,
        backgroundColor: 'white',
        color: '#2196F3',
        fontWeight: 700,
        fontSize: 10,
        borderRadius: '50%',
        width: 22,
        height: 22,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));


export function Fotos({jornadaId, fotos, disabled}) {
    const classes = useStyles();
    const {addFoto, openFotoSlideshow} = useTareaState();

    const [fotosUploading, setFotosUploading] = useState([]);

    const addButtonId = `add-foto-${jornadaId}`;
    return (
        <div className={classes.root}>
            {!disabled && <label htmlFor={addButtonId} className={classes.addFoto}>
                <AddAPhotoIcon/>
                <input
                    id={addButtonId}
                    type="file"
                    multiple
                    accept="image/x-png,image/jpeg"
                    style={{display: 'none'}}
                    onChange={ev => {
                        const files = ev.target.files;
                        if (files.length === 0)
                            return;

                        setFotosUploading(new Array(files.length).fill({
                            thumbnail_url: null,
                        }));

                        new Array(...files).forEach((file, i) => {
                            new ImageTools()
                                .resize(file, {width: 200, height: 200})
                                .then(blob => {
                                    const url = URL.createObjectURL(blob);
                                    setFotosUploading(
                                        fotos => fotos.map(
                                            (foto, j) =>
                                                j === i ? {...foto, thumbnail_url: url} : foto
                                        )
                                    );
                                });

                            new ImageTools()
                                .resize(file, {width: 3000, height: 3000})
                                .then(blob => {
                                    const formData = new FormData();
                                    formData.append('fotos', blob)

                                    tareasProvider
                                        .action(`jornadas/${jornadaId}/fotos`, {
                                            method: 'post',
                                            body: formData
                                        })
                                        .then(newFotos => {
                                            setFotosUploading(
                                                fotos => fotos.map(
                                                    (foto, j) =>
                                                        j === i ? null : foto
                                                )
                                            )
                                            newFotos.forEach(newFoto => addFoto(jornadaId, newFoto));
                                        })
                                        .catch(err => {
                                            console.log(err);
                                            setFotosUploading(
                                                fotos => fotos.map(
                                                    (foto, j) =>
                                                        j === i ? null : foto
                                                )
                                            )
                                        })
                                });
                        });

                    }}
                />
            </label>}
            {fotos.map((foto, i) => (
                <div
                    key={i}
                    className={classes.foto}
                    onClick={() => openFotoSlideshow(fotos, i)}
                >
                    {/*<div className={classes.avatar}>{foto.operarioInitials}</div>*/}
                    <img src={foto.thumbnail_url}/>
                </div>
            ))}
            {fotosUploading.filter(Boolean).map((foto, i) => (
                <div
                    key={i}
                    className={classnames(classes.foto, 'uploading')}
                >
                    <img src={foto.thumbnail_url}/>
                    <CircularProgress />
                </div>
            ))}
        </div>
    );
}