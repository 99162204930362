import React, {useState} from "react";
import {PageHeader} from "../../common/PageHeader";
import {PageBody} from "../../common/PageBody";
import {TextField} from "../common/TextField";
import {ButtonField} from "../common/ButtonField";
import {formatDate} from "../../../utils";
import {BigButton} from "../../common/BigButton";
import {DIRECCION, FECHA, OPERARIO} from "./EditarTarea";
import {tareasProvider} from "../../../api";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import red from "@material-ui/core/colors/red";

export default ({tarea, setFieldValue, setCurrentFieldComponent, onClose, onSave, onDelete}) => {
    return (
        <React.Fragment>
            <PageHeader
                title='Editar servicio'
                startButton={
                    <IconButton
                        onClick={onClose}
                    >
                        <CloseIcon style={{color: 'white'}}/>
                    </IconButton>
                }
            />
            <PageBody>
                <ButtonField
                    label='Cliente'
                    value={tarea.cliente}
                />
                <ButtonField
                    label='Direccion'
                    value={tarea.direccion && tarea.direccion.nombre}
                    placeholder={'Selecciona una direccion'}
                    onClick={() => setCurrentFieldComponent(DIRECCION, {clienteId: tarea.cliente_id})}
                />
                <TextField
                    label='Tarea'
                    value={tarea.descripcion}
                    onChange={setFieldValue('descripcion')}
                    placeholder='Descripción de la tarea'
                />
                <ButtonField
                    label='Fecha'
                    value={tarea.fecha && formatDate(new Date(tarea.fecha))}
                    placeholder='Selecciona una fecha'
                    onClick={() => setCurrentFieldComponent(FECHA)}
                />
                <ButtonField
                    label='Operarios'
                    value={tarea.operarios && tarea.operarios.map(operario => operario.nombre).join(', ')}
                    placeholder='Selecciona un operario'
                    onClick={() => setCurrentFieldComponent(OPERARIO)}
                />
                <BigButton
                    style={{marginTop: 'auto'}}
                    disabled={!(tarea.cliente && tarea.descripcion && tarea.fecha && tarea.operarios.length > 0)}
                    onClick={() => {
                        const fecha = new Date(tarea.fecha);
                        fecha.setHours(0, 0, 0, 0);
                        const values = {
                            direccion_id: tarea.direccion.id,
                            descripcion: tarea.descripcion,
                            fecha,
                            operarios: tarea.operarios,
                        };

                        tareasProvider.save(values, tarea.id).then(onSave);
                    }}
                >
                    Guardar servicio
                </BigButton>
                {tarea.estado === 'PENDIENTE' &&
                <BorrarTareaDialog
                    id={tarea.id}
                    onDelete={onDelete}
                />}
            </PageBody>
        </React.Fragment>
    );
}

export const DeleteButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        textTransform: 'none',
        fontSize: 15,
        padding: 16,
        borderRadius: 8,
        marginBottom: 24,
        '&:hover': {
            backgroundColor: red[700],
        },
    },
}))(({children, ...props}) => (
    <Button
        startIcon={<DeleteIcon/>}
        variant='contained'
        {...props}
    >
        {children}
    </Button>
));

function BorrarTareaDialog({id, onDelete}) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <DeleteButton
                onClick={() => setOpen(true)}
            >
                Borrar servicio
            </DeleteButton>
            <Dialog
                open={open}
            >
                <DialogTitle
                    disableTypography
                    style={{
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 600,
                    }}
                >
                    ¿Estás seguro que quieres borrar esta tarea?
                </DialogTitle>
                <DialogActions style={{justifyContent: 'space-between', padding: 20}}>
                    <Button onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            tareasProvider.delete(id).then(() => {
                                setOpen(false);
                                onDelete && onDelete();
                            });
                        }}
                        variant='contained'
                        color="primary"
                        autoFocus
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}