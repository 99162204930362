import useSplash from "../../../contexts/SplashState";
import {useField} from "formik";
import {tareasProvider} from "../../../api";
import {RoundedInput} from "../RoundedInput";
import SearchIcon from "@material-ui/icons/Search";
import {SelectMaterialView} from "./SelectMaterialView";
import {DeleteDialog} from "./DeleteDialog";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import ReplayIcon from "@material-ui/icons/Replay";
import IconButton from "@material-ui/core/IconButton";
import {deepEqual} from "../../../utils";

const useStyles = makeStyles(theme => ({
    lineaMaterial: {
        display: 'flex',
        marginLeft: theme.spacing(-1),
    },
}), {name: 'MaterialJornada'});

export function MaterialJornada({name, jornadaId, disabled, material, onDelete, canDelete, onAddExtra}) {
    const styles = useStyles();
    const {showCustomComponent} = useSplash();

    const [, {value, initialValue}, helpers] = useField(name);

    const deleteMaterial = (jornadaId, materialId) => {
        tareasProvider
            .getAll(`jornadas/${jornadaId}/materiales/${materialId}`, {method: 'delete'})
            .then(() => {
                onDelete();
            })
    };

    const canReset = Boolean(initialValue && material.id !== undefined) && !deepEqual(initialValue, value);

    return (
        <div className={styles.lineaMaterial}>
            <RoundedInput
                placeholder={'Material'}
                name={`${name}.descripcion`}
                style={{flex: 1}}
                disabled={disabled}
            />
            <RoundedInput
                placeholder={'Uds'}
                name={`${name}.unidades`}
                style={{width: 80}}
                disabled={disabled}
            />
            <IconButton
                size='small'
                onClick={() => {
                    if (disabled)
                        return;

                    showCustomComponent(({closeSplash}) => (
                        <SelectMaterialView
                            onChange={selectedMaterial => {
                                const [thisSelectedMaterial, ...extraSelectedMateriales] =
                                    Array.isArray(selectedMaterial) ?
                                        selectedMaterial :
                                        [selectedMaterial];

                                const material = {
                                    ...value,
                                    descripcion: thisSelectedMaterial.descripcion,
                                };
                                if (selectedMaterial.id)
                                    material.material_interno_id = thisSelectedMaterial.id;

                                helpers.setValue(material);

                                if (extraSelectedMateriales.length > 0)
                                    onAddExtra(extraSelectedMateriales);

                                closeSplash();
                            }}
                            onClose={closeSplash}
                            currentValue={material}
                        />
                    ));
                    setTimeout(() => {
                        document.querySelector('#global-filter-input').focus();
                    }, 200);
                }}
            >
                <SearchIcon style={{fontSize: 20}}/>
            </IconButton>
            {canReset ?
                <IconButton size='small' onClick={() => helpers.setValue(initialValue)}>
                    <ReplayIcon style={{fontSize: 20}}/>
                </IconButton>
            :
            <DeleteDialog
                onDelete={() => deleteMaterial(jornadaId, material.id)}
                disabled={disabled || material.id === undefined || !canDelete}
            />}
        </div>
    )
}