import React, {useCallback, useEffect, useRef, useState} from "react";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ReplayIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import withStyles from "@material-ui/core/styles/withStyles";
import {PageBodySection} from "../../common/PageBody";
import {useSnackbar} from "material-ui-snackbar-provider";
import debounce from "@material-ui/core/utils/debounce";
import {tareasProvider} from "../../../api";
import {useTareaState} from "../TareaView";
import {deepEqual} from "../../../utils";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import {makeStyles} from "@material-ui/core/styles";

const Input = withStyles({
    root: {
        color: '#818CAE',
        fontSize: 13,
        height: '100%',
    },
    inputMultiline: {
        height: '100%',
    },
})(InputBase);

const useStyles = makeStyles(theme => ({
    saving: {
        fontSize: 12,
        color: '#818CAE',
        gap: `${theme.spacing(1)}px`,
        display: 'flex',
        alignItems: 'center',
    },
    error: {
        color: '#F24822',
    },
}), {name: 'AddNota'});

export const AddNota = ({id, currentNotas, onSave}) => {
    const classes = useStyles();
    const [notas, setNotas] = useState(currentNotas?.texto ?? '');
    const [initialNotas, ] = useState(currentNotas?.texto ?? '');
    const [saving, setSaving] = useState(null);
    const [error, setError] = useState(false);
    const {viewParams: {jornada}} = useTareaState();

    const inputRef = useRef(null);

    useEffect(() => {
        if (!inputRef.current)
            return;

        inputRef.current.focus();
        inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
    }, [inputRef]);

    const changed = notas !== initialNotas;

    const saveNotas = useCallback(
        notas =>
            tareasProvider
                .updateJornadaNotas(jornada.id, notas)
                .then((updatedNotas) => {
                    setError(false);
                    onSave(updatedNotas);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => setSaving(false)),
        [id, onSave]);

    const debouncedSaveNotas = useCallback(debounce(saveNotas, 1000), [saveNotas]);

    function changeNotas(value) {
        setSaving(true);
        setError(false);
        setNotas(value);
        debouncedSaveNotas(value);
    }
    
    return <PageBodySection>
        <div
            style={{
                backgroundColor: '#fff',
                borderRadius: 8,
                padding: '8px 16px',
                position: 'relative',
                minHeight: 100,
                marginBottom: 24,
                marginTop: 16,
                flex: 1,
            }}
        >
            <Input
                fullWidth
                multiline
                rows={6}
                value={notas}
                onChange={ev => {
                    changeNotas(ev.target.value)
                }}
                inputRef={inputRef}
            />
            <div
                style={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8,
                    display: 'flex',
                }}
            >
                {!changed  &&
                    <IconButton
                        size='small'
                        onClick={() => changeNotas('')}
                    >
                        <DeleteIcon/>
                    </IconButton>
                }
            </div>
        </div>
        {saving !== null &&
            <>
                <div className={clsx(classes.saving, {[classes.error]: error})}>
                    {saving && <><CircularProgress size={16} /> Guardando cambios...</>}
                    {!saving && error && <><CloseIcon fontSize='small' /> Ha ocurrido un error al guardar</>}
                    {!saving && !error && <><DoneIcon fontSize='small' /> Guardado</>}
                </div>
            </>}
    </PageBodySection>;
};