import React, {useEffect, useMemo, useState} from "react";
import {PageHeader} from "../common/PageHeader";
import {PageBody} from "../common/PageBody";
import Navigation from "../common/Navigation";
import {addTiempo, createTiempo, getMinutos, getTiempoJornadas, isTiempoZero} from "../../api/tareas-functions";
import {useHistory} from "react-router-dom";
import {createMuiTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";
import {format, formatISODate} from "../../utils";
import {DatePicker} from "@material-ui/pickers";
import Typography from "@material-ui/core/Typography";
import classNames from 'classnames';
import {calendariosProvider, tareasProvider} from "../../api";
import {processTarea} from "../../api/tareas";
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import {ResumenTiempo} from "./ResumenTiempo";


const useStyles = makeStyles(theme => ({
    subtitle: {
        fontSize: 14,
        color: 'white',
        margin: theme.spacing(2, 3),
        textAlign: 'center',
    },
    jornadaVacia: {
        color: '#8F95AF',
    },
    jornadaCompleta: {
        '& button': {
            backgroundColor: '#CFF3E9',
            color: '#2CBD96',
        }
    },
    jornadaParcial: {
        '& button': {
            backgroundColor: '#FCE7C5',
            color: '#F3AC3D',
        },
    },
    noLaborable: {
        '& button': {
            border: '1px solid #EA5759',
        },
    },
    jornadaBullet: {
        backgroundColor: '#8F95AF',
        width: 12,
        height: 12,
        borderRadius: 4,
        marginRight: theme.spacing(1),
    },
    jornadaCompletaBullet: {
        backgroundColor: '#2CBD96',
    },
    jornadaParcialBullet: {
        backgroundColor: '#F3AC3D',
    },
    noLaborableBullet: {
        backgroundColor: 'transparent',
        border: '1px solid #EA5759',
        boxSizing: 'border-box',
    },
    infoPaper: {
        backgroundColor: 'white',
        borderRadius: 8,
        padding: theme.spacing(2),
        '&>div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            color: '#213061',
        },
        '&>div+div': {
            marginTop: theme.spacing(1.5),
        },
        marginBottom: theme.spacing(3),
    },
}));

const materialTheme = createMuiTheme({
    overrides: {
        MuiTypography: {
            body2: {
                fontFamily: 'inherit',
                fontWeight: 'inherit',
            },
        },
        MuiButtonBase: {
            root: {
                fontFamily: 'Inter, sans-serif',
                fontSize: 13,
            }
        },
        MuiPickersBasePicker: {
            container: {
                alignItems: 'center',
            },
            pickerView: {
                minHeight: 340,
                justifyContent: 'flex-start',
            }
        },
        MuiPickersStaticWrapper: {
            staticWrapperRoot: {
                flex: 1,
                backgroundColor: 'transparent',
                overflow: 'initial',
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                color: '#213061',
                backgroundColor: 'transparent',
            },
            transitionContainer: {
                textTransform: 'capitalize',
                color: '#213061',
            },
            dayLabel: {
                color: '#213061',
                textTransform: 'uppercase',
                fontWeight: 600,
            },
        },
        MuiPickersDay: {
            day: {
                color: 'inherit',
                fontWeight: 'normal',
                borderRadius: 8,
                margin: 2,
            },
            daySelected: {
                backgroundColor: 'transparent',
                color: 'inherit',
                fontWeight: 'normal',
            },
            current: {
                fontWeight: 700,
                backgroundColor: 'inherit',
                color: '#45486E',
                border: '2px solid #45486E',
                '&>span:first-of-type': {
                    display: 'flex',
                    flexDirection: 'column',
                },
                '&>span:first-of-type::before': {
                    content: '"HOY"',
                    fontSize: 8,
                    marginTop: 3,
                    marginBottom: -3,
                },
            },
        },
    },
});

export const Calendario = () => {
    const thisMonth = new Date();
    thisMonth.setDate(1);

    const [currentMonth, setCurrentMonth] = useState(thisMonth);

    const [noLaborables, setNoLaborables] = useState([]);
    const [tareas, setTareas] = useState(null);
    const history = useHistory();
    const styles = useStyles();

    useEffect(() => {
        tareasProvider
            .getAll(`?mes=${format(currentMonth, 'M')}`)
            .then(tareas => {
                setTareas(tareas.map(processTarea))
            });
    }, [currentMonth]);

    const currentYear = currentMonth.getFullYear();

    useEffect(() => {
        calendariosProvider
            .getAll(`no_laborables/${currentYear}`)
            .then(setNoLaborables);
    }, [currentYear]);

    const horasTrabajadasJornada = useMemo(() => tareas && getTiempoJornadas(tareas), [tareas]);

    const {realizadas, laborables, extras} = useMemo(() => {
        if (!horasTrabajadasJornada)
            return {};

        let realizadas = createTiempo(0);
        let laborables = null;
        const ultimoDia = lastDayOfMonth(currentMonth);
        const dia = new Date(currentMonth);
        for (let i = 0; i < ultimoDia.getDate(); i++) {
            dia.setDate(i + 1);
            const isoDay = formatISODate(dia);
            const tiempo = horasTrabajadasJornada[isoDay];
            realizadas = addTiempo(realizadas, tiempo);

            if (!noLaborables.includes(isoDay)) {
                laborables += 8;
            }
        }

        const extras = createTiempo(getMinutos(realizadas) - laborables * 60);
        return { realizadas, laborables, extras };
    }, [currentMonth, horasTrabajadasJornada, noLaborables]);

    if (!tareas)
        return null;

    return (
        <React.Fragment>
            <PageHeader
                title='Calendario laboral'
            >
                <span className={styles.subtitle}>
                    Selecciona un dia para ver el tiempo y servicios realizados
                </span>
                <ResumenTiempo
                    laborables={laborables}
                    realizadas={realizadas}
                    extras={extras}
                />
            </PageHeader>
            <PageBody>
                <ThemeProvider theme={materialTheme}>
                    <DatePicker
                        disableToolbar={true}
                        autoOk
                        openTo='date'
                        variant="static"
                        value={currentMonth}
                        onChange={ev => {
                            history.push(`/jornada/${formatISODate(ev)}`);
                        }}
                        onMonthChange={setCurrentMonth}
                        renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
                            const isoDay = formatISODate(day);
                            const tiempoJornada = horasTrabajadasJornada[isoDay];

                            const jornadaClass =
                                !tiempoJornada || isTiempoZero(tiempoJornada) ?
                                    styles.jornadaVacia :
                                    tiempoJornada.horas >= 8 ?
                                        styles.jornadaCompleta :
                                        styles.jornadaParcial;

                            return <span className={classNames(jornadaClass, {[styles.noLaborable]: noLaborables.includes(isoDay)})}>{dayComponent}</span>
                        }}
                    />
                </ThemeProvider>

                <div className={styles.infoPaper}>
                    <div><span className={classNames(styles.jornadaBullet, styles.jornadaCompletaBullet)}/> Jornada laboral de 8h o más</div>
                    <div><span className={classNames(styles.jornadaBullet, styles.jornadaParcialBullet)}/> Jornada laboral de menos de 8h</div>
                    <div><span className={styles.jornadaBullet}/> Sin registro</div>
                    <div><span className={classNames(styles.jornadaBullet, styles.noLaborableBullet)}/> No laborable</div>
                </div>

            </PageBody>
            <Navigation/>
        </React.Fragment>
    );
}