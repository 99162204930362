import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import {makeStyles} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import DialogContent from "@material-ui/core/DialogContent";
import {formatDate, formatDateTime, isAllDay} from "../../utils";

const useStyles = makeStyles(theme => ({
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 18,
        fontWeight: 500,
        padding: theme.spacing(1.5, 2, 1.5, 3),
    },
    content: {
        borderTop: '1px solid #F3F5FA',
        color: '#818CAE',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        padding: theme.spacing(2, 3),
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 14,
        gap: '2px',
        '& strong': {
            fontSize: 16,
            fontWeight: 500,
            color: '#1F2A4B',
        },
    }
}), {name: 'OtherOperariosDialog'});

export function OtherOperariosDialog({button, tarea}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const formButton = React.cloneElement(button, {onClick: () => setOpen(true)});

    const planificaciones = tarea.planificaciones.filter(
        planificacion => planificacion.operarios.some(asignacion => asignacion.operario.is_self && asignacion.visible)
    );

    return (
        <>
            {formButton}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
            >
                <DialogTitle
                    disableTypography
                    className={classes.title}
                >
                    Planificación y operarios

                    <IconButton
                        size='small'
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                {planificaciones.map((planificacion, i) => {
                    const formatFn = isAllDay(planificacion.fecha_inicio, planificacion.fecha_fin) ? formatDate : formatDateTime;

                    return (
                        <DialogContent key={i} className={classes.content}>
                            <div className={classes.section}>
                                <strong>Planificación:</strong>
                                <span>{formatFn(planificacion.fecha_inicio)} a {formatFn(planificacion.fecha_fin)}</span>
                            </div>
                            <div className={classes.section}>
                                <strong>Operarios:</strong>
                                {planificacion
                                    .operarios
                                    .filter(asignacion => asignacion.visible)
                                    .map((asignacion, j) => (
                                        <span key={j}>{asignacion.operario.nombre}</span>
                                    ))}
                            </div>
                        </DialogContent>
                    )
                })}
            </Dialog>
        </>
    )
}