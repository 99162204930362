import React, {useEffect, useMemo, useState} from "react";
import {PageHeader} from "../common/PageHeader";
import TabbedFilter from "../TabbedFilter";
import {PageBody} from "../common/PageBody";
import TareaCard from "../TareaCard";
import Navigation from "../common/Navigation";
import {addTiempo, createTiempo, getMinutos, getTiempoJornadas, isTiempoZero} from "../../api/tareas-functions";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {format, formatDate, formatISODate, formatTiempo} from "../../utils";
import isSameDay from 'date-fns/isSameDay'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {calendariosProvider, tareasProvider} from "../../api";
import {processTarea, processTareaForList} from "../../api/tareas";
import {ResumenTiempo} from "./ResumenTiempo";
import isBefore from "date-fns/isBefore";

const useStyles = makeStyles(theme => ({
    root: {
        whiteSpace: 'pre',
        fontWeight: 500,
        color: '#8F95AF',
        backgroundColor: 'white',
        textTransform: 'uppercase',
        width: 39,
        height: 54,
        padding: 0,
        paddingTop: 8,
        justifyContent: 'space-between',
        position: 'relative',
        '& .tiempoJornada': {
            fontSize: 8,
            textTransform: 'none',
            marginBottom: 4,
        },
        '& span.hoy': {
            borderRadius: 4,
            fontSize: 11,
            background: '#45486E',
            color: 'white',
            width: '100%',
            height: 18,
            marginBottom: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: -24,
            '&::after': {
                content: "''",
                width: 5,
                height: 5,
                display: 'block',
                transform: 'rotate(45deg)',
                background: '#45486E',
                position: 'absolute',
                bottom: -2,
            },
        },
        '& span.tiempoJornadaBullet': {
            position: 'absolute',
            top: 4,
            left: 4,
            width: 4,
            height: 4,
            borderRadius: '50%',
            backgroundColor: '#8F95AF',
        },
        '& span.jornadaCompleta': {
            backgroundColor: '#2CBD96',
        },
        '& span.jornadaParcial': {
            backgroundColor: '#F3AC3D',
        },
    },
    active: {
        backgroundColor: '#45486E',
        color: 'white',
    },
    horasTrabajadas: {
        color: 'white',
        fontSize: 12,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        display: 'flex',
        // justifyContent: 'space-between',
        '& span': {
            marginLeft: theme.spacing(0.5),
            fontSize: 12,
            fontWeight: 600,
        },
    },
}));

export const JornadaList = ({fecha}) => {
    const today = new Date();
    const todayStr = format(today, 'yyyy-MM-dd');

    const [noLaborables, setNoLaborables] = useState([]);
    const [tareas, setTareas] = useState(null);
    const [stateFilter, setStateFilter] = useState(fecha || todayStr);
    const history = useHistory();
    const styles = useStyles();

    const semana = format(new Date(stateFilter), 'I');
    useEffect(() => {
        tareasProvider
            .getAll(`?semana=${semana}`)
            .then(tareas => {
                const tareaList = tareas.map(processTareaForList).reduce((tareaList, processedTareas) => {
                    tareaList.push(...processedTareas);
                    return tareaList;
                }, []);

                tareaList.sort((a, b) => isBefore(a.fechaInicio, b.fechaInicio) ? -1 : 1);

                setTareas(tareaList);
                // setTareas(tareas.map(processTarea))
            });
    }, [semana]);

    const currentYear = new Date(stateFilter).getFullYear();

    useEffect(() => {
        calendariosProvider
            .getAll(`no_laborables/${currentYear}`)
            .then(setNoLaborables);
    }, [currentYear]);


    const horasTrabajadasJornada = useMemo(() => tareas && getTiempoJornadas(tareas), [tareas]);

    const { filters, realizadas, laborables, extras } = useMemo(() => {
        if (!horasTrabajadasJornada)
            return [];

        let realizadas = createTiempo(0);
        let laborables = null;
        const dayFilter = fecha ? new Date(fecha) : new Date();
        const dayOfWeek = dayFilter.getDay();
        dayFilter.setDate(dayFilter.getDate() - (dayOfWeek > 0 ? dayOfWeek : 7));
        const filters = [];

        for (let i=0; i<7; i++) {
            dayFilter.setDate(dayFilter.getDate() + 1);
            const dayFilterStr = format(dayFilter, 'yyyy-MM-dd');
            const tiempoJornada = horasTrabajadasJornada[dayFilterStr];

            realizadas = addTiempo(realizadas, tiempoJornada);
            if (!noLaborables.includes(dayFilterStr)) {
                laborables += 8;
            }

            filters.push({
                label:
                    <>
                        {differenceInCalendarDays(dayFilter, today) <= 0 &&
                        <span className={`tiempoJornadaBullet ${tiempoJornada && tiempoJornada.horas >= 8 ? 'jornadaCompleta' : !isTiempoZero(tiempoJornada) && 'jornadaParcial'}`}/>}
                        {format(dayFilter, "EEEEEE'\n'dd")}
                        <span className='tiempoJornada'>{formatTiempo(tiempoJornada)}</span>
                        {isSameDay(dayFilter, today) &&
                        <span className='hoy'>HOY</span>}
                    </>,
                value: format(dayFilter, 'yyyy-MM-dd')
            })
        }

        const extras = createTiempo(getMinutos(realizadas) - laborables * 60);

        return { filters, realizadas, laborables, extras };
    }, [today, fecha, horasTrabajadasJornada, noLaborables]);

    let filteredTareas = null;

    console.log(fecha, stateFilter)
    if (stateFilter && tareas) {
        filteredTareas = [];

        for (let tarea of tareas) {
            let tiempo = tarea.tiempoHoy;

            console.log(tarea.id, tiempo)
            if (stateFilter !== todayStr) {
                const jornada = tarea.jornadas.filter(j => j.propia).find(jornada => jornada.fecha === stateFilter);
                tiempo = jornada ? jornada.tiempo : null;
            }

            if (tiempo) {
                filteredTareas.push({...tarea, tiempoHoy: tiempo});
            }
        }
    }

    if (!tareas)
        return null;

    return (
        <React.Fragment>
            <PageHeader
                title='Jornada Laboral'
            >
                <TabbedFilter
                    style={{marginTop: 24, marginBottom: 16}}
                    onFilterClicked={setStateFilter}
                    classes={styles}
                    filters={filters}
                    selectedFilter={stateFilter}
                />
                <ResumenTiempo
                    laborables={laborables}
                    realizadas={realizadas}
                    extras={extras}
                />
            </PageHeader>
            <PageBody>
                {filteredTareas && filteredTareas.length > 0 ?
                    filteredTareas.map((tarea, i) => (
                        <TareaCard
                            key={i}
                            cliente={tarea.cliente}
                            descripcion={tarea.descripcion}
                            estado={tarea.estado}
                            tiempo={tarea.tiempoHoy}
                            tiempoAcumulado={tarea.tiempoAcumulado}
                            onClick={() => history.push(`/tarea/${tarea.id}`)}
                            numero={tarea.numero}
                            fechaInicio={tarea.fechaInicio}
                            fechaFin={tarea.fechaFin}
                            numOperarios={tarea.numOperarios}
                            tipo={tarea.tipo}
                        />
                    ))
                    :
                    <div
                        style={{
                            fontSize: 17,
                            color: '#818CAE',
                            textAlign: 'center',
                            marginTop: 64,
                        }}
                    >
                        No hay tareas registradas
                    </div>
                }
            </PageBody>
            <Navigation/>
        </React.Fragment>
    );
}