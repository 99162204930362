import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import MuiButton from "@material-ui/core/Button";


const buttonColors = {
    transparent: {
        background: 'transparent',
        color: '#1F2A4B',
    },
    normal: {
        background: '#E9EAEF',
        color: '#8F95AF',
    },
    success: {
        background: '#2CBD96',
        color: 'white',
    },
    error: {
        background: '#E94F4F',
        color: 'white',
    },
    info: {
        background: '#2196f3',
        color: 'white',
    },
    dark: {
        background: '#8F95AF',
        color: 'white',
    },
};

const Button = withStyles(theme => ({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: props => props.size === 'small' ? 12 : 14,
        fontWeight: 500,
        padding: theme.spacing(2),
        lineHeight: 1.5,
        borderRadius: props => props.radius ?? 8,
        width: props => props.fullWidth ? '100%' : 'auto',
        height: props => props.size === 'small' ? 32 : 'auto',
        color: props => buttonColors[props.color || 'normal'].color,
        backgroundColor: props => buttonColors[props.color || 'normal'].background,
        '&:hover': {
            backgroundColor: props => buttonColors[props.color || 'normal'].background,
        },
        '&:active': {
            backgroundColor: props => buttonColors[props.color || 'normal'].background,
        },
        '&:focus': {
            backgroundColor: props => buttonColors[props.color || 'normal'].background,
        },
    },
}))(({color, radius, ...props}) => React.createElement(MuiButton, props));

export default Button;