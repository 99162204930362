import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import RevisionIcon from '@material-ui/icons/Schedule';
import PendienteIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import VerificadoIcon from '@material-ui/icons/Done';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        gap: '10px',
        borderBottom: '0.5px solid #e3e3e3',
        paddingBottom: theme.spacing(2),
        '& div': {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            fontSize: 10,
            padding: theme.spacing(0, 1),
            color: '#213061',
            height: 20,
            borderRadius: 10,
            '& svg': {
                fontSize: 12,
                marginRight: theme.spacing(0.5),
            },
        },
    },
}));


export default function LeyendaHistorial() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div>
                <RevisionIcon /> Fichaje en revisión por administración
            </div>
            <div>
                <PendienteIcon /> Pendiente de verificación
            </div>
            <div>
                <VerificadoIcon style={{color: '#2CBD96'}} /> Verificado
            </div>
        </div>
    )
}