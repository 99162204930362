import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function EntradaIcon(props) {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            {...props}
        >
            <path d="M12.0266 5.35542L18.0067 5.35542C18.3734 5.35542 18.6711 5.65311 18.6711 6.01986L18.6711 17.98C18.6711 18.3468 18.3734 18.6445 18.0067 18.6445L12.0266 18.6445C11.6591 18.6445 11.3621 18.9415 11.3621 19.3089C11.3621 19.6763 11.6591 19.9734 12.0266 19.9734L18.0067 19.9734C19.1057 19.9734 20 19.079 20 17.98L20 6.01989C20 4.92089 19.1057 4.02654 18.0067 4.02654L12.0266 4.02654C11.6591 4.02654 11.3621 4.32354 11.3621 4.69098C11.3621 5.05842 11.6591 5.35542 12.0266 5.35542Z" />
            <path d="M14.4864 11.5266L10.4465 7.53992C10.186 7.28211 9.76478 7.28546 9.50697 7.54658C9.24916 7.80771 9.25181 8.2283 9.51362 8.48611L12.4007 11.3353H4.66444C4.297 11.3353 4 11.6323 4 11.9997C4 12.3671 4.297 12.6642 4.66444 12.6642H12.4007L9.51362 15.5133C9.25184 15.7711 9.24984 16.1917 9.50697 16.4529C9.63719 16.5844 9.80862 16.6509 9.98006 16.6509C10.1488 16.6509 10.3176 16.5871 10.4465 16.4595L14.4864 12.4728C14.6126 12.3479 14.6844 12.1777 14.6844 11.9997C14.6844 11.8217 14.6133 11.6522 14.4864 11.5266Z" />
        </SvgIcon>
    );
}