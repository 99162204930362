import React, {useMemo, useState} from "react";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import {deepEqual, formatDate, formatISODate} from "../../../utils";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {FieldArray, useField} from "formik";
import {tareasProvider} from "../../../api";
import {PageBodySection} from "../../common/PageBody";
import useSplash from "../../../contexts/SplashState";
import {SelectMaterialView} from "./SelectMaterialView";
import {MaterialJornada} from "./MaterialJornada";
import {useSnackbar} from "material-ui-snackbar-provider/lib";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        '&:last-of-type': {
            marginBottom: theme.spacing(2),
        },
    },
    title: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#818CAE',
        '& span': {
            fontWeight: 'normal',
        },
    },
    addButton: {
        fontSize: 11,
        backgroundColor: '#E9ECEE',
        textTransform: 'none',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    sinMaterial: {
        fontSize: 13,
        color: '#818CAE',
        border: '1px dashed #818CAE',
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        borderRadius: 8,
    },
}), {name: 'MaterialesJornada'});


export function MaterialesJornada({index, jornada, onSaveMateriales}) {
    const styles = useStyles();
    const snackbar = useSnackbar();
    const [saving, setSaving] = useState(false);

    const todayStr = formatISODate(new Date());
    const isToday = jornada.fecha === todayStr;

    const collectionName = `jornadas[${index}].materiales`;
    const materialesElementId = `materiales${index}`;

    const [, {value, initialValue}, {setValue}] = useField(collectionName);

    const changed = useMemo(() => !deepEqual(value, initialValue), [value, initialValue]);

    const saveMaterial = (jornadaId, material) => {
        let action = `jornadas/${jornadaId}/materiales`;

        if (material.id)
            action += `/${material.id}`;

        return tareasProvider
            .getAll(action, {
                method: 'post',
                body: JSON.stringify(material),
            });
    };

    return (
        <PageBodySection className={styles.root}>
            <Typography className={styles.title}>{isToday ? 'Hoy' : formatDate(new Date(jornada.fecha))} {jornada.operario && <span>({jornada.operario})</span>}</Typography>
            <FieldArray
                name={collectionName}
                render={({push}) => {
                    function handleAddExtraMateriales(selectedMateriales) {
                        selectedMateriales.forEach(({id, descripcion}) => {
                            push({
                                descripcion,
                                unidades: '',
                                material_interno_id: id,
                            });
                        });
                    }

                    return (
                        <>
                            <div id={materialesElementId}>
                                {jornada.materiales.length > 0 ?
                                    jornada.materiales.map((material, i) => (
                                        <MaterialJornada
                                            key={i}
                                            name={`${collectionName}[${i}]`}
                                            jornadaId={jornada.id}
                                            disabled={jornada.facturada}
                                            material={material}
                                            onDelete={() => onSaveMateriales([{idx: i, material: null}])}
                                            canDelete={!changed}
                                            onAddExtra={handleAddExtraMateriales}
                                        />
                                    ))
                                    :
                                    <Typography className={styles.sinMaterial}>
                                        No hay material para este dia
                                    </Typography>
                                }
                            </div>
                            {!jornada.facturada && jornada.propia &&
                            <>
                                <Button
                                    variant='contained'
                                    className={styles.addButton}
                                    disableElevation
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        push({descripcion: '', unidades: ''});
                                        setTimeout(() => {
                                            document.querySelector(`#${materialesElementId}>div:last-of-type input:first-of-type`).focus();
                                        }, 200)
                                    }}
                                >
                                    Añadir
                                </Button>

                                {changed &&
                                <>
                                    <Button
                                        variant='contained'
                                        className={styles.addButton}
                                        disableElevation
                                        startIcon={<DoneIcon />}
                                        onClick={() => {
                                            setSaving(true);
                                            const materialesCambiados = [];
                                            value.forEach((material, idx) => {
                                                const initialMaterial = initialValue[idx];
                                                if (deepEqual(material, initialMaterial))
                                                    return;

                                                if (initialMaterial &&
                                                    material.descripcion !== initialMaterial.descripcion &&
                                                    material.material_interno_id !== undefined &&
                                                    material.material_interno_id === initialMaterial.material_interno_id) {
                                                    delete material.material_interno_id;
                                                }

                                                materialesCambiados.push({idx, material});
                                            });

                                            const promises = materialesCambiados.map(({material}) => saveMaterial(jornada.id, material));

                                            Promise
                                                .all(promises)
                                                .then(results => {
                                                    results.forEach((savedMaterial, i) => materialesCambiados[i].material = savedMaterial);
                                                    onSaveMateriales(materialesCambiados);
                                                })
                                                .catch(err =>
                                                    snackbar.showMessage('Ha ocurrido un error al guardar el material. Comprueba tu conexión a internet y vuelve a intentarlo.', null, null, {severity: 'error'}))
                                                .finally(() => setSaving(false));
                                        }}
                                        disabled={!changed || saving}
                                    >
                                        Guardar
                                    </Button>
                                    <Button
                                        variant='contained'
                                        className={styles.addButton}
                                        disableElevation
                                        startIcon={<CloseIcon />}
                                        onClick={() => setValue(initialValue)}
                                        disabled={!changed}
                                    >
                                        Cancelar
                                    </Button>
                                </>}
                            </>}
                        </>
                    )
                }}
            />
        </PageBodySection>
    )
}