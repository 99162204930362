import React, {useEffect, useState} from "react";
import SelectClienteView from "../common/SelectClienteView";
import Calendario from "../common/Calendario";
import GeneralView from "./GeneralView";
import SelectOperarioView from "../common/SelectOperarioView";
import Navigation from "../../common/Navigation";
import SelectDireccionView from "../common/SelectDireccionView";
import {operariosProvider} from "../../../api";
import useAuthState from "../../../contexts/AuthState";

export const GENERAL = 0;
export const CLIENTE = 1;
export const FECHA = 2;
export const OPERARIO = 3;
export const DIRECCION = 4;


const viewComponents = {
    [GENERAL]: GeneralView,
    [FECHA]: Calendario,
    [CLIENTE]: SelectClienteView,
    [OPERARIO]: SelectOperarioView,
    [DIRECCION]: SelectDireccionView,
};

export default () => {
    const {isCompany} = useAuthState();
    const [tarea, setTarea] = useState({
        descripcion: '',
        cliente: null,
        direccion: null,
        operarios: [],
        fecha: null,
    });
    const [selfOperario, setSelfOperario] = useState(null);
    const [currentFieldComponent, setCurrentFieldComponent] = useState({
        id: 0,
        props: {},
    });

    useEffect(() => {
        operariosProvider
            .getAll('as_options')
            .then(operarios => {
                const self = operarios.find(op => op.is_self);
                setTarea(tarea => ({
                    ...tarea,
                    operarios: self ? [self] : [],
                }));
                setSelfOperario(self);
            });
    }, []);

    const setFieldValue = field => ev => {
        const value = ev.target ? ev.target.value : ev;

        if (field === 'operarios' && !isCompany && selfOperario) {
            if (value.findIndex(op => op.id === selfOperario.id) < 0) {
                value.unshift(selfOperario);
            }
        }

        setTarea(tarea => ({
            ...tarea,
            [field]: value,
        }));
    };

    const Component = viewComponents[currentFieldComponent.id];

    return (
        <>
            <Component
                tarea={tarea}
                setFieldValue={setFieldValue}
                setCurrentFieldComponent={(componentId, componentProps) => setCurrentFieldComponent({id: componentId, props: componentProps || {}})}
                {...currentFieldComponent.props}
            />
            {currentFieldComponent.id === GENERAL && <Navigation/>}
        </>
    );
};
