import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {RoundedButton} from "../../common/RoundedButton";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 260,
        margin: '0 auto',
    },
    icon: {
        color: 'white',
        width: 64,
        height: 64,
    },
    children: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiTypography-root': {
            fontWeight: 'normal',
            textAlign: 'center',
        },
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: `${theme.spacing(1)}px`,
        marginBottom: theme.spacing(4),
    }
}), {name: 'OpcionConfirmar'});


export default function OpcionConfirmar({icon, children, onAccept, onCancel, acceptText, cancelText = 'Cancelar'}) {
    const classes = useStyles();
    const Icon = icon;

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Icon className={classes.icon} />
                <div className={classes.children}>
                    {children}
                </div>
            </div>
            <div className={classes.buttons}>
                <RoundedButton onClick={onAccept}>{acceptText}</RoundedButton>
                {onCancel && <RoundedButton variant='outlined' onClick={onCancel}>{cancelText}</RoundedButton>}
            </div>
        </div>
    )
}