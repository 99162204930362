import React, {useEffect, useState} from "react";
import {GENERAL} from "../nueva/NuevaTarea";
import ListView from "../../common/ListView";
import {operariosProvider} from "../../../api";


export default ({tarea, setFieldValue, setCurrentFieldComponent}) => {
    const [operarios, setOperarios] = useState(null);

    useEffect(() => {
        operariosProvider.getAll('as_options').then(setOperarios);
    }, []);


    const columns = React.useMemo(() => [
        {
            Header: 'Nombre',
            accessor: 'nombre',
        }
    ], []);

    return operarios && (
        <ListView
            title='Seleccionar operarios'
            onClose={() => setCurrentFieldComponent(GENERAL)}
            setFieldValue={setFieldValue('operarios')}
            currentValue={tarea.operarios}
            items={operarios}
            columns={columns}
            searchPlaceholder='Buscar operario'
            multiple
            canAdd
        />
    )
}



