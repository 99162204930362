import React, {useEffect, useMemo, useState} from "react";
import {PageHeader} from "../common/PageHeader";
import {IconButton} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {PageBody} from "../common/PageBody";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import TabbedFilter from "../TabbedFilter";
import {convertDates, solicitudEstadoLabels} from "../../api/solicitudes";
import {solicitudesProvider} from "../../api";
import SolicitudListItem from "./SolicitudListItem";
import {formatISODate} from "../../utils";
import PreviousIcon from "@material-ui/icons/KeyboardArrowLeft";
import Typography from "@material-ui/core/Typography";
import NextIcon from "@material-ui/icons/KeyboardArrowRight";

const useStyles = makeStyles(theme => ({
    subtitle: {
        fontSize: 18,
        color: 'white',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            color: 'white',
        },
    },
}), {name: 'SolicitudListView'});

const filters = [{
    label: 'Todas',
    value: null,
}, ...Object.entries(solicitudEstadoLabels).map(([value, label]) => ({label, value}))];

export default function SolicitudListView({tipo, estado = null}) {
    const history = useHistory();
    const classes = useStyles();
    const [solicitudes, setSolicitudes] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());

    const goPreviousYear = () => setYear(year => year - 1);
    const goNextYear = () => setYear(year => year + 1);

    useEffect(() => {
        solicitudesProvider
            .getByYearMonth(year)
            .then(convertDates)
            .then(setSolicitudes)
    }, [year]);

    const filteredSolicitudes = useMemo(() => solicitudes.filter(solicitud => solicitud.tipo === tipo.toUpperCase() && (estado === null || solicitud.estado === estado.toUpperCase())), [solicitudes, tipo, estado]);

    return (
        <React.Fragment>
            <PageHeader
                title={tipo === 'vacaciones' ? 'Solicitudes de vacaciones' : 'Ausencias realizadas'}
                startButton={
                    <IconButton
                        onClick={() => history.push('/solicitudes')}
                    >
                        <ArrowBackIcon style={{color: 'white'}}/>
                    </IconButton>
                }
            >
                <div className={classes.subtitle}>
                    <IconButton
                        onClick={goPreviousYear}
                    >
                        <PreviousIcon />
                    </IconButton>
                    <Typography className={classes.fecha}>{year}</Typography>
                    <IconButton
                        onClick={goNextYear}
                    >
                        <NextIcon />
                    </IconButton>
                </div>
                {tipo === 'vacaciones' &&
                <TabbedFilter
                    style={{marginTop: 24}}
                    onFilterClicked={estado => {
                        let url = `/solicitudes/lista/${tipo}`;
                        if (estado !== null)
                            url = `${url}/${estado.toLowerCase()}`;

                        history.replace(url)
                    }}
                    filters={filters}
                    selectedFilter={estado ? estado.toUpperCase() : null}
                />}
            </PageHeader>
            <PageBody>
                {filteredSolicitudes.map((solicitud, i) => (
                    <SolicitudListItem
                        key={i}
                        solicitud={solicitud}
                        style={{
                            marginTop: 16,
                        }}
                        onClick={() => {
                            const fecha = new Date(solicitud.fecha_inicio);
                            history.push(`/solicitudes/calendario/${fecha.getFullYear()}/${fecha.getMonth()+1}/${formatISODate(fecha)}`);
                        }}
                    />
                ))}
            </PageBody>
        </React.Fragment>
    )
}