import React, {useEffect, useMemo, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiCheckbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RevisionIcon from '@material-ui/icons/Schedule';
import VerificadoIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import withStyles from "@material-ui/core/styles/withStyles";
import {format, formatFullDate, formatTiempo} from "../../utils";
import * as classnames from "classnames";
import EntradaIcon from "../icons/Entrada";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import SalidaIcon from "../icons/Salida";
import List from "@material-ui/core/List";
import Button from "../common/Button";
import {fichajesProvider} from "../../api";
import {ButtonDialog} from "../common/ButtonDialog";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {estadosFichaje} from "../../api/fichajes";


const FICHAJES_ICON_SIZE = 20;
const FICHAJES_ICON_MARGIN = 8;

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    verificarMultiples: {
        paddingBottom: 110,
    },
    heading: {
        fontSize: 12,
    },
    fichajesIcon: {
        fontSize: FICHAJES_ICON_SIZE,
        marginRight: FICHAJES_ICON_MARGIN,
        color: 'rgba(0, 0, 0, 0.54)',
        '&.verificado': {
            color: '#2CBD96',
        },
    },
    lista: {
        borderRadius: 8,
        width: '100%',
        fontSize: 12,
        backgroundColor: theme.palette.background.paper,
        '&.MuiList-padding': {
            padding: 0,
        },
        '& .MuiListItem-root': {
            padding: theme.spacing(0.5, 5),
        },
        '& .MuiListItemText-primary': {
            fontSize: 12,
        },
        '& .entrada, & .entrada svg': {
            color: '#2CBD96',
            fontSize: 14,
        },
        '& .salida, & .salida svg': {
            color: '#E94F4F',
            fontSize: 14,
        },
        '& .MuiListItemSecondaryAction-root': {
            color: '#213061',
            left: 150,
        },
        '& .MuiListItemIcon-root': {
            minWidth: theme.spacing(2.5),
        },
        '& li:last-of-type .MuiListItem-divider, & li:last-of-type.MuiListItem-divider': {
            borderBottom: 0,
        },
    },
    validarSeleccionadosContainer: {
        backgroundColor: '#E1E7F4',
        padding: theme.spacing(3),
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        // marginTop: 'auto',
        // marginLeft: theme.spacing(-3),
        // marginRight: theme.spacing(-3),
    },
    selectAll: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1),
        '& .MuiFormControlLabel-label': {
            fontSize: 12,
            color: '#8F95AF',
        },
    },
}));


const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:first-of-type)': {
            borderTop: '0.5px solid #e3e3e3',
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded, &$expanded + $root': {
            borderTop: 0,
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        '&.Mui-expanded': {
            minHeight: 0,
        },
    },
    content: {
        alignItems: 'center',
        '&.Mui-expanded': {
            margin: 0,
        },
    },
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
    root: {
        padding: 0,
    },
})(MuiAccordionDetails);


const Checkbox = withStyles(theme => ({
    root: {
        padding: 0,
        marginRight: 0,
        '& .MuiSvgIcon-root': {
            fontSize: FICHAJES_ICON_SIZE,
            marginRight: FICHAJES_ICON_MARGIN,
        }
    },
}))(MuiCheckbox);



export default function HistorialFichajes({fecha}) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [fichajes, setFichajes] = useState([]);
    const [selectedFichajes, setSelectedFichajes] = useState([]);

    useEffect(() => {
        fichajesProvider.getFichajes(fecha || new Date()).then(setFichajes);
    }, [fecha]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function renderIcon(fichaje) {
        if (fichaje.estado === 'PENDIENTE')
            return <RevisionIcon className={classes.fichajesIcon} />

        if (fichaje.estado === 'REVISADO')
            return (
                <Checkbox
                    className={classes.fichajesIcon}
                    aria-label={fichaje.id}
                    onClick={(event) => {
                        event.stopPropagation();
                        if (!event.target.checked)
                            setSelectedFichajes(current => current.filter(id => id !== fichaje.id))
                        else
                            setSelectedFichajes(current => [...current, fichaje.id])
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    checked={selectedFichajes.includes(fichaje.id)}
                />
            );

        if (fichaje.estado === 'VERIFICADO')
            return <VerificadoIcon className={classnames(classes.fichajesIcon, 'verificado')} />
    }

    const verificarFichajes = ids =>
        fichajesProvider
            .verificarFichajes(ids)
            .then(updatedFichajes => {
                setFichajes(
                    fichajes => fichajes.map(
                        oldFichaje => updatedFichajes.find(f => f.id === oldFichaje.id) || oldFichaje
                    )
                );
                setSelectedFichajes([]);
            });

    const fichajesIdsVerificables = useMemo(() => fichajes.filter(fichaje => fichaje.estado === estadosFichaje.REVISADO).map(fichaje => fichaje.id), [fichajes]);
    const selectAllFichajes = checked => setSelectedFichajes(checked ? fichajesIdsVerificables : []);

    return (
        <div className={classnames(classes.root, {[classes.verificarMultiples]: selectedFichajes.length > 0})}>
            <FormControlLabel
                className={classes.selectAll}
                control={
                    <Checkbox
                        checked={fichajesIdsVerificables.length === selectedFichajes.length && fichajesIdsVerificables.length > 0}
                        onChange={ev => selectAllFichajes(ev.target.checked)}
                    />
                }
                label="Seleccionar todos los pendientes"
            />
            {fichajes.map(fichaje => (
                <Accordion
                    key={fichaje.id}
                    expanded={expanded === fichaje.id}
                    onChange={handleChange(fichaje.id)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${fichaje.id}-content`}
                        id={`panel${fichaje.id}-header`}
                    >
                        {renderIcon(fichaje)}
                        <Typography className={classes.heading}>
                            {formatFullDate(fichaje.fecha)} | {formatTiempo(fichaje.total_horas)} trabajados
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List className={classes.lista}>
                            {fichaje.marcajes.map((marcaje, i) => (
                                <React.Fragment key={i}>
                                    {marcaje.hora_entrada &&
                                    <>
                                        <ListItem className='entrada' divider>
                                            <ListItemIcon>
                                                <EntradaIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Entrada" />
                                            <ListItemSecondaryAction>
                                                {format(marcaje.hora_entrada, 'HH:mm')}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </>}
                                    {marcaje.hora_salida &&
                                    <>
                                        <ListItem className='salida' divider>
                                            <ListItemIcon>
                                                <SalidaIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Salida" />
                                            <ListItemSecondaryAction>
                                                {format(marcaje.hora_salida, 'HH:mm')}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </>}
                                </React.Fragment>
                            ))}
                            {fichaje.estado === 'PENDIENTE' &&
                            <ListItem style={{padding: 8}} divider>
                                <Button
                                    fullWidth
                                    size='small'
                                    color='dark'
                                    radius={4}
                                >
                                    Pendiente de revisión
                                </Button>
                            </ListItem>}
                            {fichaje.estado === 'REVISADO' &&
                            <ListItem style={{padding: 8}} divider>
                                <ButtonDialog
                                    button={
                                        <Button
                                            fullWidth
                                            size='small'
                                            color='info'
                                            radius={4}

                                        >
                                            Verificar jornada
                                        </Button>
                                    }
                                    title='¿Estás seguro que los marcajes son correctos?'
                                    okText='Verificar jornada'
                                    onAccept={() => verificarFichajes([fichaje.id])}
                                />
                            </ListItem>}
                        </List>
                    </AccordionDetails>
                </Accordion>
            ))}
            <Slide direction="up" in={selectedFichajes.length > 0} mountOnEnter unmountOnExit>
                <div className={classes.validarSeleccionadosContainer}>
                    <ButtonDialog
                        button={
                            <Button
                                fullWidth
                                color='info'
                            >
                                Verificar jornadas seleccionadas
                            </Button>
                        }
                        title='¿Estás seguro que los marcajes son correctos?'
                        okText='Verificar jornadas'
                        onAccept={() => verificarFichajes(selectedFichajes)}
                    />
                </div>
            </Slide>
        </div>
    );
}