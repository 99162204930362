import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {formatDate, formatDateTime} from "../../utils";
import {solicitudEstadoLabels, solicitudTipoLabels} from "../../api/solicitudes";
import {Typography} from "@material-ui/core";
import TodayIcon from '@material-ui/icons/Today';
import Paper from "@material-ui/core/Paper";
import Button from "../common/Button";
import * as classnames from "classnames";
import {useHistory} from "react-router-dom";


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1.5),
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        '& div': {
            height: 20,
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
            fontSize: 11,
            padding: theme.spacing(0, 1),
        },
    },
    fecha: {
        backgroundColor: '#F3F5FA',
        color: '#818CAE',
        '& svg': {
            fontSize: 14,
            marginRight: theme.spacing(0.5),
        },
    },
    aprobada: {
        backgroundColor: '#2CBD96',
        color: 'white',
    },
    rechazada: {
        backgroundColor: '#E9504F',
        color: 'white',
    },
    pendiente: {
        backgroundColor: '#818CAE',
        color: 'white',
    },
    motivo: {
        fontSize: 16,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    dias: {
        color: '#8F95AF',
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            fontSize: 16,
            marginRight: theme.spacing(0.5),
        },
    },
    observacionesPaper: {
        marginTop: theme.spacing(1.5),
        padding: theme.spacing(1.5),
    },
    observaciones: {
        color: '#8F95AF',
        fontSize: 14,
    },
    autor: {
        color: '#2196F3',
        fontSize: 12,
        marginTop: theme.spacing(1),
        textAlign: 'right',
        fontWeight: 500,
    },
    resolicitar: {
        marginTop: theme.spacing(1.5),
    }
}), {name: 'SolicitudListItem'});

export default function SolicitudListItem({solicitud, expanded, className, style, onClick}) {
    const classes = useStyles();
    const history = useHistory();

    const allDay =
        solicitud.fecha_inicio.getHours() === 0 && solicitud.fecha_inicio.getMinutes() === 0 &&
        solicitud.fecha_fin.getHours() === 23 && solicitud.fecha_fin.getMinutes() === 59;

    const formatFn = allDay ? formatDate : formatDateTime;

    return (
        <>
            <Paper className={classnames(classes.root, className)} style={style} elevation={0} onClick={onClick}>
                <div className={classes.header}>
                    <div className={classes.fecha}><TodayIcon /> Fecha solicitud: {formatDateTime(solicitud.fecha)}</div>
                    {solicitud.tipo !== 'AUSENCIA' && <div className={classes[solicitud.estado.toLowerCase()]}>{solicitudEstadoLabels[solicitud.estado]}</div>}
                </div>
                <Typography className={classes.motivo}><strong>{solicitudTipoLabels[solicitud.tipo]}</strong>: {solicitud.motivo}</Typography>
                <div className={classes.dias}>
                    <TodayIcon />{formatFn(solicitud.fecha_inicio)} a {formatFn(solicitud.fecha_fin)}
                </div>
            </Paper>
            {expanded &&
            <>
                {solicitud.observaciones &&
                <Paper className={classes.observacionesPaper} elevation={0}>
                    <Typography className={classes.observaciones}>{solicitud.observaciones}</Typography>
                    <Typography className={classes.autor}>Por administración</Typography>
                </Paper>}
                {solicitud.estado === 'RECHAZADA' &&
                <Button
                    className={classes.resolicitar}
                    color='info'
                    onClick={() => history.push('/solicitudes/nueva', {solicitud})}
                >
                    Volver a solicitar
                </Button>}
            </>}
        </>
    )
}