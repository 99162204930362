import React, {useMemo} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import CloseIcon from '@material-ui/icons/Close';
import {TextField} from "formik-material-ui";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import AuthService from "./AuthService";
import API_ROOT from "../../api/api-config";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {Link, useHistory, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Requerido'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Las contraseñas tienen que ser iguales')
});


const useStyles = makeStyles(theme => ({
    main: {
        width: 'auto',
        display: 'flex', // Fix IE 11 issue.
        flexDirection: 'column',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
    },
    header: {
        display: 'flex',
        fontSize: 24,
        fontWeight: 'bold',
        color: '#213061',
        alignItems: 'center',
    },
    logo: {
        margin: theme.spacing(2, 1.5, 2, 2),
        // height: theme.spacing(3),
    },
    closeButton: {
        color: '#E2E2E2',
        marginLeft: 'auto',
    },
    paper: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        borderRadius: 8,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        marginTop: theme.spacing(3),
        height: 48,
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: 24,
        color: '#363636',
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
    },
    subtitle: {
        fontSize: 16,
        color: '#9B9B9B',
        marginBottom: theme.spacing(3),
    },
    recoverPassword: {
        '& span': {
            margin: theme.spacing(0, 1),
            fontWeight: 'bold',
        },
    },
}));

export default function ChangePassword() {
    const classes = useStyles();
    const history = useHistory();
    const authService = useMemo(() => new AuthService(API_ROOT), []);
    const {token} = useParams();

    console.log(token);

    return (
        <>
            <CssBaseline/>
            <Typography className={classes.header}>
                <img src="/logo-text-panel.png" alt="Labory logo" className={classes.logo} />
                <IconButton
                    component={Link}
                    to='/'
                    className={classes.closeButton}
                >
                    <CloseIcon />
                </IconButton>
            </Typography>
            <main className={classes.main}>
                <Typography className={classes.title}>Restablecer contraseña</Typography>
                <Typography className={classes.subtitle}>Escribe la nueva contraseña.</Typography>
                <div className={classes.paper}>
                    <Formik
                        initialValues={{
                            password: '',
                            confirmPassword: '',
                        }}
                        validationSchema={ResetPasswordSchema}
                        onSubmit={({password}, actions) => {
                            authService
                                .changePassword(token, password)
                                .then(res => {
                                    actions.setSubmitting(false);
                                    history.push('/');
                                })
                                .catch(err => {
                                    console.log(err);
                                    console.log(err.response);
                                    actions.setSubmitting(false);
                                });
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form className={classes.form}>
                                <TextField
                                    name="password"
                                    label="Contraseña"
                                    type="password"
                                    className={classes.textField}
                                    disabled={isSubmitting}
                                />
                                <TextField
                                    name="confirmPassword"
                                    label="Confirmar contraseña"
                                    type="password"
                                    className={classes.textField}
                                    disabled={isSubmitting}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={isSubmitting}
                                >
                                    Restablecer contraseña
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </main>
        </>
    );
}
