import {useField} from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Switch} from "../Switch";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

export function SwitchWithLabelFormik({name, onChange, ...props}) {
    const [field, ,] = useField(name);

    return (
        <FormControlLabel
            control={
                <Switch
                    name={name}
                    checked={field.value}
                    onChange={(ev) => {
                        field.onChange(ev);
                        onChange && onChange(ev)
                    }}
                    onBlur={field.onBlur}
                />
            }
            {...props}
        />
    )
}


const useRightSwitchStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: 8,
        marginRight: 8,
        marginLeft: 0,
    },
    label: {
        flex: 1,
        color: '#818CAE',
        fontSize: 14,
    },
}));

export const StartLabelSwitchFormik = ({classes = {}, ...props}) => {
    const localClasses = useRightSwitchStyles();

    return (
        <SwitchWithLabelFormik
            {...props}
            classes={{...localClasses, ...classes}}
            labelPlacement='start'
        />
    );
}


export function SwitchWithLabel({name, onChange, ...props}) {
    return (
        <FormControlLabel
            control={
                <Switch
                    name={name}
                    onChange={onChange}
                />
            }
            {...props}
        />
    )
}

export const StartLabelSwitch = (props) => {
    const classes = useRightSwitchStyles();

    return (
        <SwitchWithLabel
            {...props}
            classes={classes}
            labelPlacement='start'
        />
    );
}