import {useField} from "formik";
import MuiInput from "@material-ui/core/Input";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import {FormHelperText} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 4,
        background: 'white',
        fontSize: 13,
        '&.Mui-error': {
            border: '1px solid #f44336',
        },
    },
    input: {
        padding: 10,
    },
}), {name: 'Input'});

export function Input({name, style, ...props}) {
    const classes = useStyles();

    const [field, {error, touched}, ] = useField(name);

    return (
        <FormControl error={Boolean(touched && error)} style={style}>
            <MuiInput
                name={name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                classes={{
                    root: classes.root,
                    input: classes.input,
                }}
                disableUnderline
                {...props}
            />
            {error && touched && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}