import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import PreviousIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextIcon from '@material-ui/icons/KeyboardArrowRight';
import {IconButton} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {format} from "../../utils";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: theme.spacing(1, 0),
    },
    fecha: {
        fontSize: 18,
        color: '#213061',
    },
}));


export default function HistorialNavegacion({fecha, goPreviousMonth, goNextMonth}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <IconButton
                onClick={goPreviousMonth}
            >
                <PreviousIcon />
            </IconButton>
            <Typography className={classes.fecha}>{format(fecha, "MMMM yyyy")}</Typography>

            <IconButton
                onClick={goNextMonth}
            >
                <NextIcon />
            </IconButton>
        </div>
    )
}