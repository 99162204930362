import React from 'react';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import {TareaList} from "./components/tareas/TareaList";
import {Switch, Route} from "react-router-dom";
import TareaView from "./components/tareas/TareaView";
import EditTime from "./components/tareas/EditTime";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {JornadaList} from "./components/jornada/JornadaList";
import {Calendario} from "./components/jornada/Calendario";
import NuevaTarea from "./components/tareas/nueva/NuevaTarea";
import {AuthStateProvider} from "./contexts/AuthState";
import { hotjar } from 'react-hotjar';
import {IS_LOCALHOST} from "./api/api-config";
import EditTareaList from "./components/tareas/editar/EditTareaList";
import {FicharView} from "./components/fichajes/FicharView";
import {HistorialFichajesView} from "./components/fichajes/HistorialFichajesView";
import {ResumenView} from "./components/solicitudes/ResumenView";
import {NuevaSolicitudView} from "./components/solicitudes/NuevaSolicitudView";
import {SplashProvider} from "./contexts/SplashState";
import {CalendarioView} from "./components/solicitudes/CalendarioView";
import SolicitudListView from "./components/solicitudes/SolicitudListView";
import {SnackbarProvider} from "material-ui-snackbar-provider";
import {Snackbar} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Alert} from "@material-ui/lab";
import { Offline, Online } from "react-detect-offline";

const theme = createTheme({
    palette: {
        primary: { main: '#668AF6' },
        secondary: { main: '#8D8D8D' },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    backgroundColor: '#F3F5FA',
                },
            },
        },
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
        h1: {
            fontSize: 24,
            color: 'white',
            fontWeight: 'normal',
            textAlign: 'center',
            padding: '10px 0',
        },
        h2: {
            fontSize: 20,
            color: 'white',
            fontWeight: 600,
            marginTop: 12,
            marginBottom: 8,
        },
        h3: {
            fontSize: 17,
            color: 'white',
            fontWeight: 'normal',
        },
        h4: {
            fontSize: 18,
            color: '#45486E',
            marginTop: 8,
            marginBottom: 16,
        },
        body2: {
            fontFamily: 'inherit',
            fontWeight: 'bold',
        }
    },
});

function DefaultSnackbar ({
  message, action, ButtonProps, SnackbarProps, customParameters
}) {
    return (
        <Snackbar
            {...SnackbarProps}
        >
            <Alert elevation={6} variant="filled" severity={customParameters?.severity ?? 'info'}>{message || ''}</Alert>
        </Snackbar>
    )
}

function App() {
    const hotjarSettings = {hjid: 2002703, hjsv: 6};
    if (!IS_LOCALHOST)
        hotjar.initialize(hotjarSettings.hjid, hotjarSettings.hjsv);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider SnackbarProps={{ autoHideDuration: null }} SnackbarComponent={DefaultSnackbar}>
                    <AuthStateProvider>
                        <SplashProvider>
                            <Offline><div style={{position: 'absolute', top: 0, left: 0, right:0, background: '#F24822', textAlign: 'center', color: 'white', padding: 4, fontSize: 12, zIndex: 99999, fontWeight: 500}}>Estás trabajando sin conexión. Los cambios no se guardarán.</div></Offline>
                            <Switch>
                                <Route
                                    path='/tarea/nueva'
                                >
                                    <NuevaTarea/>
                                </Route>
                                <Route
                                    path='/tarea/:id/tiempo/:fecha?'
                                    render={({match: {params}}) => (
                                        <EditTime
                                            id={params.id}
                                            fecha={params.fecha}
                                        />
                                    )}
                                />
                                <Route
                                    path={['/tarea/:id/jornadas/:selectedJornadaDate', '/tarea/:id']}
                                    render={({match: {params}, location}) => (
                                        <TareaView id={params.id} selectedJornadaDate={params.selectedJornadaDate} />
                                    )}
                                />
                                <Route
                                    path='/jornada/:fecha?'
                                    render={({match: {params}}) => (
                                        <JornadaList
                                            fecha={params.fecha}
                                        />
                                    )}/>
                                <Route path='/calendario'>
                                    <Calendario/>
                                </Route>
                                <Route path='/fichajes/historico'>
                                    <HistorialFichajesView/>
                                </Route>
                                <Route path='/fichajes'>
                                    <FicharView/>
                                </Route>
                                <Route
                                    path='/solicitudes/nueva'
                                    render={({match: {params}, location}) => (
                                        <NuevaSolicitudView solicitud={location.state?.solicitud}/>
                                    )}
                                />
                                <Route
                                    path='/solicitudes/calendario/:currentYear?/:currentMonth?/:selectedDate?'
                                    render={({match: {params}, location}) => {
                                        const currentMonth = new Date();
                                        if (params.currentYear)
                                            currentMonth.setFullYear(params.currentYear);
                                        if (params.currentMonth)
                                            currentMonth.setMonth(params.currentMonth - 1);
                                        currentMonth.setDate(1);
                                        return (
                                            <CalendarioView
                                                currentMonth={currentMonth}
                                                selectedDate={params.selectedDate}
                                            />
                                        )
                                    }}
                                />
                                <Route
                                    path='/solicitudes/lista/:tipo/:estado?'
                                    render={({match: {params}, location}) => (
                                        <SolicitudListView
                                            tipo={params.tipo}
                                            estado={params.estado}
                                        />
                                    )}
                                />
                                <Route path='/solicitudes'>
                                    <ResumenView/>
                                </Route>
                                <Route path='/tareas-admin'>
                                    <EditTareaList/>
                                </Route>
                                <Route path='/'>
                                    <TareaList/>
                                </Route>
                            </Switch>
                        </SplashProvider>
                    </AuthStateProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
}

export default App;
