import Typography from "@material-ui/core/Typography";
import React from "react";

export const PageHeader = ({title, startButton, endButton, children, fill=false}) => (
    <div
        style={{
            background: 'linear-gradient(180deg, #4C60FE 0%, #4DA1FF 100%), #4C60FE',
            display: 'flex',
            flexDirection: 'column',
            padding: 24,
            flex: fill ? 1 : null,
        }}
    >
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    marginLeft: -16,
                    width: 48,
                    height: 48,
                    display: 'flex',
                }}
            >
                {startButton && React.cloneElement(startButton)}
            </div>
            <Typography variant='h1' style={{flex: 1, fontSize: 20}}>{title}</Typography>
            <div
                style={{
                    marginRight: -16,
                    width: 48,
                    height: 48,
                    display: 'flex',
                }}
            >
                {endButton && React.cloneElement(endButton)}
            </div>
        </div>
        {children}
    </div>
);