import {useHistory} from "react-router-dom";
import {TimeSlotItem} from "./TimeSlotItem";
import isToday from "date-fns/isToday";
import {formatDate, formatISODate} from "../../utils";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import React, {useEffect, useMemo, useState} from "react";
import {createMuiTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";
import {calendariosProvider, tareasProvider} from "../../api";
import {addTiempo, createTiempo, getMinutos, getTiempoJornadas, isTiempoZero} from "../../api/tareas-functions";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import {DatePicker} from "@material-ui/pickers";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {PageBodySection} from "../common/PageBody";

const useStyles = makeStyles(theme => ({
    subtitle: {
        fontSize: 14,
        color: 'white',
        margin: theme.spacing(2, 3),
        textAlign: 'center',
    },
    jornadaVacia: {
        color: '#8F95AF',
    },
    jornadaTrabajada: {
        '& button': {
            backgroundColor: '#DADEE9',
            color: '#8F95AF',
        },
        '& button:hover': {
            backgroundColor: '#DADEE9',
            color: '#8F95AF',
        },
    },
    noLaborable: {
        '& button': {
            border: '1px solid #EA5759',
        },
    },
    selectedDate: {
        '& button': {
            border: '2px solid #45486E',
        },
    },
    jornadaBullet: {
        backgroundColor: '#DADEE9',
        width: 12,
        height: 12,
        borderRadius: 4,
        marginRight: theme.spacing(1),
    },
    noLaborableBullet: {
        backgroundColor: 'transparent',
        border: '1px solid #EA5759',
        boxSizing: 'border-box',
    },
    infoPaper: {
        backgroundColor: 'white',
        borderRadius: 8,
        padding: theme.spacing(2),
        '&>div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            color: '#213061',
        },
        '&>div+div': {
            marginTop: theme.spacing(1.5),
        },
        marginBottom: theme.spacing(3),
    },
}));

const materialTheme = createMuiTheme({
    overrides: {
        MuiTypography: {
            body2: {
                fontFamily: 'inherit',
                fontWeight: 'inherit',
            },
        },
        MuiButtonBase: {
            root: {
                fontFamily: 'Inter, sans-serif',
                fontSize: 13,
            }
        },
        MuiPickersBasePicker: {
            container: {
                alignItems: 'center',
            },
            pickerView: {
                minHeight: 340,
                justifyContent: 'flex-start',
                width: '100%',
            }
        },
        MuiPickersStaticWrapper: {
            staticWrapperRoot: {
                flex: 1,
                backgroundColor: 'transparent',
                overflow: 'initial',
            },
        },
        MuiPickersCalendar: {
            week: {
                justifyContent: 'space-between',
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                color: '#213061',
                backgroundColor: 'transparent',
            },
            transitionContainer: {
                textTransform: 'capitalize',
                color: '#213061',
            },
            dayLabel: {
                color: '#213061',
                textTransform: 'uppercase',
                fontWeight: 600,
            },
            daysHeader: {
                justifyContent: 'space-between',
            },
        },
        MuiPickersDay: {
            day: {
                color: 'inherit',
                fontWeight: 'normal',
                borderRadius: 8,
                margin: 2,
            },
            daySelected: {
                backgroundColor: 'transparent',
                color: 'inherit',
                fontWeight: 'normal',
            },
            current: {
                fontWeight: 700,
                backgroundColor: 'inherit',
                color: '#45486E',
                // border: '2px solid #45486E',
                '&>span:first-of-type': {
                    display: 'flex',
                    flexDirection: 'column',
                },
                '&>span:first-of-type::before': {
                    content: '"HOY"',
                    fontSize: 8,
                    marginTop: 3,
                    marginBottom: -3,
                },
            },
        },
    },
});

export const JornadasList = ({id, jornadas, editable, selectedJornadaDate, refresh}) => {
    const thisMonth = new Date(selectedJornadaDate);
    thisMonth.setDate(1);

    const [currentMonth, setCurrentMonth] = useState(thisMonth);
    const [noLaborables, setNoLaborables] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const styles = useStyles();
    const history = useHistory();
    const currentYear = currentMonth.getFullYear();

    useEffect(() => {
        calendariosProvider
            .getAll(`no_laborables/${currentYear}`)
            .then(setNoLaborables);
    }, [currentYear]);

    const jornadasByFecha = useMemo(() => {
        const jornadasByFecha = {};
        jornadas.forEach(jornada => jornadasByFecha[jornada.fecha] = jornada);
        return jornadasByFecha;
    }, [jornadas]);

    const selectedJornada = useMemo(() => jornadasByFecha[selectedJornadaDate], [jornadasByFecha, selectedJornadaDate]);

    return (
        <PageBodySection>
            <ThemeProvider theme={materialTheme}>
                <DatePicker
                    disableToolbar={true}
                    autoOk
                    openTo='date'
                    variant="static"
                    value={currentMonth}
                    onChange={ev => {
                        history.replace(`/tarea/${id}/jornadas/${formatISODate(ev)}`);
                    }}
                    onMonthChange={setCurrentMonth}
                    renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
                        const isoDay = formatISODate(day);
                        const tiempoJornada = jornadasByFecha[isoDay];
                        const jornadaClass = tiempoJornada ? styles.jornadaTrabajada : styles.jornadaVacia;

                        return (
                            <span
                                className={classNames(
                                    jornadaClass,
                                    {
                                        [styles.selectedDate]: selectedJornadaDate === isoDay,
                                        [styles.noLaborable]: noLaborables.includes(isoDay)
                                    }
                                )}
                            >
                                {dayComponent}
                            </span>
                        );
                    }}
                />
            </ThemeProvider>

            {selectedJornada ?
            <TimeSlotItem
                label={
                    isToday(new Date(selectedJornada.fecha)) ?
                        <div
                            style={{
                                background: 'linear-gradient(180deg, #4D62FF 0%, #4D96FF 100%), #EDF8F5',
                                borderRadius: 20,
                                color: 'white',
                                width: 40,
                                height: 20,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: 11,
                                marginTop: -2,
                                marginBottom: -2,
                            }}
                        >HOY</div>
                        :
                        formatDate(new Date(selectedJornada.fecha))
                }
                tiempo={selectedJornada.tiempo}
                button={
                    editable &&
                    <>
                        <Button
                            disabled={selectedJornada.facturada}
                            onClick={() => history.push(`/tarea/${id}/tiempo/${selectedJornada.fecha}`, {editing: true})}
                            style={{
                                marginLeft: 8,
                                height: '100%',
                                minWidth: 44,
                                background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
                                opacity: selectedJornada.facturada ? 0.4 : 1,
                            }}
                        >
                            <EditIcon style={{color: 'white', fontSize: 20}}/>
                        </Button>
                        <Button
                            disabled={selectedJornada.facturada}
                            onClick={() => setDeleting(true)}
                            style={{
                                marginLeft: 8,
                                height: '100%',
                                minWidth: 44,
                                background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
                                opacity: selectedJornada.facturada ? 0.4 : 1,
                            }}
                        >
                            <DeleteIcon style={{color: 'white', fontSize: 20}}/>
                        </Button>
                    </>
                }
                style={{marginBottom: 12}}
            />
            :
                <div
                    style={{
                        display: 'flex',
                        marginBottom: 12,
                    }}
                >
                    <div
                        style={{
                            borderRadius: 4,
                            border: '1px dashed #BABABA',
                            minHeight: 38,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: 13,
                            color: '#818CAE',
                            flex: 1,
                        }}
                    >
                        No hay registro de tiempo
                    </div>
                    {editable &&
                    <Button
                        onClick={() => history.push(`/tarea/${id}/tiempo/${selectedJornadaDate}`)}
                        style={{
                            marginLeft: 8,
                            minWidth: 44,
                            height: 40,
                            background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
                        }}
                    >
                        <AddIcon style={{color: 'white', fontSize: 20}} />
                    </Button>}
                </div>}

            <div className={styles.infoPaper}>
                <div><span className={styles.jornadaBullet}/> Tienes horas registradas</div>
                <div><span className={classNames(styles.jornadaBullet, styles.noLaborableBullet)}/> No laborable</div>
            </div>

            <Dialog
                open={deleting}
            >
                <DialogTitle
                    disableTypography
                    style={{
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 600,
                    }}
                >
                    ¿Estás seguro que quieres borrar esta jornada?
                </DialogTitle>
                <DialogContent
                    style={{
                        color: '#818CAE',
                        fontSize: 13,
                        textAlign: 'center',
                    }}
                >
                    Si hay materiales asociados también se borrarán. Esta operación no se puede deshacer.
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between', padding: 20}}>
                    <Button onClick={() => setDeleting(false)}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            tareasProvider.actionOnId(id, `tiempo/${selectedJornadaDate}`, null, {method: 'delete'}).then(res => {
                                refresh();
                                setDeleting(false);
                            });
                        }}
                        variant='contained'
                        color="primary"
                        autoFocus
                    >
                        Borrar
                    </Button>
                </DialogActions>
            </Dialog>
        </PageBodySection>
    );
};