import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {GENERAL, PARTE_MANTENIMIENTO, PARTES_MANTENIMIENTO_LIST, useTareaState} from "../TareaView";
import {tareasProvider} from "../../../api";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from "@material-ui/core/Collapse";
import PreguntaChoice from "./PreguntaChoice";
import {Formik} from "formik";
import {BigButton} from "../../common/BigButton";
import PreguntaText from "./PreguntaText";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {processTarea} from "../../../api/tareas";
import {ButtonDialog} from "../../common/ButtonDialog";
import PreguntaNotasView from "./PreguntaNotasView";
import NotasIcon from "../../icons/Notas";
import IconButton from "@material-ui/core/IconButton";
import useSplash from "../../../contexts/SplashState";
import {FirmarView} from "../FirmarView";
import {PageBodySection} from "../../common/PageBody";
import useAuthState from "../../../contexts/AuthState";
import {formatDate} from "../../../utils";


const useStyles = makeStyles(theme => ({
    item: {
        borderBottom: '1px solid #D8DAE1',
        '&:first-of-type': {
            marginTop: -8,
        },
    },
    infoEquipoItem: {
        backgroundColor: '#E3E6ED',
        '& $title': {

        },
    },
    infoEquipoList: {
        position: 'relative',
        borderTop: '1px solid #D8DAE1',
        fontSize: 13,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2, 3),
        gap: `${theme.spacing(1)}px`,
        '& div strong': {
            marginRight: theme.spacing(0.5),
        },
    },
    notasButton: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(3),
    },
    cuestionarioTitle: {
        padding: theme.spacing(1, 3),
        fontSize: 14,
        backgroundColor: '#4D8AFF',
        color: 'white',
        display: 'flex',
        '& strong': {
            marginRight: theme.spacing(0.5),
        },
        '& span': {
            fontWeight: 600,
            marginLeft: 'auto',
        },
    },
    itemHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2, 3),
    },
    title: {
        flex: 1,
        fontSize: 13,
        fontWeight: 500,
        '& strong': {
            marginRight: theme.spacing(0.5),
        },
    },
    chip: {
        fontSize: 10,
        padding: theme.spacing(0.5, 1),
        marginRight: theme.spacing(2),
        borderRadius: 10,
        backgroundColor: '#FEE5E5',
        color: '#E94F4F',
        '&.firmado': {
            backgroundColor: '#CFF3E9',
            color: '#2CBD96',
        },
    },
    button: {
        margin: theme.spacing(2),
        marginTop: 'auto',
    },
    secondaryButton: {
        textTransform: 'none',
        color: '#4D6EFF',
    },
    info: {
        fontSize: 10,
        color: '#818CAE',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(1),
        '& svg': {
            fontSize: 13,
            marginRight: theme.spacing(0.5),
        },
    },
    signature: {
        border: '1px dashed #bababa',
        borderRadius: 4,
        maxHeight: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        '& img': {
            margin: theme.spacing(2),
            objectFit: 'cover',
            overflow: 'hidden',
        },
    },
    signatureName: {
        fontSize: 12,
        color: '#818cae',
        marginBottom: theme.spacing(1),
    },
}), {name: 'ParteMantenimientoView'});



export const ParteMantenimientoView = () => {
    const classes = useStyles();
    const {setTarea, setView, viewParams: { parteMantenimiento }} = useTareaState();
    const [cuestionarios, setCuestionarios] = useState([]);
    const [expandedSeccion, setExpandedSeccion] = useState(null);
    const [respuestas, setRespuestas] = useState({});
    const {showCustomComponent} = useSplash();
    const {userInfo: {name: userName}} = useAuthState();

    useEffect(() => {
        if (cuestionarios.length === parteMantenimiento.cuestionarios.length) {
            return;
        }

        tareasProvider.getCuestionariosParteMantenimiento(parteMantenimiento.id).then(cuestionarios => {
            const currentRespuestas = Object.fromEntries(
                parteMantenimiento.respuestas.map(respuesta =>
                    [
                        respuesta.pregunta_id,
                        { valor: respuesta.valor, notas: respuesta.notas },
                    ]
                )
            );

            const respuestas = cuestionarios
                .reduce((respuestas, cuestionario) => {
                    let preguntasIds = [];
                    cuestionario.secciones.reduce((respuestas, seccion) => {
                        preguntasIds.push(...seccion.preguntas.map(p => p.id));

                        respuestas.push(
                            ...seccion.preguntas.map(
                                pregunta => {
                                    const respuesta = currentRespuestas[pregunta.id] || {valor: null, notas: ''};
                                    if (respuesta.valor && seccion.tipo === 'TEXT')
                                        respuesta.valor = respuesta.valor.split(',');

                                    return [pregunta.id, respuesta]
                                }
                            )
                        );
                        return respuestas;
                    }, respuestas);

                    cuestionario.preguntasIds = preguntasIds;

                    return respuestas;
                }, []);

            setCuestionarios(cuestionarios);
            setRespuestas(Object.fromEntries(respuestas));
        });
    }, [cuestionarios, parteMantenimiento]);

    const isInfoExpanded = expandedSeccion === 'info';
    const isFirmaExistente = parteMantenimiento && parteMantenimiento.firma_operario && parteMantenimiento.firma_operario.image_url;

    const equipo = parteMantenimiento.equipo;

    return (
        <Formik
            initialValues={{
                respuestas,
            }}
            enableReinitialize
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(false);
            }}
        >
            {({isSubmitting, values}) => {
                const numPreguntas = Object.keys(values.respuestas).length;
                const numContestadas = Object.values(values.respuestas).map(r => r.valor).filter(Boolean).length;
                const puedeTerminar = numPreguntas === numContestadas;

                return (
                    <>
                        <div className={clsx(classes.item, classes.infoEquipoItem)}>
                            <div className={classes.itemHeader} onClick={() => setExpandedSeccion(isInfoExpanded ? null : 'info')}>
                                <Typography className={classes.title}><strong>EQUIPO:</strong>{parteMantenimiento.equipo.nombre}</Typography>
                                {isInfoExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon/>}
                            </div>
                            <Collapse in={isInfoExpanded}>
                                <div className={classes.infoEquipoList}>
                                    <IconButton
                                        size='small'
                                        color={parteMantenimiento.notas ? 'primary' : 'default'}
                                        className={classes.notasButton}
                                        onClick={() => {
                                            showCustomComponent(({closeSplash}) => (
                                                <PreguntaNotasView
                                                    onSave={notas => setView(PARTE_MANTENIMIENTO, {parteMantenimiento: {...parteMantenimiento, notas}})}
                                                    onClose={closeSplash}
                                                    notas={parteMantenimiento.notas}
                                                />
                                            ));
                                        }}
                                    >
                                        <NotasIcon size='small' />
                                    </IconButton>
                                    <div><strong>Referencia:</strong>{equipo.referencia}</div>
                                    {equipo.marca && <div><strong>Marca:</strong>{equipo.marca}</div>}
                                    {equipo.modelo && <div><strong>Modelo:</strong>{equipo.modelo}</div>}
                                    {equipo.numero_serie && <div><strong>Núm. serie:</strong>{equipo.numero_serie}</div>}
                                    {equipo.ubicacion && <div><strong>Ubicación:</strong>{equipo.ubicacion}</div>}
                                    {equipo.potencia && <div><strong>Potencia:</strong>{equipo.potencia} W</div>}
                                    {equipo.tipo_refrigerante_carburante && <div><strong>Refrigerante/Carburante:</strong>{equipo.tipo_refrigerante_carburante}</div>}
                                    {equipo.fecha_fin_garantia && <div><strong>Fin de garantía:</strong>{formatDate(equipo.fecha_fin_garantia)}</div>}
                                </div>
                            </Collapse>
                        </div>
                        {cuestionarios.map(cuestionario => {
                            const numPreguntas = cuestionario.preguntasIds.length;
                            const numContestadas = Object.keys(values.respuestas).length > 0 ? cuestionario.preguntasIds.map(id => values.respuestas[id].valor).filter(Boolean).length : 0;
                            return (
                                <React.Fragment key={cuestionario.id}>
                                    <div className={clsx(classes.item, classes.cuestionarioTitle)}><div><strong>Cuestionario:</strong>{cuestionario.nombre}</div><span>{numContestadas}/{numPreguntas}</span></div>
                                    {cuestionario.secciones.map((seccion, index) => {
                                        const isThisExpanded = expandedSeccion === seccion.id;
                                        return (
                                            <div key={index} className={classes.item}>
                                                <div className={classes.itemHeader} onClick={() => setExpandedSeccion(isThisExpanded ? null : seccion.id)}>
                                                    <Typography className={classes.title}>{seccion.titulo}</Typography>
                                                    {isThisExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon/>}
                                                </div>
                                                <Collapse in={isThisExpanded}>
                                                    <div>
                                                        {seccion.preguntas.map((pregunta, i) => {
                                                            const PreguntaComponent = seccion.tipo === 'CHOICE' ? PreguntaChoice : PreguntaText;
                                                            return (
                                                                <PreguntaComponent
                                                                    key={i}
                                                                    pregunta={pregunta}
                                                                    columnas={seccion.columnas}
                                                                    onValueSaved={valor => {
                                                                        const respuesta = parteMantenimiento.respuestas.find(respuesta => respuesta.pregunta_id === pregunta.id);
                                                                        if (respuesta) {
                                                                            respuesta.valor = valor;
                                                                        } else {
                                                                            parteMantenimiento.respuestas.push({pregunta_id: pregunta.id, valor, notas: ''})
                                                                        }
                                                                    }}
                                                                    onChangeNotas={notas => setRespuestas(
                                                                        ({
                                                                            ...values.respuestas,
                                                                            [pregunta.id]: {
                                                                                ...values.respuestas[pregunta.id],
                                                                                notas
                                                                            }
                                                                        })
                                                                    )}
                                                                />
                                                            )
                                                        })}
                                                    </div>
                                                </Collapse>
                                            </div>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })}
                        {parteMantenimiento.firma_operario ?
                            <PageBodySection style={{marginTop: 'auto'}}>
                                <Typography variant='h4'>Firma del operario</Typography>
                                <div className={classes.signature}>
                                    <img
                                        src={isFirmaExistente ? parteMantenimiento.firma_operario.image_url : parteMantenimiento.firma_operario.imagen}
                                    />
                                </div>
                                <Typography className={classes.signatureName}>Firmado por: {parteMantenimiento.firma_operario.nombre}</Typography>
                                {parteMantenimiento.terminado ?
                                    <BigButton
                                        disabled={isSubmitting}
                                        onClick={() => tareasProvider
                                            .setParteMantenimientoTerminado(parteMantenimiento.id, false)
                                            .then(updatedParteMantenimiento => {
                                                setTarea(tarea => ({
                                                    ...tarea,
                                                    partes_mantenimiento: tarea.partes_mantenimiento.map(parte => parte.id === updatedParteMantenimiento.id ? updatedParteMantenimiento : parte)
                                                }));
                                                setView(PARTE_MANTENIMIENTO, {parteMantenimiento: updatedParteMantenimiento});
                                            })
                                        }
                                    >
                                        CONTINUAR PARTE DE MANTENIMIENTO
                                    </BigButton>
                                    :
                                    <ButtonDialog
                                        title={'¿Estás seguro que has terminado el parte de mantenimiento?'}
                                        content={!puedeTerminar ? 'Aún te quedan preguntas sin contestar' : ''}
                                        okText='Terminar'
                                        button={
                                            <BigButton
                                                disabled={isSubmitting}

                                            >
                                                TERMINAR PARTE DE MANTENIMIENTO
                                            </BigButton>
                                        }
                                        onAccept={() => tareasProvider
                                            .setParteMantenimientoTerminado(parteMantenimiento.id, true, parteMantenimiento.firma_operario)
                                            .then(updatedParteMantenimiento => {
                                                setTarea(tarea => ({
                                                    ...tarea,
                                                    partes_mantenimiento: tarea.partes_mantenimiento.map(parte => parte.id === updatedParteMantenimiento.id ? updatedParteMantenimiento : parte)
                                                }));
                                                setView(PARTES_MANTENIMIENTO_LIST, {parteMantenimiento: updatedParteMantenimiento});
                                            })
                                        }
                                    />}
                            </PageBodySection>
                            :
                            <>
                                <BigButton
                                    style={{ margin: 'auto 16px 16px 16px'}}
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        showCustomComponent(({closeSplash}) => (
                                            <FirmarView
                                                defaultNombre={userName}
                                                onSave={firma => {
                                                    setView(PARTE_MANTENIMIENTO, {parteMantenimiento: {...parteMantenimiento, firma_operario: firma}});
                                                    closeSplash();
                                                }}
                                                onClose={closeSplash}
                                            />
                                        ));
                                    }}
                                >
                                    FIRMAR PARTE DE MANTENIMIENTO
                                </BigButton>
                                <ButtonDialog
                                    title='Con esta acción rellenarás el cuestionario sin incidencias de forma automática. Podrás editar cada pregunta si lo deseas.'
                                    okText='Autocompletar'
                                    onAccept={() => {
                                        tareasProvider
                                            .setParteMantenimientoDefault(parteMantenimiento.id)
                                            .then(updatedParteMantenimiento => {
                                                setTarea(tarea => ({
                                                    ...tarea,
                                                    partes_mantenimiento: tarea.partes_mantenimiento.map(parte => parte.id === updatedParteMantenimiento.id ? updatedParteMantenimiento : parte)
                                                }));
                                                setView(PARTE_MANTENIMIENTO, {parteMantenimiento: updatedParteMantenimiento});
                                                setCuestionarios([]);
                                            })
                                    }}
                                    button={
                                        <Button
                                            className={classes.secondaryButton}
                                            endIcon={<ArrowForwardIcon />}
                                        >
                                            Autocompletar cuestionario sin incidencias
                                        </Button>
                                    }
                                />
                            </>}

                    </>
                )
            }}

        </Formik>
    );
};