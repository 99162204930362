import React, {useEffect, useState} from "react";
import {PageHeader} from "../common/PageHeader";
import {PageBody} from "../common/PageBody";
import Navigation from "../common/Navigation";
import {makeStyles} from "@material-ui/core/styles";
import {ResumenTiempo} from "../jornada/ResumenTiempo";
import {fichajesProvider, operariosProvider} from "../../api";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {useHistory} from "react-router-dom";
import LeyendaHistorial from "./LeyendaHistorial";
import HistorialNavegacion from "./HistorialNavegacion";
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';
import HistorialFichajes from "./HistorialFichajes";

const useStyles = makeStyles(theme => ({
    resumenTiempo: {
        marginTop: theme.spacing(2),
    },
}));

export const HistorialFichajesView = () => {
    const history = useHistory();
    const classes = useStyles();
    const [fecha, setFecha] = useState(new Date());
    const [resumenTiempo, setResumenTiempo] = useState({});

    useEffect(() => {
        operariosProvider.getResumenHorasMes(fecha.getFullYear(), fecha.getMonth() + 1).then(setResumenTiempo);
    }, [fecha]);

    const {laborables, trabajadas, extras} = resumenTiempo;

    return (
        <React.Fragment>
            <PageHeader
                title='Historial de fichajes'
                startButton={
                    <IconButton
                        onClick={() => history.push('/fichajes')}
                    >
                        <ArrowBackIcon style={{color: 'white'}} />
                    </IconButton>
                }
            >
                <ResumenTiempo
                    laborables={laborables}
                    realizadas={trabajadas}
                    extras={extras}
                    className={classes.resumenTiempo}
                />
            </PageHeader>
            <PageBody>
                <LeyendaHistorial />
                <HistorialNavegacion
                    fecha={fecha}
                    goNextMonth={() => setFecha(fecha => addMonths(fecha, 1))}
                    goPreviousMonth={() => setFecha(fecha => subMonths(fecha, 1))}
                />
                <HistorialFichajes fecha={fecha} />
            </PageBody>
        </React.Fragment>
    );
}
