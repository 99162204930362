import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 4,
        backgroundColor: '#9FCBFF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(0.5, 1),
        fontSize: 12,
        color: '#4D64FF',
        textAlign: 'center',
        position: 'relative',
    },
    active: {
        backgroundColor: 'white',
        fontWeight: 'bold',
    },
}));

const TabbedFilterItem = ({label, onClick, classes, active=false}) => {
    let styles = useStyles();

    return (
        <div
            className={classNames(styles.root, {[styles.active]: active}, classes && classes.root, classes && {[classes.active]: active})}
            onClick={onClick}
        >
            {label}
        </div>
    )
};

export default ({onFilterClicked, className, style, selectedFilter, filters, classes}) => {
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            {filters.map(({label, value}, i) => (
                <TabbedFilterItem
                    key={i}
                    label={label}
                    active={selectedFilter === value}
                    onClick={() => onFilterClicked(value)}
                    classes={classes}
                />
            ))}
        </div>
    )
};