import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    botonTelefono: {
        backgroundColor: '#0FC292',
        color: 'white',
        marginTop: theme.spacing(2),
        '&:hover': {
            backgroundColor: 'inherit',
        },
    },
}));

export function LocationDialog({button, tarea}) {
    const styles = useStyles();
    const [open, setOpen] = useState(false);

    const formButton = React.cloneElement(button, {onClick: () => setOpen(true)});

    function openMaps(direccion, placeId) {
        const params = {
            destination: direccion,
        };

        if (placeId)
            params.destination_place_id = placeId;

        const url = `https://www.google.com/maps/dir/?api=1&${new URLSearchParams(params).toString()}`;
        window.open(url, '_blank');
    }

    return (
        <>
            {formButton}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
            >
                <IconButton
                    onClick={() => {
                        setOpen(false);
                    }}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogTitle
                    disableTypography
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 600,
                    }}
                >
                    <LocationOnIcon color='primary' style={{fontSize: 60, marginBottom: 8}}/>
                    <span>{tarea.direccion.direccion_completa}</span>
                    <span style={{marginTop: 16}}>{tarea.telefono}</span>
                </DialogTitle>
                <DialogActions disableSpacing style={{flexDirection: 'column', alignItems: 'center', padding: 20}}>
                    <Button
                        onClick={() => {
                            setOpen(false);
                            openMaps(tarea.direccion.direccion_completa, tarea.direccion.google_place_id);
                        }}
                        variant='contained'
                        color="primary"
                        autoFocus
                        fullWidth
                        disableElevation
                    >
                        ¿Cómo llegar?
                    </Button>
                    <Button
                        onClick={() => {
                            setOpen(false);
                            window.open('tel://' + tarea.telefono.replace(/[^0-9]+/g, ''));
                        }}
                        variant='contained'
                        className={styles.botonTelefono}
                        fullWidth
                        disableElevation
                    >
                        Llamar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}