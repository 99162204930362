import React, {useEffect, useState} from "react";
import {tareasProvider} from "../../../api";
import ListView from "./ListView";
import EditarTarea from "./EditarTarea";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";


export default () => {
    const [tareas, setTareas] = useState(null);
    const [selectedTarea, setSelectedTarea] = useState(null);
    const [reabrirTarea, setReabrirTarea] = useState(null);

    useEffect(() => {
        tareasProvider.getAll('for_edit').then(setTareas);
    }, []);


    const columns = React.useMemo(() => [
        {
            accessor: 'descripcion',
        },
        {
            accessor: 'cliente',
        },
        {
            accessor: 'estado',
        },
        {
            accessor: 'direccion',
        },
        {
            accessor: 'fecha',
        },
        {
            id: 'operarios',
            accessor: row => row.operarios.map(op => op.nombre).join(' | '),
        },
    ], []);

    return (
        <>
            <Dialog
                open={Boolean(reabrirTarea)}
            >
                <DialogTitle
                    disableTypography
                    style={{
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 600,
                    }}
                >
                    Reabrir el servicio
                </DialogTitle>
                <DialogContent
                    style={{
                        color: '#818CAE',
                        fontSize: 13,
                        textAlign: 'center',
                    }}
                >
                    Este servicio está cerrado. Antes de poder editarlo hay que reabrirlo
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between', padding: 20}}>
                    <Button onClick={() => setReabrirTarea(null)}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            tareasProvider.actionOnId(reabrirTarea.id, 'abrir').then(res => {
                                setReabrirTarea(null);
                                setTareas(tareas => {
                                    const idx = tareas.findIndex(t => t.id === reabrirTarea.id);
                                    return [
                                        ...tareas.slice(0, idx),
                                        {
                                            ...tareas[idx],
                                            estado: 'EN_PROGRESO',
                                        },
                                        ...tareas.slice(idx + 1),
                                    ];
                                });
                                setSelectedTarea(reabrirTarea);
                            });
                        }}
                        variant='contained'
                        color="primary"
                        autoFocus
                    >
                        Reabrir
                    </Button>
                </DialogActions>
            </Dialog>
            {selectedTarea &&
            <Dialog
                fullScreen
                open={true}
            >
                <EditarTarea
                    tarea={selectedTarea}
                    onClose={() => setSelectedTarea(null)}
                    onSave={tarea => {
                        setTareas(tareas => {
                            const idx = tareas.findIndex(t => t.id === tarea.id);
                            return [
                                ...tareas.slice(0, idx),
                                tarea,
                                ...tareas.slice(idx + 1),
                            ];
                        });
                        setSelectedTarea(null);
                    }}
                    onDelete={() => {
                        setTareas(tareas => {
                            const idx = tareas.findIndex(t => t.id === selectedTarea.id);
                            return [
                                ...tareas.slice(0, idx),
                                ...tareas.slice(idx + 1),
                            ];
                        });
                        setSelectedTarea(null);
                    }}
                />
            </Dialog>}
            {tareas &&
            <ListView
                title='Todas las tareas'
                items={tareas}
                columns={columns}
                searchPlaceholder='Buscar tarea'
                onSelect={idx => {
                    const tarea = tareas[idx];
                    if (tarea.estado === 'FINALIZADA')
                        setReabrirTarea(tarea);
                    else
                        setSelectedTarea(tarea);
                }}
            />}
        </>
    );
}



