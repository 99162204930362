import {formatTiempo} from "../../utils";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import * as classnames from "classnames";

const useStyles = makeStyles(theme => ({
    resumenTiempo: {
        display: 'flex',
        color: 'white',
        '&>div': {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
}));

export function ResumenTiempo({laborables, realizadas, extras, className, style}) {
    const styles = useStyles();

    if (!laborables || !realizadas || !extras)
        return null;

    return (
        <div className={classnames(styles.resumenTiempo, className)} style={style}>
            <div>
                <strong>{laborables}h</strong>
                Laborables
            </div>
            <div>
                <strong>{formatTiempo(realizadas)}</strong>
                Realizadas
            </div>
            <div>
                <strong>{formatTiempo(extras)}</strong>
                Extras
            </div>
        </div>
    );
}