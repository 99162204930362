import React, {useMemo} from "react";
import {PageHeader} from "./PageHeader";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import {TextField} from "../tareas/common/TextField";
import SearchIcon from "@material-ui/icons/Search";
import {PageBody} from "./PageBody";
import MuiList from "@material-ui/core/List";
import ListItem from "../tareas/common/ListItem";
import MuiListItemIcon from "@material-ui/core/ListItemIcon";
import MuiListItemText from "@material-ui/core/ListItemText";
import {useAsyncDebounce, useGlobalFilter, useRowSelect, useTable} from "react-table";
import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import withStyles from "@material-ui/core/styles/withStyles";

function GlobalFilter({
                          searchPlaceholder,
                          globalFilter,
                          setGlobalFilter,
                      }) {
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <TextField
            fieldStyle={{marginTop: 16}}
            value={value || ""}
            onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            backgroundColor='#DBE2FF'
            startAdornment={<SearchIcon/>}
            placeholder={searchPlaceholder}
            id="global-filter-input"
        />
    )
}

const ListItemIcon = withStyles(theme => ({
    root: {
        minWidth: 36,
    },
}))(MuiListItemIcon);

const ListItemText = withStyles(theme => ({
    inset: {
        paddingLeft: 36,
    },
}))(MuiListItemText);


const ResumenSeleccionados = withStyles(theme => ({
    root: {
        color: 'white',
        fontSize: 10,
    },
    item: {
        '& + &::before': {

            content: '", "',
        },
    },
}), {name: 'ResumenSeleccionados'})(({rows, classes}) => {
    console.log(rows)
    return (
        <div className={classes.root}>
            {rows.length > 0 &&
            <>
                Has seleccionado:&nbsp;
                {rows.map((row, i) => <span key={i} className={classes.item}>{row.cells[0].render('Cell')}</span>)}
            </>
            }
        </div>
    )
});

export default ({title, setFieldValue, searchPlaceholder, onClose, currentValue, items, columns, multiple=false, canAdd=false}) => {

    const initialSelectedRowIds = {};
    if (currentValue) {
        const currentRows = currentValue instanceof Array ? currentValue : [currentValue];
        currentRows.forEach(row => initialSelectedRowIds[items.findIndex(item => item.id === row.id)] = true);
    }

    const {
        rows,
        prepareRow,
        state: { selectedRowIds, globalFilter },
        setGlobalFilter,
        toggleAllRowsSelected,
        selectedFlatRows,
    } = useTable({
        columns,
        data: items,
        initialState: {
            selectedRowIds: initialSelectedRowIds,
        },
    }, useRowSelect, useGlobalFilter);

    const RenderRow = React.useCallback(
        ({ index, style }) => {
            if (canAdd && index === rows.length) {
                return (
                    <ListItem
                        style={style}
                        onClick={() => {
                            const columnName = columns[0].id || columns[0].accessor;
                            const obj = {
                                id: null,
                                [columnName]: globalFilter,
                            };
                            setFieldValue(multiple ? [obj] : obj);
                        }}
                    >
                        <ListItemIcon><AddIcon/></ListItemIcon>
                        <ListItemText
                            primary={`Añadir "${globalFilter}"`}
                        />
                    </ListItem>
                )
            }

            const row = rows[index];
            prepareRow(row);
            return (
                <ListItem
                    {...row.getRowProps({
                        style,
                    })}
                    onClick={() => {
                        if (!multiple)
                            toggleAllRowsSelected(false);
                        row.toggleRowSelected();
                    }}
                >
                    {row.isSelected && <ListItemIcon><DoneIcon/></ListItemIcon>}
                    <ListItemText
                        primary={row.cells[0].render('Cell')}
                        secondary={row.cells[1] && row.cells[1].render('Cell')}
                        inset={!row.isSelected}
                    />
                </ListItem>
            )
        },
        [prepareRow, rows, selectedRowIds, multiple, toggleAllRowsSelected]
    );

    const resumen = useMemo(() => {
        if (!multiple)
            return null;

        selectedFlatRows.forEach(prepareRow);

        return <ResumenSeleccionados rows={selectedFlatRows} />;
    }, [selectedFlatRows, prepareRow]);

    return (
        <React.Fragment>
            <PageHeader
                title={title}
                startButton={
                    <IconButton
                        onClick={onClose}
                    >
                        <CloseIcon style={{color: 'white'}}/>
                    </IconButton>
                }
                endButton={
                    <IconButton
                        onClick={() => {
                            const selectedItems = selectedFlatRows.map(row => row.original);
                            setFieldValue(multiple ? selectedItems : selectedItems[0]);
                            onClose();
                        }}
                        disabled={selectedFlatRows.length === 0}
                    >
                        <DoneIcon style={{color: 'white'}}/>
                    </IconButton>
                }
            >
                <GlobalFilter
                    searchPlaceholder={searchPlaceholder}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                {resumen}
            </PageHeader>
            <PageBody
                paddingTop={8}
            >
                <AutoSizer>
                    {({height, width}) => (
                        <MuiList disablePadding>
                            <FixedSizeList
                                className="List"
                                height={height}
                                itemCount={rows.length + (canAdd && globalFilter ? 1 : 0)}
                                itemSize={75}
                                width={width}
                            >
                                {RenderRow}
                            </FixedSizeList>
                        </MuiList>
                    )}
                </AutoSizer>
            </PageBody>
        </React.Fragment>
    )
}