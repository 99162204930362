import InputBase from "@material-ui/core/InputBase";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {useField} from "formik";
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from '@material-ui/icons/Replay';
import {deepEqual} from "../../utils";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff',
        borderRadius: 8,
        margin: theme.spacing(1),
        padding: theme.spacing(0.5, 1.5),
        fontSize: 13,
        color: '#818CAE',
        '&.Mui-error': {
            border: '2px solid #D84A3A',
        }
    },
    readOnly: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}), {name: 'RoundedInput'});


export const RoundedReadOnly = ({style, className, endAdornment, ...props}) => {
    const styles = useStyles();

    const [field, , ] = useField(props);

    return (
        <div
            style={style}
            className={clsx(styles.root, styles.readOnly, className)}
            {...props}
        >
            {field.value}
            {endAdornment}
        </div>
    );
};



export const RoundedInput = ({style, className, ...props}) => {
    const styles = useStyles();

    const [{value, ...field}, meta, ] = useField(props);

    return (
        <InputBase
            value={value || ''}
            {...field}
            style={style}
            className={clsx(styles.root, className)}
            error={Boolean(meta.touched && meta.error)}
            {...props}
        />
    );
};
