import DataProvider from "./DataProvider";

export const empty = {
};


class MaterialesProvider extends DataProvider {
    constructor() {
        super('materiales_internos', empty);
    }

    getAsOptions = async () => {
        return this.getAll('as_options');
    }
}

export const dataProvider = new MaterialesProvider();
