import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import classNames from 'classnames';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TodayIcon from '@material-ui/icons/Today';
import ReceiptIcon from '@material-ui/icons/Receipt';
import {formatDate, formatDateTime, formatTiempo, isAllDay} from "../utils";
import {estadoLabels} from "../api/tareas";
import useAuthState from "../contexts/AuthState";
import Chip from "@material-ui/core/Chip";
import OperariosIcon from "./icons/Operarios";
import clsx from "clsx";
import EventIcon from "@material-ui/icons/Event";
import {isTiempoZero} from "../api/tareas-functions";

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 8,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        color: '#213061',
        position: 'relative',
        margin: theme.spacing(1, 0),
    },
    fecha: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        color: '#818CAE',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        '& svg': {
            fontSize: 16,
            marginRight: theme.spacing(0.5),
        },
    },
    cliente: {
        fontWeight: 'bold',
        fontSize: 18,
        marginBottom: theme.spacing(1),
    },
    descripcion: {
        fontSize: 14,
        marginBottom: theme.spacing(2),
    },
    upperRow: {
        display: 'flex',
        justifyContent: 'space-between',
        // marginBottom: theme.spacing(1),
        margin: '0 -4px 8px',
        gap: '8px',
    },
    chip: {
        backgroundColor: '#f3f5fa',
        color: '#818CAE',
        fontSize: 12,
        '& svg': {
            height: 14,
            width: 14,
            color: 'inherit',
        },
    },
    operariosExtra: {
        marginLeft: 'auto',
    },
    statusLabel: {
        // position: 'absolute',
        // top: theme.spacing(2),
        // right: theme.spacing(2),
        padding: theme.spacing(0.5, 0),
        width: 70,
        borderRadius: 20,
        fontSize: 12,
        fontWeight: 500,
        letterSpacing: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    pendiente: {
        backgroundColor: '#D9EEFF',
        color: '#2196F3',
    },
    en_progreso: {
        backgroundColor: '#FFECCF',
        color: '#F3AC3D',
    },
    finalizada: {
        backgroundColor: '#EDF8F5',
        color: '#4DB7A0',
    },
}), {name: 'TareaCard'});


const useFooterElementStyles = makeStyles(theme => ({
    root: {
        color: '#818CAE',
        display: 'flex',
        fontSize: 13,
    },
    label: {
        // fontSize: 11,
        // marginBottom: 4,
        '& strong': {
            fontWeight: 500,
        },
    },
    value: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            fontSize: 13,
        },
        '& span': {
            // fontSize: 13,
            marginLeft: 4
        },
        '$zero &': {
            color: theme.palette.error.main,
        },
    },
    zero: {

    },
}), {name: 'TareaCardFooterElement'});

const FooterElement = ({label, icon, value, className}) => {
    const classes = useFooterElementStyles();

    return (
        <div
            className={clsx(classes.root, className)}
        >
            <div
                className={classes.label}
            >{label}</div>
            <div
                className={classes.value}
            >
                {icon}
                <span>{value}</span>
            </div>
        </div>
    );
}

const Tiempo = ({label, tiempo}) => {
    const classes = useFooterElementStyles();

    return (
        <FooterElement
            label={<strong>{label}</strong>}
            // icon={<ScheduleIcon />}
            value={formatTiempo(tiempo)}
            className={isTiempoZero(tiempo) ? classes.zero : null}
        />
    );
}

const tipoLabels = {
    AGENDA: 'Agenda',
    INSTALACION: 'Instalacion',
    MANTENIMIENTO: 'Mantenimiento',
    REPARACION: 'Reparacion',
    OTROS: 'Otros',
    SUMINISTRO: 'Suministro',
    PRESUPUESTO: 'Presupuesto',
};

export default function TareaCard({cliente, descripcion, estado, tiempo, tiempoLabel, tiempoAcumulado, onClick, numero, fechaInicio, fechaFin, numOperarios, tipo}) {
    const styles = useStyles();
    const formatFn = isAllDay(fechaInicio, fechaFin) ? formatDate : formatDateTime;

    return (
        <div
            className={styles.root}
            onClick={onClick}
        >
            <div className={styles.upperRow}>
                <Chip
                    label={tipoLabels[tipo]}
                    // icon={<TodayIcon fontSize='small' />}
                    size='small'
                    className={styles.chip}
                    style={{fontWeight: 500}}
                />
                {/*<Chip*/}
                {/*    label={formatFn(fecha)}*/}
                {/*    // icon={<TodayIcon fontSize='small' />}*/}
                {/*    size='small'*/}
                {/*    className={styles.chip}*/}
                {/*/>*/}
                {numOperarios > 1 &&
                <Chip
                    label={`+${numOperarios-1}`}
                    icon={<OperariosIcon fontSize='small' />}
                    size='small'
                    className={clsx(styles.chip, styles.operariosExtra)}
                />}
                <div className={classNames(styles.statusLabel, styles[estado.toLowerCase()])}>{estadoLabels[estado]}</div>
            </div>
            <div className={styles.fecha}><EventIcon />{formatFn(fechaInicio)} a {formatFn(fechaFin)}</div>
            <div className={styles.cliente}>{cliente}</div>
            <div className={styles.descripcion}>{descripcion}</div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <FooterElement
                    // icon={<ReceiptIcon />}
                    label='Ref:'
                    value={numero ? numero.toString().padStart(4, '0') : 'N/D'}
                />
                <Tiempo label={tiempoLabel || 'Hoy:'} tiempo={tiempo} />
                {tiempoAcumulado && <Tiempo label='Total:' tiempo={tiempoAcumulado} />}
            </div>
        </div>
    )
}