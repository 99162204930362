import React, {useCallback, useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {CERRAR, useTareaState} from "./TareaView";
import SignatureCanvas from 'react-signature-canvas';
import IconButton from "@material-ui/core/IconButton";
import {PageHeader} from "../common/PageHeader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DoneIcon from "@material-ui/icons/Done";
import Typography from "@material-ui/core/Typography";
import {PageBody} from "../common/PageBody";
import InputBase from "@material-ui/core/InputBase";
import SyncIcon from '@material-ui/icons/Sync';
import Button from "@material-ui/core/Button";
import {Icon} from "@material-ui/core";
import {useSnackbar} from "material-ui-snackbar-provider";

const useStyles = makeStyles(theme => ({
    label: {
        color: '#818CAE',
        fontSize: 12,
        margin: theme.spacing(1, 0),
    },
    input: {
        backgroundColor: 'white',
        borderRadius: 4,
        padding: theme.spacing(0.5, 1),
        fontSize: 13,
        color: '#818CAE',
        marginBottom: theme.spacing(3),
    },
    clearSignature: {
        position: 'absolute',
        top: 8,
        right: 8,
        boxShadow: 'none',
        textTransform: 'none',
        color: 'white',
        backgroundColor: '#4D86FF',
        '&:focus, &:active': {
            backgroundColor: '#4D86FF',
        }
    },
    signatureWrapper: {
        flex: 1,
        border: '1px dashed #bababa',
        borderRadius: 4,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        marginBottom: theme.spacing(3),
    },
}));

export function FirmarView({onClose, onSave, defaultNombre = ''}) {
    const classes = useStyles();
    const {tarea} = useTareaState();
    const [signatureResult, setSignatureResult] = useState({
        nombre: defaultNombre,
        imagen: '',
        coordenadas: null,
    });

    const snackbar = useSnackbar();

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    console.log(position);

                    setSignatureResult(signature => ({
                        ...signature,
                        coordenadas: `${position.coords.latitude},${position.coords.longitude}`,
                    }));
                },
                function (err) {
                    console.log(err);

                    if (err.code === 1) {
                        snackbar.showMessage('Permisos de localización desactivados. Por favor, activalos en la configuración de tu navegador web')
                    }

                    setSignatureResult(signature => ({
                        ...signature,
                        coordenadas: null,
                    }));
                },
                {
                    maximumAge: 60000,
                    timeout: 5000,
                    enableHighAccuracy: true,
                },
            );
        }
    }, [])

    useEffect(() => {setSignatureResult(signature => ({...signature, nombre: defaultNombre}))}, [defaultNombre]);

    const sigCanvas = useRef({});
    const sigPad = useRef(null);
    const input = useRef(null);

    const setNombreOnChange = ev => {
        const value = ev.target.value;
        setSignatureResult(current => ({...current, nombre: value}));
    };

    const setSignatureOnChange = () => {
        const dataURL = sigPad.current.getTrimmedCanvas().toDataURL();
        setSignatureResult(result => ({...result, imagen: dataURL}));
    }

    const clearInput = () => {
        sigPad.current.clear();
        setSignatureResult(current => ({
            ...current,
            imagen: '',
        }));
    };

    const measuredRef = useCallback(node => {
        const resizeCanvas = (signaturePad, canvas) => {
            canvas.width = canvas.parentElement.clientWidth;
            canvas.height = canvas.parentElement.clientHeight;
            signaturePad.clear();
        }

        if (node !== null) {
            sigCanvas.current = node.getCanvas()
            sigPad.current = node;
            resizeCanvas(node.getSignaturePad(), node.getCanvas())
        }
    }, []);

    useEffect(() => {
        const resizeCanvas = () => {
            if (!sigCanvas.current)
                return;

            const canvas = sigCanvas.current;
            canvas.width = canvas.parentElement.clientWidth;
            canvas.height = canvas.parentElement.clientHeight;
        }

        window.addEventListener('resize', resizeCanvas)

        return () => window.removeEventListener('resize', resizeCanvas);
    }, []);

    const isSignatureValid = signatureResult.imagen !== null && signatureResult.nombre != '';

    return (
        <React.Fragment>
            <PageHeader
                title='Conformidad'
                startButton={
                    <IconButton
                        onClick={onClose}
                    >
                        <ArrowBackIcon style={{color: 'white'}}/>
                    </IconButton>
                }
                endButton={
                    <IconButton
                        onClick={() => onSave(signatureResult)}
                        disabled={!isSignatureValid}
                        style={{opacity: isSignatureValid ? 1 : 0.4}}
                    >
                        <DoneIcon style={{color: 'white'}}/>
                    </IconButton>
                }
            >
                <Typography variant='h2'>{tarea.cliente}</Typography>
                <Typography variant='h3'>{tarea.descripcion}</Typography>
            </PageHeader>
            <PageBody>
                <Typography className={classes.label}>Introduce tu nombre completo</Typography>
                <InputBase
                    inputRef={input}
                    className={classes.input}
                    onChange={setNombreOnChange}
                    value={signatureResult.nombre}
                />
                <Typography className={classes.label}>Firma en el recuadro</Typography>
                <div className={classes.signatureWrapper}>
                    {isSignatureValid &&
                    <Button
                        size='small'
                        onClick={clearInput}
                        className={classes.clearSignature}
                        startIcon={<SyncIcon />}
                        variant='contained'
                    >
                        Repetir
                    </Button>}
                    <SignatureCanvas
                        ref={measuredRef}
                        penColor='black'
                        clearOnResize={false}
                        onBegin={() => input.current.blur()}
                        onEnd={setSignatureOnChange}
                    />
                </div>
            </PageBody>
        </React.Fragment>
    );
}