import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
import MuiList from "@material-ui/core/List";
import {useAsyncDebounce, useGlobalFilter, useTable} from "react-table";
import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import {PageBody} from "../../common/PageBody";
import {PageHeader} from "../../common/PageHeader";
import {TextField} from "../common/TextField";
import {makeStyles} from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import {useHistory} from "react-router-dom";
import {formatDate} from "../../../utils";

const useStyles = makeStyles(theme => ({
    item: {
        color: '#818CAE',
        padding: theme.spacing(1.5, 2.5),
        boxSizing: 'border-box',
        borderBottom: '1px solid #ddd',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    itemHeader: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    cliente: {
        fontSize: 14,
        fontWeight: 500,
        color: '#213061',
        flex: 1,
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    fecha: {
        fontSize: 10,
        margin: theme.spacing(0, 0.5),
    },
    status: {
        fontSize: 16,
        color: '#213061',
    },
    descripcion: {
        fontSize: 11,
        margin: theme.spacing(1, 0),
    },
    operarios: {
        fontSize: 10,
    },
}));


function GlobalFilter({
                          searchPlaceholder,
                          globalFilter,
                          setGlobalFilter,
                      }) {
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <TextField
            value={value || ""}
            onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            backgroundColor='#DBE2FF'
            startAdornment={<SearchIcon/>}
            placeholder={searchPlaceholder}
        />
    )
}

export default ({title, searchPlaceholder, items, columns, onSelect}) => {
    const history = useHistory();
    const classes = useStyles();

    const {
        rows,
        prepareRow,
        state: { globalFilter },
        setGlobalFilter,
    } = useTable({
        columns,
        data: items,
    }, useGlobalFilter);

    const RenderRow = React.useCallback(
        ({ index, style }) => {
            const row = rows[index];
            prepareRow(row);

            const StatusIcon = row.values.estado === 'FINALIZADA' ? LockIcon : LockOpenIcon;

            return (
                <ButtonBase
                    {...row.getRowProps({
                        style,
                    })}
                    className={classes.item}
                    component='div'
                    onClick={() => onSelect(row.index)}
                >
                    <div className={classes.itemHeader}>
                        <div className={classes.cliente}>{row.values.cliente}</div>
                        <div className={classes.fecha}>{formatDate(new Date(row.values.fecha))}</div>

                        <StatusIcon className={classes.status} />
                    </div>
                    <div className={classes.descripcion}>{row.values.descripcion}</div>

                    <div className={classes.operarios}>{row.values.operarios}</div>
                </ButtonBase>
            )
        },
        [prepareRow, rows, classes, onSelect]
    );

    return (
        <React.Fragment>
            <PageHeader
                title={title}
                startButton={
                    <IconButton
                        onClick={() => history.push('/')}
                    >
                        <CloseIcon style={{color: 'white'}}/>
                    </IconButton>
                }
            >
                <GlobalFilter
                    searchPlaceholder={searchPlaceholder}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            </PageHeader>
            <PageBody
                style={{padding: 0, paddingTop: 8}}
            >
                <AutoSizer>
                    {({height, width}) => (
                        <MuiList disablePadding>
                            <FixedSizeList
                                className="List"
                                height={height}
                                itemCount={rows.length}
                                itemSize={95}
                                width={width}
                            >
                                {RenderRow}
                            </FixedSizeList>
                        </MuiList>
                    )}
                </AutoSizer>
            </PageBody>
        </React.Fragment>
    )
}