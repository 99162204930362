import React from "react";
import {PageHeader} from "../../common/PageHeader";
import {PageBody} from "../../common/PageBody";
import {createMuiTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";
import {formatISODate} from "../../../utils";
import {DatePicker} from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {GENERAL} from "../nueva/NuevaTarea";

const useStyles = makeStyles(theme => ({
    subtitle: {
        fontSize: 14,
        color: 'white',
        margin: theme.spacing(2, 3),
        textAlign: 'center',
    },
    jornadaVacia: {
        color: '#8F95AF',
    },
    jornadaCompleta: {
        color: '#2CBD96',
    },
    jornadaParcial: {
        color: '#F3AC3D',
    },
    jornadaBullet: {
        backgroundColor: '#8F95AF',
        width: 7,
        height: 7,
        borderRadius: '50%',
        marginRight: theme.spacing(1),
    },
    jornadaCompletaBullet: {
        backgroundColor: '#2CBD96',
    },
    jornadaParcialBullet: {
        backgroundColor: '#F3AC3D',
    },
    infoPaper: {
        backgroundColor: 'white',
        borderRadius: 8,
        padding: theme.spacing(2),
        '&>div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            color: '#213061',
            marginBottom: theme.spacing(1.5),
        },
        marginBottom: theme.spacing(3),
    },
}));

const materialTheme = createMuiTheme({
    overrides: {
        MuiTypography: {
            body2: {
                fontFamily: 'inherit',
                fontWeight: 'inherit',
            },
        },
        MuiButtonBase: {
            root: {
                fontFamily: 'Inter, sans-serif',
                fontSize: 13,
            }
        },
        MuiPickersBasePicker: {
            container: {
                alignItems: 'center',
            },
        },
        MuiPickersStaticWrapper: {
            staticWrapperRoot: {
                flex: 1,
                backgroundColor: 'transparent',
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                color: '#213061',
                backgroundColor: 'transparent',
            },
            transitionContainer: {
                textTransform: 'capitalize',
                color: '#213061',
            },
            dayLabel: {
                color: '#213061',
                textTransform: 'uppercase',
                fontWeight: 600,
            },
        },
        MuiPickersDay: {
            day: {
                color: 'inherit',
                fontWeight: 'normal',
            },
            daySelected: {
                backgroundColor: '#213061',
                color: 'white',
            },
            current: {
                fontWeight: 600,
                backgroundColor: '#F3AC3D',
                color: '#45486E',
            },
        },
    },
});

export default ({tarea, setFieldValue, setCurrentFieldComponent}) => {
    const styles = useStyles();

    return (
        <React.Fragment>
            <PageHeader
                title='Calendario'
                startButton={
                    <IconButton
                        onClick={() => setCurrentFieldComponent(GENERAL)}
                    >
                        <CloseIcon style={{color: 'white'}}/>
                    </IconButton>
                }
            >
                <span className={styles.subtitle}>
                    Selecciona una fecha para la tarea
                </span>
            </PageHeader>
            <PageBody>
                <ThemeProvider theme={materialTheme}>
                    <DatePicker
                        disableToolbar={true}
                        autoOk
                        openTo='date'
                        variant="static"
                        value={tarea.fecha}
                        onChange={ev => {
                            setFieldValue('fecha')(formatISODate(ev));
                            setCurrentFieldComponent(GENERAL);
                        }}
                    />
                </ThemeProvider>
            </PageBody>
        </React.Fragment>
    );
}