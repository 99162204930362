import React from "react";
import {
    DatePicker as MuiDatePicker,
    DateTimePicker as MuiDateTimePicker,
} from "@material-ui/pickers";
import {useField} from "formik";


export function DatePicker({ label, name, showError=true, ...other }) {
    const [field, m, h] = useField(name);
    const currentError = m.error;
    return (
        <MuiDatePicker
            clearable
            autoOk={true}
            name={field.name}
            value={field.value}
            format="dd/MM/yyyy"
            helperText={showError && currentError}
            error={showError && Boolean(currentError)}
            onChange={date => h.setValue(date, true)}
            {...other}
        />
    );
}


export function DateTimePicker({ label, name, showError=true, ...other }) {
    const [field, m, h] = useField(name);
    const currentError = m.error;
    return (
        <MuiDateTimePicker
            clearable
            autoOk={true}
            name={field.name}
            value={field.value}
            format="dd/MM/yyyy HH:mm"
            helperText={showError && currentError}
            error={showError && Boolean(currentError)}
            onChange={date => h.setValue(date, true)}
            {...other}
        />
    );
}