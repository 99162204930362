import React, {useCallback, useEffect, useRef, useState} from "react";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import withStyles from "@material-ui/core/styles/withStyles";
import {PageBodySection} from "../common/PageBody";
import debounce from "@material-ui/core/utils/debounce";
import {tareasProvider} from "../../api";
import {useSnackbar} from "material-ui-snackbar-provider";
import {useTareaState} from "./TareaView";
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import clsx from "clsx";

const Input = withStyles({
    root: {
        color: '#818CAE',
        fontSize: 13,
        height: '100%',
    },
    inputMultiline: {
        height: '100%',
    },
})(InputBase);


const useStyles = makeStyles(theme => ({
    saving: {
        fontSize: 12,
        color: '#818CAE',
        gap: `${theme.spacing(1)}px`,
        display: 'flex',
        alignItems: 'center',
    },
    error: {
        color: '#F24822',
    },
}), {name: 'ExplicacionView'});

export const ExplicacionView = ({id, currentExplicacion, onSave}) => {
    const classes = useStyles();
    const [explicacion, setExplicacion] = useState(currentExplicacion);
    const [saving, setSaving] = useState(null);
    const [error, setError] = useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
        if (!inputRef.current)
            return;

        inputRef.current.focus();
        inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
    }, [inputRef]);

    const changed = explicacion !== currentExplicacion;

    const saveExplicacion = useCallback(
        explicacion =>
            tareasProvider
                .updateExplicacion(id, explicacion)
                .then(() => {
                    setError(false);
                    onSave(explicacion);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => setSaving(false)),
        [id, onSave]);

    const debouncedSaveExplicacion = useCallback(debounce(saveExplicacion, 1000), [saveExplicacion]);

    function changeExplicacion(value) {
        setSaving(true);
        setError(false);
        setExplicacion(value);
        debouncedSaveExplicacion(value);
    }

    return (
        <PageBodySection>
            <div
                style={{
                    backgroundColor: '#fff',
                    borderRadius: 8,
                    padding: '8px 16px',
                    position: 'relative',
                    minHeight: 100,
                    marginBottom: 24,
                    marginTop: 16,
                    flex: 1,
                }}
            >
                <Input
                    fullWidth
                    multiline
                    rows={6}
                    value={explicacion}
                    onChange={ev => {
                        changeExplicacion(ev.target.value);
                    }}
                    inputRef={inputRef}
                />
                <div
                    style={{
                        position: 'absolute',
                        bottom: 8,
                        right: 8,
                        display: 'flex',
                    }}
                >
                    {!changed  &&
                        <IconButton
                            size='small'
                            onClick={() => changeExplicacion('')}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    }
                </div>
            </div>
            {saving !== null &&
            <>
                <div className={clsx(classes.saving, {[classes.error]: error})}>
                    {saving && <><CircularProgress size={16} /> Guardando cambios...</>}
                    {!saving && error && <><CloseIcon fontSize='small' /> Ha ocurrido un error al guardar</>}
                    {!saving && !error && <><DoneIcon fontSize='small' /> Guardado</>}
                </div>
            </>}
        </PageBodySection>
    );
};