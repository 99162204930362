import React, {useEffect, useState} from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import ResumenItem from "./ResumenItem";
import {solicitudesProvider} from "../../api";
import {useHistory} from "react-router-dom";


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        '&:not(:first-of-type)': {
            marginTop: theme.spacing(2),
        },
        marginBottom: theme.spacing(2),
    },
    header: {
        fontSize: 12,
        color: '#8F95AF',
        textTransform: 'uppercase',
        marginBottom: theme.spacing(2),
    },
}));

function ResumenList({title, items}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography className={classes.header}>{title}</Typography>
            {items.map((item, i) => (
                <ResumenItem
                    key={i}
                    label={item.label}
                    value={item.value}
                    onClick={item.onClick}
                />
            ))}
        </div>
    )
}

export default function Resumen({year}) {
    const history = useHistory();
    const [resumen, setResumen] = useState(null)

    useEffect(() => {
        solicitudesProvider.getResumen(year).then(setResumen);
    }, [year]);

    if (resumen == null)
        return null;

    const {vacaciones, ausencias} = resumen;

    return (
        <>
            <ResumenList
                title='Resumen de vacaciones'
                items={[{
                    label: 'Días disponibles de vacaciones',
                    value: vacaciones.dias.disponibles,
                }, {
                    label: 'Días totales de vacaciones',
                    value: vacaciones.dias.totales,
                }]}
            />
            <ResumenList
                title='Solicitudes de vacaciones'
                items={[{
                    label: 'Aprobadas',
                    value: vacaciones.solicitudes.aprobadas,
                    onClick: () => history.push('/solicitudes/lista/vacaciones/aprobada'),
                }, {
                    label: 'Rechazadas',
                    value: vacaciones.solicitudes.rechazadas,
                    onClick: () => history.push('/solicitudes/lista/vacaciones/rechazada'),
                }, {
                    label: 'Pendientes',
                    value: vacaciones.solicitudes.pendientes,
                    onClick: () => history.push('/solicitudes/lista/vacaciones/pendiente'),
                }, {
                    label: 'Todas',
                    value: vacaciones.solicitudes.totales,
                    onClick: () => history.push('/solicitudes/lista/vacaciones'),
                }]}
            />
            <Divider style={{marginBottom: 16}}/>
            <ResumenList
                title='Ausencias'
                items={[{
                    label: 'Ausencias realizadas',
                    value: ausencias.realizadas,
                    onClick: () => history.push('/solicitudes/lista/ausencia'),
                }]}
            />
        </>
    )
}