import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function SalidaIcon(props) {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            {...props}
        >
            <path d="M11.9734 18.6446H5.99334C5.62656 18.6446 5.32891 18.3469 5.32891 17.9801V6.01999C5.32891 5.65321 5.62659 5.35555 5.99334 5.35555H11.9734C12.3409 5.35555 12.6379 5.05855 12.6379 4.69111C12.6379 4.32367 12.3409 4.02661 11.9734 4.02661H5.99334C4.89434 4.02661 4 4.92099 4 6.01999V17.9801C4 19.0791 4.89434 19.9735 5.99334 19.9735H11.9734C12.3409 19.9735 12.6379 19.6765 12.6379 19.309C12.6379 18.9416 12.3409 18.6446 11.9734 18.6446Z" />
            <path d="M19.8028 11.5269L15.7629 7.54017C15.5024 7.28236 15.0812 7.2857 14.8234 7.54682C14.5656 7.80795 14.5682 8.22854 14.83 8.48636L17.7171 11.3355H9.98084C9.61341 11.3355 9.31641 11.6325 9.31641 12C9.31641 12.3674 9.61341 12.6644 9.98084 12.6644H17.7171L14.83 15.5136C14.5683 15.7714 14.5662 16.192 14.8234 16.4531C14.9536 16.5847 15.125 16.6511 15.2965 16.6511C15.4652 16.6511 15.634 16.5873 15.7629 16.4597L19.8028 12.473C19.929 12.3481 20.0008 12.178 20.0008 11.9999C20.0008 11.8219 19.9297 11.6525 19.8028 11.5269Z" />
        </SvgIcon>
    );
}