import React, {useState} from "react";
import {PageHeader} from "../common/PageHeader";
import {PageBody} from "../common/PageBody";
import Navigation from "../common/Navigation";
import {makeStyles} from "@material-ui/core/styles";
import PreviousIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextIcon from '@material-ui/icons/KeyboardArrowRight';
import AddIcon from '@material-ui/icons/Add';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import {IconButton} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Resumen from "./Resumen";
import Fab from "@material-ui/core/Fab";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    subtitle: {
        fontSize: 18,
        color: 'white',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            color: 'white',
        },
    },
    addButton: {
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
    },
}));

export const ResumenView = () => {
    const classes = useStyles();
    const history = useHistory();
    const [year, setYear] = useState(new Date().getFullYear());

    const goPreviousYear = () => setYear(year => year - 1);
    const goNextYear = () => setYear(year => year + 1);

    return (
        <React.Fragment>
            <PageHeader
                title='Vacaciones y Ausencias'
                endButton={
                    <IconButton
                        onClick={() => {
                            history.push('/solicitudes/calendario')
                        }}
                    >
                        <CalendarTodayOutlinedIcon style={{color: 'white', fontSize: 18}}/>
                    </IconButton>
                }
            >
                <div className={classes.subtitle}>
                    <IconButton
                        onClick={goPreviousYear}
                    >
                        <PreviousIcon />
                    </IconButton>
                    <Typography className={classes.fecha}>{year}</Typography>
                    <IconButton
                        onClick={goNextYear}
                    >
                        <NextIcon />
                    </IconButton>
                </div>
            </PageHeader>
            <PageBody style={{position: 'relative'}}>
                <Resumen year={year} />
                <Fab
                    color="primary"
                    size="medium"
                    aria-label="add"
                    className={classes.addButton}
                    onClick={() => history.push('/solicitudes/nueva')}
                >
                    <AddIcon />
                </Fab>
            </PageBody>
            <Navigation/>
        </React.Fragment>
    );
}
