import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {IconButton, Typography} from "@material-ui/core";
import NextIcon from "@material-ui/icons/KeyboardArrowRight";
import clsx from "clsx";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: 30,
        margin: theme.spacing(0.5, 0),
    },
    label: {
        color: '#213061',
        fontSize: 16,
        flex: 1,
    },
    value: {
        color: '#8F95AF',
        fontSize: 14,
    },
    button: {
        marginRight: theme.spacing(-1),
        marginLeft: theme.spacing(0.5),
    },
}));

export default function ResumenItem({label, value, onClick, className, style}) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, className)} style={style}>
            <Typography className={classes.label}>{label}</Typography>
            <Typography className={classes.value}>{value}</Typography>
            {onClick &&
            <IconButton
                onClick={onClick}
                size='small'
                className={classes.button}
            >
                <NextIcon />
            </IconButton>
            }
        </div>
    );
}