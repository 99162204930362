import React, {useEffect, useState} from "react";
import {materialesProvider} from "../../../api";
import ListView from "../../common/ListView";


export function SelectMaterialView({onChange, onClose, currentValue}) {
    const [materiales, setMateriales] = useState(null);

    useEffect(() => {
        materialesProvider.getAsOptions().then(setMateriales);
    }, []);

    const columns = React.useMemo(() => [
        {
            Header: 'Descripcion',
            accessor: 'descripcion',
        }
    ], []);

    return materiales && (
        <ListView
            title='Seleccionar material'
            onClose={onClose}
            setFieldValue={onChange}
            currentValue={currentValue && {id: currentValue.material_interno_id}}
            items={materiales}
            columns={columns}
            searchPlaceholder='Seleccionar material'
            // canAdd
            multiple={currentValue.descripcion === ''}
        />
    )
}
