import React, {useCallback, useEffect, useRef, useState} from "react";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ReplayIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import withStyles from "@material-ui/core/styles/withStyles";
import {PageBodySection} from "../../common/PageBody";
import {useSnackbar} from "material-ui-snackbar-provider";
import debounce from "@material-ui/core/utils/debounce";
import {tareasProvider} from "../../../api";
import {useTareaState} from "../TareaView";

const Input = withStyles({
    root: {
        color: '#818CAE',
        fontSize: 13,
        height: '100%',
    },
    inputMultiline: {
        height: '100%',
    },
})(InputBase);

export const AddExplicacion = ({id, currentExplicacion, onSave}) => {
    const [explicacion, setExplicacion] = useState(currentExplicacion ?? '');
    const [initialExplicacion, ] = useState(currentExplicacion ?? '');
    const {viewParams: {jornada}} = useTareaState();

    const inputRef = useRef(null);
    const snackbar = useSnackbar();

    useEffect(() => {
        if (!inputRef.current)
            return;

        inputRef.current.focus();
        inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
    }, [inputRef]);

    const changed = explicacion !== initialExplicacion;

    const debouncedSaveExplicacion = useCallback(
        debounce(
            explicacion =>
                tareasProvider
                    .updateJornadaExplicacion(jornada.id, explicacion)
                    .then(() => {
                        onSave(explicacion);
                        snackbar.showMessage('Descripción guardada')
                    })
                    .catch(() => snackbar.showMessage('Ha ocurrido un error al guardar la explicacion')),
            500
        ), [id]);

    function changeExplicacion(value) {
        setExplicacion(value);
        debouncedSaveExplicacion(value);
    }
    
    return <PageBodySection>
        <div
            style={{
                backgroundColor: '#fff',
                borderRadius: 8,
                padding: '8px 16px',
                position: 'relative',
                minHeight: 100,
                marginBottom: 24,
                marginTop: 16,
                flex: 1,
            }}
        >
            <Input
                fullWidth
                multiline
                rows={6}
                value={explicacion}
                onChange={ev => {
                    changeExplicacion(ev.target.value)
                }}
                inputRef={inputRef}
            />
            <div
                style={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8,
                    display: 'flex',
                }}
            >
                {changed ?
                    <>
                        <IconButton
                            size='small'
                            onClick={() => changeExplicacion(initialExplicacion)}
                        >
                            <ReplayIcon/>
                        </IconButton>
                    </>
                    :
                    <IconButton
                        size='small'
                        onClick={() => changeExplicacion('')}
                    >
                        <DeleteIcon/>
                    </IconButton>
                }
            </div>
        </div>
    </PageBodySection>;
};