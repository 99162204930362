import React, {useState} from "react";
import {PageHeader} from "../../common/PageHeader";
import {PageBody} from "../../common/PageBody";
import {TextField} from "../common/TextField";
import {ButtonField} from "../common/ButtonField";
import {formatDate} from "../../../utils";
import {BigButton} from "../../common/BigButton";
import {CLIENTE, DIRECCION, FECHA, OPERARIO} from "./NuevaTarea";
import {useHistory} from "react-router-dom";
import {tareasProvider} from "../../../api";
import {useSnackbar} from "material-ui-snackbar-provider";

export default ({tarea, setFieldValue, setCurrentFieldComponent}) => {
    const history = useHistory();
    const snackbar = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false);

    return (
        <React.Fragment>
            <PageHeader
                title='Añadir servicio'
            />
            <PageBody>
                <ButtonField
                    label='Cliente'
                    value={tarea.cliente && tarea.cliente.nombre}
                    placeholder='Selecciona un cliente'
                    onClick={() => setCurrentFieldComponent(CLIENTE)}
                />
                <ButtonField
                    label='Direccion'
                    value={tarea.direccion && tarea.direccion.direccion_completa}
                    placeholder={tarea.cliente ? 'Selecciona una direccion' : 'Selecciona primero un cliente'}
                    onClick={() => tarea.cliente && setCurrentFieldComponent(DIRECCION, {clienteId: tarea.cliente.id})}
                />
                <TextField
                    label='Tarea'
                    value={tarea.descripcion}
                    onChange={setFieldValue('descripcion')}
                    placeholder='Descripción de la tarea'
                />
                <ButtonField
                    label='Fecha'
                    value={tarea.fecha && formatDate(new Date(tarea.fecha))}
                    placeholder='Selecciona una fecha'
                    onClick={() => setCurrentFieldComponent(FECHA)}
                />
                <ButtonField
                    label='Operarios'
                    value={tarea.operarios && tarea.operarios.map(operario => operario.nombre).join(', ')}
                    placeholder='Selecciona un operario'
                    onClick={() => setCurrentFieldComponent(OPERARIO)}
                />
                <BigButton
                    style={{marginTop: 'auto'}}
                    disabled={!(tarea.cliente && tarea.descripcion && tarea.fecha && tarea.operarios.length > 0)}
                    onClick={() => {
                        if (isSubmitting)
                            return;

                        setSubmitting(true);

                        const fechaInicio = new Date(tarea.fecha);
                        const fechaFin = new Date(tarea.fecha);

                        fechaInicio.setHours(0, 0, 0, 0);
                        fechaFin.setHours(23,59,59, 0);

                        const values = {
                            cliente_id: tarea.cliente.id,
                            direccion_id: tarea.direccion.id,
                            descripcion: tarea.descripcion,
                            planificaciones: [{
                                fecha_inicio: fechaInicio,
                                fecha_fin: fechaFin,
                                operarios: tarea.operarios.map(op => ({operario: op, visible: true}))
                            }]
                        };

                        tareasProvider
                            .save(values)
                            .then(() => history.push('/'))
                            .catch(() => {
                                snackbar.showMessage('Ha ocurrido un error al guardar el servicio')
                            })
                            .finally(() => setSubmitting(false));
                    }}
                >
                    Guardar servicio
                </BigButton>
            </PageBody>
        </React.Fragment>
    );
}