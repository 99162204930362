import ScheduleIcon from "@material-ui/icons/Schedule";
import React from "react";
import {formatTiempo} from "../../utils";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
    },
    root: {
        flex: 1,
        display: 'flex',
        padding: theme.spacing(1.5, 1),
        backgroundColor: 'white',
        fontSize: 13,
        color: '#818CAE',
        borderRadius: 4,
        alignItems: 'center',
        position: 'relative',
    },
    label: {
        flex: 1,
    },
    time: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
}));

export const TimeSlotItem = ({label, tiempo, button, style}) => {
    const classes = useStyles();
    return (
        <div
            className={classes.wrapper}
            style={style}
        >
            <div className={classes.root}>
                <div className={classes.label}>{label}</div>
                <div className={classes.time}>
                    <ScheduleIcon fontSize='inherit' style={{marginRight: 4}}/>
                    {formatTiempo(tiempo)}
                </div>
            </div>
            {button &&
            <div className={classes.button}>{button}</div>}
        </div>
    );
}