import React from "react";
import {useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {TimeSlotItem} from "./TimeSlotItem";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import {getTiempoTotal} from "../../api/tareas-functions";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import EventIcon from '@material-ui/icons/Event';
import Button from "@material-ui/core/Button";
import {
    ALBARANES,
    CERRAR,
    EXPLICACION,
    GASTOS,
    MATERIAL_UTILIZADO,
    NOTAS,
    PARTES_MANTENIMIENTO_LIST
} from "./TareaView";
import {formatISODate} from "../../utils";
import {PageBodySection} from "../common/PageBody";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {ButtonDialog} from "../common/ButtonDialog";
import {BigButton} from "../common/BigButton";
import OpcionesContinuarView from "./opciones_continuar/OpcionesContinuarView";
import useSplash from "../../contexts/SplashState";

const useStyles = makeStyles(theme => ({
    hoyLabel: {
        background: 'linear-gradient(180deg, #4D62FF 0%, #4D96FF 100%), #EDF8F5',
        borderRadius: 20,
        color: 'white',
        width: 40,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 11,
        marginTop: -2,
        marginBottom: -2,
    },
    timeButton: {
        marginLeft: 8,
        height: '100%',
        minWidth: 44,
        background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
    },
    hoyNoTime: {
        borderRadius: 4,
        border: '1px dashed #BABABA',
        minHeight: 38,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 13,
        color: '#818CAE',
        flex: 1,
    },
    hoyAddButton: {
        marginLeft: 8,
        height: 40,
        minWidth: 44,
        background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
    },
    scrollBox: {
        borderRadius: 8,
        padding: '8px 16px',
        position: 'relative',
        minHeight: 100,
        marginBottom: 24,
        color: '#818CAE',
        fontSize: 13,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        background: `linear-gradient(white 30%, rgba(255,255,255, 0)),
                linear-gradient(rgba(255,255,255, 0), white 70%) 0 100%,
                radial-gradient(farthest-side at 50% 0, rgba(34,34,34, 0.2), rgba(0,0,0,0)),
                radial-gradient(farthest-side at 50% 100%, rgba(34,34,34, 0.2), rgba(0,0,0,0)) 0 100%`,
        backgroundColor: '#ffffff',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'local, local, scroll, scroll',
        backgroundSize: '100% 24px, 100% 24px, 100% 8px, 100% 8px',
        '& .MuiIconButton-root': {
            position: 'absolute',
            bottom: 'calc(50% - 12px)',
            right: 8,
        },
        '&.empty': {
            minHeight: 24,
            justifyContent: 'center',
        },
        '&.empty .MuiIconButton-root': {
            bottom: 'calc(50% - 15px)',
        },
    },
}));

export const VistaGeneral = ({id, tarea, setView, onSave, editable}) => {
    const classes = useStyles();
    const history = useHistory();
    const {showCustomComponent} = useSplash();
    const listaMateriales = tarea.jornadas.map(jornada => jornada.materiales).flat();

    const hasMateriales = listaMateriales.length === 0;
    const materiales = hasMateriales ? <span style={{fontStyle: 'italic'}}>Añadir material utilizado</span> : listaMateriales.map((m, i) => <span style={{marginBottom: 4}} key={i}>{m.unidades} {m.descripcion}</span>);

    const explicaciones = tarea.jornadas.map(j => j.explicacion).filter(Boolean);
    const hasExplicacion = explicaciones.length > 0;
    const explicacion = hasExplicacion ? explicaciones.map((m, i) => <span style={{marginBottom: 4, whiteSpace: 'pre-line'}} key={i}>{m}</span>) : <span style={{fontStyle: 'italic'}}>Explicar el trabajo realizado</span>;

    const notasPropias = tarea.jornadas.filter(j => j.propia && j.notas.length > 0).map(j => j.notas[0]);
    const notas = notasPropias.length > 0 ? notasPropias.map((m, i) => <span style={{marginBottom: 4, whiteSpace: 'pre-line'}} key={i}>{m.texto}</span>) : <span style={{fontStyle: 'italic'}}>Ver, editar o añadir observaciones</span>;

    const numAlbaranesPendientes = tarea.albaranes.filter(a => a.firma === null || a.firma.image_url === null).length;
    const albaranes = <span style={{fontStyle: 'italic'}}>{numAlbaranesPendientes > 0 ? `Hay ${numAlbaranesPendientes} albaranes pendientes de firmar` : 'Generar albaranes para el cliente'}</span>;

    const numPartesMantenimiento = tarea.partes_mantenimiento.length;
    const numPartesMantenimientoCompletados = tarea.partes_mantenimiento.filter(p => p.terminado).length;
    const partesMantenimiento = <span style={{fontStyle: 'italic'}}>{numPartesMantenimientoCompletados} de {numPartesMantenimiento} terminados</span>;

    let cerrarTrabajoButton =
        <Button
            style={{
                color: '#4DABFF',
                textTransform: 'none',
                fontSize: 14,
                fontWeight: 500,
                padding: '8px 16px',
                border: '1px solid #2196F3',
                width: '100%',
            }}
            onClick={() => setView(CERRAR)}
            variant='outlined'
        >
            Cerrar servicio
        </Button>;

    if (tarea.jornadas.length === 0) {
        cerrarTrabajoButton =
            <ButtonDialog
                title='¿Estás seguro que quieres cerrar este servicio con 0 horas?'
                okText='Cerrar servicio'
                button={
                    cerrarTrabajoButton
                }
                onAccept={() => setView(CERRAR)}
            />;
    }

    return <>
        <PageBodySection>
            <Typography variant='h4'>Tiempo</Typography>
            {tarea.tiempoHoy ?
                <TimeSlotItem
                    label={
                        <div className={classes.hoyLabel}>HOY</div>
                    }
                    tiempo={tarea.tiempoHoy}
                    button={
                        editable &&
                        <Button
                            disabled={tarea.jornadas[0].facturada}
                            onClick={() => history.push(`/tarea/${id}/tiempo`, {editing: true})}
                            className={classes.timeButton}
                            style={{
                                opacity: tarea.jornadas[0].facturada ? 0.4 : 1,
                            }}
                        >
                            <EditIcon style={{color: 'white', fontSize: 20}}/>
                        </Button>
                    }
                />
                :
                <div style={{display: 'flex'}}>
                    <div className={classes.hoyNoTime}>
                        Aún no has registrado tiempo hoy
                    </div>
                    {editable &&
                    <Button
                        onClick={() => history.push(`/tarea/${id}/tiempo`)}
                        className={classes.hoyAddButton}
                    >
                        <AddIcon style={{color: 'white', fontSize: 20}} />
                    </Button>}
                </div>
            }
            <TimeSlotItem
                label='Total'
                tiempo={getTiempoTotal(tarea)}
                button={
                    <Button
                        onClick={() => history.push(`/tarea/${id}/jornadas/${tarea.jornadas.length > 0 ? tarea.jornadas[0].fecha : formatISODate(new Date())}`, {editing: true})}
                        className={classes.timeButton}
                    >
                        <EventIcon style={{color: 'white', fontSize: 20}} />
                    </Button>
                }
                style={{
                    marginTop: 8,
                    marginBottom: 24,
                }}
            />
        </PageBodySection>
        <PageBodySection>
            <Typography variant='h4'>Material utilizado</Typography>
            <div
                className={clsx(classes.scrollBox, {'empty': hasMateriales})}
                onClick={() => editable && setView(MATERIAL_UTILIZADO)}
            >
                {materiales}
                {editable &&
                <IconButton
                    size='small'
                    onClick={() => setView(MATERIAL_UTILIZADO)}
                >
                    <ChevronRightIcon/>
                </IconButton>}
            </div>
        </PageBodySection>
        <PageBodySection>
            <Typography variant='h4'>Descripción del servicio</Typography>
            <div
                className={clsx(classes.scrollBox, {'empty': hasExplicacion})}
                onClick={() => editable && setView(EXPLICACION)}
            >
                {explicacion}
                {editable &&
                <IconButton
                    size='small'
                    onClick={() => setView(EXPLICACION)}
                >
                    <ChevronRightIcon/>
                </IconButton>}
            </div>
        </PageBodySection>
        <PageBodySection>
            <Typography variant='h4'>Observaciones y fotos</Typography>
            <div
                className={clsx(classes.scrollBox, {'empty': true})}
                onClick={() => editable && setView(NOTAS)}
            >
                {notas}
                {/*<span style={{fontStyle: 'italic'}}>Ver, editar o añadir observaciones</span>*/}
                {editable &&
                <IconButton
                    size='small'
                    onClick={() => setView(NOTAS)}
                >
                    <ChevronRightIcon/>
                </IconButton>}
            </div>
        </PageBodySection>
        <PageBodySection>
            <Typography variant='h4'>Otros gastos</Typography>
            <div
                className={clsx(classes.scrollBox, 'empty')}
                onClick={() => editable && setView(GASTOS)}
            >
                <span style={{fontStyle: 'italic'}}>Dietas, desplazamientos, parking, ...</span>
                {editable &&
                    <IconButton
                        size='small'
                        onClick={() => setView(GASTOS)}
                    >
                        <ChevronRightIcon/>
                    </IconButton>}
            </div>
        </PageBodySection>
        <PageBodySection>
            <Typography variant='h4'>Albaranes de servicio</Typography>
            <div
                className={clsx(classes.scrollBox, 'empty')}
                onClick={() => editable && setView(ALBARANES)}
            >
                <span style={{fontStyle: 'italic'}}>{albaranes}</span>
                {editable &&
                    <IconButton
                        size='small'
                        onClick={() => setView(ALBARANES)}
                    >
                        <ChevronRightIcon/>
                    </IconButton>}
            </div>
        </PageBodySection>
        {numPartesMantenimiento > 0 &&
        <PageBodySection>
            <Typography variant='h4'>Partes de mantenimiento</Typography>
            <div
                className={clsx(classes.scrollBox, 'empty')}
                onClick={() => editable && setView(PARTES_MANTENIMIENTO_LIST)}
            >
                <span style={{fontStyle: 'italic'}}>{partesMantenimiento}</span>
                {editable &&
                    <IconButton
                        size='small'
                        onClick={() => setView(ALBARANES)}
                    >
                        <ChevronRightIcon/>
                    </IconButton>}
            </div>
        </PageBodySection>}

        {editable &&
        <PageBodySection
            style={{
                marginTop: 'auto',
                marginBottom: 24,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingTop: 16,
            }}
        >
            <BigButton
                onClick={() => {

                    showCustomComponent(({closeSplash}) => (
                        <OpcionesContinuarView onClose={closeSplash} />
                    ));
                }}
                style={{width: '100%', marginBottom: 0}}
            >
                CONTINUAR
            </BigButton>
        </PageBodySection>}
    </>;
};


