import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {RoundedInput} from "../RoundedInput";
import Pregunta from "./Pregunta";
import {useField} from "formik";
import InputBase from "@material-ui/core/InputBase";
import clsx from "clsx";


const useStyles = makeStyles(theme => ({
    textItem: {
        display: 'flex',
        flexDirection: 'column',
        gap: `${theme.spacing(1)}px`,
        '& label': {
            fontSize: 13,
            fontWeight: 500,
        },
    },
    input: {
        backgroundColor: '#fff',
        borderRadius: 8,
        padding: theme.spacing(0.5, 1.5),
        fontSize: 13,
        color: '#818CAE',
        '&.Mui-error': {
            border: '2px solid #D84A3A',
        },
        width: 80,
        margin: 0,
    },
}), {name: 'PreguntaText'});


function PreguntaTextInput({name, className}) {
    const [{value, ...field}, meta, {setValue, setTouched}] = useField(name);

    return (
        <InputBase
            value={value || ''}
            {...field}
            onChange={ev => {
                setValue(ev.target.value);
                setTouched(true);
            }}
            className={className}
            error={Boolean(meta.touched && meta.error)}
        />
    );
}


export default function PreguntaText({ pregunta, columnas, ...props }) {
    const classes = useStyles();

    return (
        <Pregunta pregunta={pregunta} {...props}>
            {columnas.map((label, i) => (
                <div key={i} className={classes.textItem}>
                    <label>{label}</label>
                    <PreguntaTextInput
                        name={`respuestas.${pregunta.id}.valor.${i}`}
                        className={classes.input}
                    />
                </div>
            ))}
        </Pregunta>
    )
}