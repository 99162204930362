import React, {useEffect, useState} from "react";
import addHours from "date-fns/addHours";
import {DatePicker, DateTimePicker} from "./DatePicker";
// import {DatePicker, DateTimePicker} from "formik-material-ui-pickers";

export function DateRangePicker({fecha, allDay, setFieldValue, disabled, fechaInicioKey, fechaFinKey, showError=true}) {
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        if (allDay)
            return;

        if (!changed) {
            setChanged(true);
            return;
        }

        setFieldValue(fechaFinKey, addHours(fecha, 1));
    }, [fecha, allDay, changed, setFieldValue]);

    const PickerComponent = allDay ? DatePicker : DateTimePicker;
    const fromFormat = allDay ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm';
    const toFormat = allDay ? 'dd/MM/yyyy' : 'HH:mm dd/MM/yyyy';

    return (
        <>
            <span style={{marginRight: 8, color: '#8F95AF', fontSize: 13, padding: '10px 0'}}>Del</span>
            <PickerComponent
                name={fechaInicioKey}
                format={fromFormat}
                autoOk
                ampm={false}
                minutesStep={15}
                disabled={disabled}
                showError={showError}
            />
            <span style={{margin: '0 8px', color: '#8F95AF', fontSize: 13, padding: '10px 0'}}>al</span>
            <PickerComponent
                name={fechaFinKey}
                format={toFormat}
                autoOk
                ampm={false}
                minutesStep={15}
                disabled={disabled}
                showError={showError}
            />
        </>
    );
}